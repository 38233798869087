import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../helpers/constants';
const UserCard = ({ data }) => {
  const { t, i18n } = useTranslation();
  let doctorPicture = '/assets/images/doctors/doctor.jpg';

  if (data?.patient_user_appointment?.profile_picture != null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${data?.patient_user_appointment?.profile_picture}`;
  }
  return (
    <div className='profile patient_card'>
      <div className='profile-image'>
        <div
          className='profile-image-wrapper'
          style={{
            background: `url(${doctorPicture})`,
          }}
        ></div>
        <img src={doctorPicture} alt='Doctor Name' />
      </div>

      <div className='profile-info'>
        <div className='personal'>
          <h3 className='name'>
            {data.patient_user_appointment.name}{' '}
            {data.patient_user_appointment.surname}
          </h3>
          <p className='normal light'>
            <span>{t('male')} &#x2022; </span>
            {data.birthday}
          </p>
          <p className='normal light'>{data.patient_user_appointment.email}</p>
          <p className='normal light'>
            {data.patient_user_appointment.phone_number}
          </p>
        </div>
      </div>

      <div className='profile-buttons'>
        <Link
          to={appRoutes.DOCTOR.PATIENT.HEALTH_OVERVIEW + '/' + data.patient_id}
        >
          <div className='user_btn sunset user-register-label sun'>
            {t('health-overview')}
          </div>
        </Link>
        <Link to={appRoutes.DOCTOR.DOCUMENTS + '/' + data.patient_id}>
          <div className='user_btn user-register-label documents w-100 nature'>
            {t('see-documents')}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default UserCard;
