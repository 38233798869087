import React, { useEffect } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomMap from '../../components/CustomMap';

const SeeDoctorProfileScreen = () => {
  const location = useLocation();
  const doctor = location?.state?.doctor;
  // console.log('DOCTOR', doctor);

  let doctorPicture = '/assets/images/doctors/doctor.jpg';

  if (doctor?.profile_picture != null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${doctor?.profile_picture}`;
  }
  const doctorList = [];
  doctorList.push(doctor);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="public_doctor_page">
        <div className="booking-appointment">
          <div className="container-xxl">
            <div className="col-sm-4 col-12">
              <Link to="/clinic-doctors" className="back-to">
                {t('back-to-list')}
              </Link>
            </div>

            <div className="row">
              <div className="col-xxl-12 col-md-12 col-12 mx-auto">
                <div className="booking booking-small">
                  <h1>
                    Dr. {doctor?.name} {doctor?.surname}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="admin-doctors-container">
          <div className="container-xxl">
            <div className="row">
              <div className="col-xxl-12">
                <div className="doctor-item doctor-col-2">
                  <div className="doctor doctor doctor_single_card">
                    <div className="profile-image">
                      <div
                        className="profile-image-wrapper"
                        style={{
                          background: `url(${doctorPicture})`,
                        }}
                      ></div>
                      <img src={doctorPicture} alt="Doctor" />
                    </div>

                    <div className="doctor-info">
                      <div className="doctor-name">
                        <span title="Dr. Richard Hardee">
                          Dr. {doctor?.name} {doctor?.surname}
                        </span>
                      </div>
                      <div className="doctor-job">
                        <div className="job-tags">
                          <span className="btn-nature">
                            {
                              doctor?.doctor_profile?.primary_specialization
                                ?.title
                            }
                          </span>
                          <span className="btn-nature">
                            {
                              doctor?.doctor_profile?.secondary_specialization
                                ?.title
                            }
                          </span>
                        </div>
                      </div>
                      <div className="doctor-desciption">
                        <p>{doctor?.this_page_required_info}</p>
                      </div>
                      <div className="doctor-location hidden-mobile">
                        <p>
                          {`${doctor?.doctor_profile?.street} ${doctor?.doctor_profile?.city} `}
                          {doctor?.doctor_profile?.country &&
                            doctor?.doctor_profile?.country}
                        </p>
                      </div>
                      <div className="doctor-price hidden-mobile">
                        <span>
                          {doctor?.doctor_profile?.doctor_specialization_prices.map(
                            (p) => (
                              <div key={p.title}>
                                <span>{p.price}€ </span>
                                <span>{p.title}</span>
                                <br />
                              </div>
                            )
                          )}{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="doctor doctor-mobile">
                    <div className="doctor-info">
                      <div className="doctor-location ">
                        <p>
                          {`${doctor?.doctor_profile?.street} ${doctor?.doctor_profile?.city}`}
                          {doctor?.doctor_profile?.country &&
                            doctor?.doctor_profile?.country}
                        </p>
                      </div>
                      <div className="doctor-price ">
                        <span>
                          {doctor?.doctor_profile?.doctor_specialization_prices.map(
                            (p) => (
                              <div key={p.title}>
                                <span>{p.price}€ </span>
                                <span>{p.title}</span>
                                <br />
                              </div>
                            )
                          )}{' '}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>
                      {' '}
                      {t('date-of-registration')}:{' '}
                      {moment(new Date(doctor?.created_at)).format(
                        'Do MMMM YYYY'
                      )}
                    </span>
                    <br />
                    <span>
                      {t('email')}: {doctor?.email}{' '}
                    </span>
                    <br />
                    <span>
                      {t('accept-third-party')}:{' '}
                      {doctor?.doctor_profile?.third_party_payment}
                    </span>
                    <br />
                    <span>
                      {t('id-number')}: {doctor?.id_number}
                    </span>
                    <br />
                    <span>
                      {t('sanitary-card')}: {doctor?.sanitary_card}
                    </span>
                    <br />
                    <span>
                      {t('gender')}: {doctor?.gender}
                    </span>
                    <br />
                    <span>
                      {t('health-insurance')}: {doctor?.healt_insurance}
                    </span>
                    <br />
                    <span>
                      {t('this-page-required-info')}:{' '}
                      {doctor?.this_page_required_info}
                    </span>
                    <br />
                    <span>
                      {t('nationality')}: {doctor?.doctor_profile?.nationality}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="doctor-gmap">
                  <CustomMap height={300} data={doctorList} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="doctor-accordion">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t('safetly')}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          {doctor?.doctor_profile?.safety_measures}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {t('services')}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                      >
                        <div className="accordion-body">
                          {doctor?.doctor_profile?.services}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {t('formation')}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                      >
                        <div className="accordion-body">
                          {doctor?.doctor_profile?.formation_and_specialization}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t('diseases')}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          {doctor?.doctor_profile?.disease_treated}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t('languages')}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          {doctor?.doctor_profile?.languages}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-phone">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button phone"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#phone"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {doctor?.phone_number}
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeeDoctorProfileScreen;
