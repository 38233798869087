import axios from 'axios';
import {
  PATIENT_ACCEPT_APOINTMENT_FAIL,
  PATIENT_ACCEPT_APOINTMENT_REQUEST,
  PATIENT_ACCEPT_APOINTMENT_SUCCESS,
  PATIENT_REFUSE_APOINTMENT_FAIL,
  PATIENT_REFUSE_APOINTMENT_REQUEST,
  PATIENT_REFUSE_APOINTMENT_SUCCESS,
  PATIENT_REGISTER_FAIL,
  PATIENT_REGISTER_REQUEST,
  PATIENT_REGISTER_SUCCESS,
  PATIENT_UPDATE_PROFILE_FAIL,
  PATIENT_UPDATE_PROFILE_REQUEST,
  PATIENT_UPDATE_PROFILE_SUCCESS,
} from '../constants/patientConstants';
import { USER_LOGIN_SUCCESS } from '../constants/userConstants';
import { getItemFromLocalStorage } from '../helpers/helpers';

export const patientRegisterActions =
  (email, name, surname, phoneNumber, password) => async (dispatch) => {
    try {
      dispatch({
        type: PATIENT_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}register`,
        {
          email,
          name,
          surname,
          phone_number: phoneNumber,
          password,
          usertype: 'patient',
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: PATIENT_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      dispatch({
        type: PATIENT_REGISTER_SUCCESS,
        payload: {},
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: PATIENT_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const patientUpdatedProfileActions =
  (
    id,
    email,
    phoneNumber,
    name,
    surname,
    password,
    idNumber,
    sanitaryCard,
    gender,
    birthday,
    healthInsurance,
    info,
    file_name,
    profile_picture
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PATIENT_UPDATE_PROFILE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}users/update`,
        {
          id,
          email,
          phone_number: phoneNumber,
          name,
          surname,
          password,
          id_number: idNumber,
          sanitary_card: sanitaryCard,
          gender,
          birthday,
          healt_insurance: healthInsurance,
          this_page_required_info: info,
          file_name,
          profile_picture,
          // usertype: 'patient',
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status_code === 400) {
        throw new Error(data.data);
      }

      dispatch({
        type: PATIENT_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: PATIENT_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const patientAcceptApointmentActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PATIENT_ACCEPT_APOINTMENT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/accept`,
        {
          id,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: PATIENT_ACCEPT_APOINTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PATIENT_ACCEPT_APOINTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const patientRefuseApointmentActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PATIENT_REFUSE_APOINTMENT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/refuse`,
        {
          id,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: PATIENT_REFUSE_APOINTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PATIENT_REFUSE_APOINTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
