import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const DoctorCard = (props) => {
  const { data, onCardClick } = props;
  const { t, i18n } = useTranslation();

  let doctorPicture = '/assets/images/doctors/doctor.jpg';

  if (data?.profile_picture != null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${data?.profile_picture}`;
  }
  // console.log('DDDD', data);

  return (
    <div onClick={onCardClick}>
      <div className='doctor doctor_single_card'>
        <div className='profile-image'>
          <div
            className='profile-image-wrapper'
            style={{
              background: `url(${doctorPicture})`,
            }}
          ></div>
          {/* {data?.profile_picture ? (<b></b> ) : null} */}
          <img src={doctorPicture} alt='Doctor' />
        </div>
        <div className='doctor-info'>
          <div className='doctor-name'>
            <Link
              to={`/public-doctor/${data?.id}`}
              title={data.name}
              state={{ doctorData: data }}
            >
              {data.name}
            </Link>
          </div>
          <div className='doctor-job'>
            <div className='job-tags'>
              {data?.doctor_profile?.primary_specialization?.title && (
                <span className='btn-nature'>
                  {t(data.doctor_profile.primary_specialization.title)}
                </span>
              )}
              {data?.doctor_profile?.secondary_specialization?.title && (
                <span className='btn-nature'>
                  {t(data.doctor_profile.secondary_specialization.title)}
                </span>
              )}
            </div>
          </div>
          <p className='desc light'>{data?.this_page_required_info}</p>

          <div className='doctor-location'>
            <p>
              {`${data?.doctor_profile?.street} ${data?.doctor_profile?.city} `}
              {data?.doctor_profile?.country && data?.doctor_profile?.country}
            </p>
          </div>
          <div className='doctor-price'>
            <select>
              <option>{t('show-prices')}</option>
              {data?.doctor_profile?.doctor_specialization_prices &&
                data.doctor_profile.doctor_specialization_prices.map(
                  (price) => (
                    <option disabled key={price.id} className='category'>
                      {price.price} € {price.title}
                    </option>
                  )
                )}
            </select>
          </div>

          {data?.doctor_profile?.third_party_payment === 'YES' && (
            <div className='third_party_info'>
              <span>{t('accept-third-paty-payment')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
