import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  API_ROUTES,
  months,
} from '../../helpers/constants';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PatientCard = ({ data }) => {
  let cardInfo = data;
  if (data.appointment) {
    cardInfo = cardInfo.appointment;
    cardInfo.patient_user_appointment = cardInfo?.patient;
  }
  // console.log('DATA', data);
  const { t, i18n } = useTranslation();

  const doctorPicture = cardInfo?.patient_user_appointment?.profile_picture
    ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${cardInfo?.patient_user_appointment?.profile_picturee}`
    : '/assets/images/doctors/doctor.jpg';

  return (
    <div className='profile'>
      <div className='profile-image'>
        <div
          className='profile-image-wrapper'
          style={{
            background: `url(${doctorPicture})`,
          }}
        ></div>
        <img src={`${doctorPicture}`} alt='Doctor Name' />
      </div>

      <div className='profile-info'>
        <div className='personal'>
          <h3 className='name'>
            {cardInfo?.patient_user_appointment?.name}{' '}
            {cardInfo?.patient_user_appointment?.surname}{' '}
          </h3>
          <ul>
            <li>
              {moment(new Date(cardInfo.start_date)).format('DD-MM-YYYY')} -{' '}
              {moment(new Date(cardInfo.start_date)).format('hh:mm A')} -{' '}
              {moment(new Date(cardInfo.end_date)).format('hh:mm A')}
            </li>
            <li>
              <div className='doctor-price'>
                <span>{cardInfo.payment_price}</span>
              </div>
            </li>
            <li>
              {t('payment-date')}:{' '}
              {moment(new Date(data?.created_at)).format('DD-MM-YYYY hh:mm A')}
            </li>
          </ul>
        </div>
      </div>

      <div className='profile-buttons'>
        {cardInfo?.payment_status === 0 && (
          <span className='btn btn-sunset'>{t('not-paid')}</span>
        )}
        <a
          href={`doctor-health-overview/${cardInfo?.patient_id}`}
          className='btn btn-darkblue'
        >
          {t('see-profile')}
        </a>
      </div>
    </div>
  );
};

const DoctorOnlinePayment = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [payNotPayed, setPayNotPayed] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const { t } = useTranslation();

  const [openYearDropDown, setOpenYearDropDown] = useState(false);
  const [openMonthDropDown, setOpenMonthDropDown] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');

  const [month, setMonth] = useState('');

  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const last50Year = range(currentYear - 1, currentYear + 8, 1);

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const initalPrice = 0;

  const filterPayments = async (year, month) => {
    setPayNotPayed([]);
    await axios
      .post(
        API_ROUTES.DOCTOR.FILTER_ONLINE_PAYMENTS,
        {
          year: year,
          month: month ? month : 1,
        },
        config
      )
      .then((response) => {
        const payedPrice = response?.data?.data?.filter(
          (el) => el.appointment.payment_status === 1
        );
        const sumPrice = payedPrice.reduce(
          (previousValue, currentValue) =>
            previousValue + Number(currentValue.amount),
          initalPrice
        );
        setTotalPrice(sumPrice);
        setPayNotPayed(response?.data?.data);
      });
  };

  const onSelectYear = async (year) => {
    setSelectedYear(year);
    filterPayments(year);
    setOpenYearDropDown(false);
    setMonth('');
    setOpenMonthDropDown(false);
  };

  const onSelectMonth = async (name, index) => {
    let monthIndex = index + 1;
    setMonth(name);
    filterPayments(selectedYear, ('0' + monthIndex).slice(-2));
    setOpenMonthDropDown(false);
  };

  const getPayNotPayed = async () => {
    await axios
      .post(API_ROUTES.DOCTOR.FILTER_ONLINE_PAYMENTS, {}, config)
      .then((response) => {
        const payedPrice = response?.data?.data?.filter(
          (el) => el.appointment.payment_status === 1
        );
        const sumPrice = payedPrice.reduce(
          (previousValue, currentValue) =>
            previousValue + Number(currentValue.amount),
          initalPrice
        );
        setTotalPrice(sumPrice);
        setPayNotPayed(response?.data?.data);
      });
  };

  useEffect(() => {
    getPayNotPayed();
  }, []);


  return (
    <>
      <div className='online_doctor_payment'>
        <div className='container-xxl page-heading'>
          <div className='row'>
            <div className='col-sm-4 col-12'>
              <a
                href='/dashboard'
                className='back-to'
              >
                {t('back-to-dashboard')}
              </a>
            </div>
            <div className='col-sm-4 col-12 text-center'>
              <div className='user-register-label nature'>
                <img src='/assets/images/wallet.svg' />
                <span>{t('online-payments')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-6 col-md-12 col-12 mx-auto'>
              <div className='sort-by'>
                <span>{t('sort-by')}</span>
                <ul style={{ position: 'relative' }}>
                  <li
                    onClick={() => setOpenYearDropDown((state) => !state)}
                    style={{ cursor: 'pointer' }}
                  >
                    {selectedYear ? selectedYear : t('year')}
                  </li>
                  {openYearDropDown && (
                    <div className='year_dropdown'>
                      {last50Year.map((element) => {
                        return (
                          <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => onSelectYear(element)}
                            key={element}
                          >
                            {element}
                          </p>
                        );
                      })}
                    </div>
                  )}
                  {selectedYear && (
                    <li onClick={() => setOpenMonthDropDown((state) => !state)}>
                      {month ? month : t('month')}
                    </li>
                  )}
                  {openMonthDropDown && (
                    <div className='month_dropdown'>
                      {months.map((element, index) => {
                        return (
                          <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => onSelectMonth(element, index)}
                            key={element}
                          >
                            {element}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </ul>
              </div>

              {/* <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="sort-by">
                <span>Sort by:</span>
                <ul>
                  <li className="active"><a href="#">Dates</a></li>
                </ul>
              </div>
            </div>
          </div> */}

              <div className='user-view'>
                {/* <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="user-view-online-date">
                  <a href="#" className="the-date">Jan 2022</a>
                </div>
              </div>
            </div> */}
                <div className='row'>
                  {payNotPayed &&
                    payNotPayed.map((element) => {
                      return (
                        <div
                          key={element.id}
                          className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
                        >
                          <PatientCard data={element} />
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className='row'>
                <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                  <div className='price-total'>
                    <div className='price-value'>
                      <span>
                        {t('tot')} ({t('tax-incl')})
                      </span>
                      <span>${totalPrice} €</span>
                    </div>
                    <div className='price-value'>
                      <span>{t('payout-date')}</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorOnlinePayment;
