import React, { useState, useRef, useEffect } from "react";
import jwt from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import { Link, useNavigate } from "react-router-dom";
import useClickOutside from "../hooks/useClickOutside";

import headerLogo from "../assets/img/header_logo.svg";
import mobileButtonToggleIcon from "../assets/img/icons/mobile_menu_toggle_icon.svg";

const Header = ({
  notificationCount,
  chatNotificationCount,
  onNotificationClick,
  onChatNotificationClick,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [isMenuOpened, setHamburgerMenuOpen] = useState(false);
  const [isMobileMenuOpened, setMobileMenuOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const menuRef = useRef();
  const mobileMenuRef = useRef();

  let token;
  if (userInfo) token = jwt(userInfo.data);

  let history = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    history("/");
  };

  const handleChangeRoute = (route) => {
    history(route);
    setMobileMenuOpen(() => false);
    setHamburgerMenuOpen(() => false);
  };

  useClickOutside(menuRef, () => {
    if (isMenuOpened) {
      setHamburgerMenuOpen(() => false);
    }
  });
  useClickOutside(mobileMenuRef, () => {
    if (isMobileMenuOpened) {
      setMobileMenuOpen(() => false);
    }
  });

  const openMenuTrigger = (openMenuState) => {
    setHamburgerMenuOpen(openMenuState);
  };

  const handleToggleMenu = () => setHamburgerMenuOpen((isOpened) => !isOpened);
  const handleToggleMobileMenu = () =>
    setMobileMenuOpen((isOpened) => !isOpened);

  // console.log(token?.tokenParams)

  const notSubscription = token?.tokenParams?.stripe_subscription_id;
  // .................................................
  // const username

  const name = token?.tokenParams?.name;
  const usertype = token?.tokenParams?.usertype;

  let expToken = token?.exp;
  useEffect(() => {
    let newDate = new Date();
    let exdDate = new Date(0);
    exdDate.setUTCSeconds(expToken);
    if (exdDate < newDate) {
      localStorage.removeItem("userInfo");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [expToken]);

  // mobile user menu / profile pic
  const profilePicture = token?.tokenParams?.profile_picture
    ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${token?.tokenParams?.profile_picture}`
    : "/assets/images/doctors/doctor.jpg";

  // Get subscription from local storage
  const subscription_plan = localStorage.getItem("subscription_plan")
    ? JSON.parse(localStorage.getItem("subscription_plan")).plan
    : false;

  // console.log('SUBSCRIPTION PLAN', subscription_plan);

  const renderMobileCTA = () => {
    if (token)
      return (
        <div className="user-login-mobile">
          <img
            className="user-login-mobile-profile-picture"
            src={profilePicture}
            alt="Profile Icon"
          />
        </div>
      );

    return (
      <div className="user-login-mobile">
        <div className="dropdown">
          <button
            className="btn btn-darkblue dropdown-toggle"
            onClick={() => handleToggleMobileMenu()}
          >
            <img src={mobileButtonToggleIcon} alt="Mobile Toggle Icon" />
          </button>

          <ul
            className={`dropdown-menu dropdown-menu-end ${
              isMobileMenuOpened ? "show" : ""
            }`}
            ref={mobileMenuRef}
          >
            <li>
              <a
                onClick={() => handleChangeRoute("/login")}
                className="dropdown-item"
              >
                {t("header_button_login")}
              </a>
            </li>
            <li>
              <a
                onClick={() => handleChangeRoute("/doctor-register")}
                className="dropdown-item"
              >
                {t("header_button_register")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderDesktopCTA = () => {
    if (token)
      return (
        <div className="user-login gap-1 d-md-flex justify-content-md-end align-items-center">
          <button
            className="btn btn-darkblue"
            onClick={() => handleChangeRoute('/dashboard')}
          >
            {t("header_user_greeting")} {name}
          </button>
        </div>
      );

    return (
      <div className="user-login gap-1 d-md-flex justify-content-md-end">
        <a onClick={() => handleChangeRoute("/login")} className="btn">
          {t("header_button_login")}
        </a>
        <a
          onClick={() => handleChangeRoute("/doctor-register")}
          className="btn btn-darkblue"
        >
          {t("header_button_register")}
        </a>
      </div>
    );
  };

  return (
    <header className="header">
      <nav className="navbar navbar-light fixed-top">
        <div className="container-xxl d-flex justify-content-between">
          <button
            className="navbar-toggler collapsed"
            type="button"
            onClick={() => handleToggleMenu()}
          >
            <div className="b-menu">
              <div className="b-bun b-bun-top"></div>
              <div className="b-bun b-bun-mid"></div>
              <div className="b-bun b-bun-bottom"></div>
            </div>
          </button>

          <Link to="/" className="navbar-brand">
            <img src={headerLogo} alt="" />
          </Link>
          {userInfo && (
            <div className="notifications_icons">
              <Link to="/notification">
                <div
                  onClick={onNotificationClick}
                  className="offer-icon notification"
                >
                  <img src="/assets/images/notifications.svg" alt="" />
                  {notificationCount && (
                    <span className="notification_count">
                      {/* {notificationCount} */}
                    </span>
                  )}
                </div>
              </Link>
              <Link to="/chat">
                <div
                  onClick={onChatNotificationClick}
                  className="offer-icon chat"
                >
                  <img src="/assets/images/chat-bot.svg" alt="" />
                  {chatNotificationCount && (
                    <span className="notification_count">
                      {/* {chatNotificationCount} */}
                    </span>
                  )}
                </div>
              </Link>
            </div>
          )}

          {renderDesktopCTA()}
          {renderMobileCTA()}

          <div
            ref={menuRef}
            className={`collapse navbar-collapse ${isMenuOpened ? "show" : ""}`}
          >
            <ul className="nav nav-pills flex-column">
              <li className="nav-item">
                <a
                  onClick={() => handleChangeRoute("/")}
                  className="nav-link"
                  aria-current="page"
                >
                  {t("header_menu_link_home")}
                </a>
              </li>
              {token && (
                <li>
                  <a
                    onClick={() => handleChangeRoute('/dashboard')}
                    className="nav-link"
                  >
                    {t("header_menu_link_dashboard")}
                  </a>
                </li>
              )}
              {token && (
                <li>
                  <a className="nav-link" onClick={() => handleLogout()}>
                    {t("header_button_logout")}
                  </a>
                </li>
              )}
              {token?.tokenParams?.usertype === "freelance_doctor" && (
                <li>
                  <a className="nav-link" href="/doctor-payment-plan">
                    {t("payment-plan")}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>

        {usertype === "freelance_doctor" &&
          !notSubscription &&
          !subscription_plan && (
            <div className="subscription_message">
              {t("header-red-message-for-doctor")}
            </div>
          )}
      </nav>
    </header>
  );
};

export default Header;
