import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { patientUpdatedProfileActions } from '../../actions/patientActions';
import { showUserActions } from '../../actions/userActions';
import loadImage from 'blueimp-load-image';

const UpdatePatientProfileScreen = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // Declare variable
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [sanitaryCard, setSanitaryCard] = useState('');
  const [gender, setGender] = useState('');
  let [birthday, setBirthday] = useState('');
  const [healthInsurance, setHealthInsurence] = useState('');
  const [info, setInfo] = useState('');
  let [file_name, setFile_name] = useState('');
  const [profile_picture, setProfile_picture] = useState(null);
  const [message, setMessage] = useState(null);
  const [checked, setChecked] = useState(false);
  const [imageMessage, setImageMessage] = useState(null);
  const [elDisable, setElDisable] = useState(false);

  const convertTo64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const patientUpdateProfile = useSelector(
    (state) => state.patientUpdateProfile
  );
  const { loading, error, patientInfo } = patientUpdateProfile;

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  const showUserData = useSelector((state) => state.showUser);
  const { showUser } = showUserData;

  const id = token?.tokenParams?.id;
  useEffect(() => {
    dispatch(showUserActions(id));
  }, [dispatch, id]);

  // console.log('TOKEN', showUser);

  let history = useNavigate();
  useEffect(() => {
    if (!token || token?.tokenParams?.usertype !== 'patient') {
      history('/login');
    }
  }, [history, token]);

  useEffect(() => {
    if (showUser) {
      setEmail(showUser?.data?.email);
      setPhoneNumber(showUser?.data?.phone_number);
      setName(showUser?.data?.name);
      setSurname(showUser?.data?.surname);
      setIdNumber(showUser?.data?.id_number);
      setSanitaryCard(showUser?.data?.sanitary_card);
      setGender(showUser?.data?.gender);
      setBirthday(showUser?.data?.birthday);
      setHealthInsurence(showUser?.data?.healt_insurance);
      setInfo(showUser?.data?.this_page_required_info);
    }
  }, [showUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage(t('password-not-match'));
    } else if (!checked) {
      setMessage(t('you-must-agree-terms'));
    } else {
      setMessage(null);
      let imageName;
      imageName = file_name.split('\\');
      imageName = imageName[imageName.length - 1].split('.');
      imageName = imageName[0];
      file_name = imageName;

      if (birthday) {
        let birthday_arr = birthday.split('.').reverse();
        birthday = birthday_arr.join('.');
      }

      dispatch(
        patientUpdatedProfileActions(
          id,
          email,
          phoneNumber,
          name,
          surname,
          password,
          idNumber,
          sanitaryCard,
          gender,
          birthday,
          healthInsurance,
          info,
          file_name,
          profile_picture
        )
      );
      dispatch(showUserActions(id));
      document.location.reload();
    }
  };
  let doctorPicture;

  doctorPicture = showUser?.data?.profile_picture
    ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${showUser?.data?.profile_picture}`
    : '/assets/images/doctors/doctor.jpg';

  // Disable
  useEffect(() => {
    if (error || imageMessage) {
      setElDisable(true);
    }
    if (!error && !imageMessage) {
      setElDisable(false);
    }
  }, [error, imageMessage]);

  return (
    <>
      <div className='patient_profile_page'>
        <div className='container-xxl'>
          <div className='container-fluid user-back'>
            <div className='row'>
              <div className='col'>
                <Link to='/patient-dashboard' className='back-to'>
                  {t('back-to-dashboard')}
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xxl-5 col-md-12 col-12 mx-auto'>
              <div className='user-form-container user-edit'>
                <div className='user-form'>
                  <form onSubmit={submitHandler}>
                    {loading && <Loader />}
                    {imageMessage && (
                      <Message variant='danger'>{imageMessage}</Message>
                    )}
                    <div className='user-text'>
                      <div className='user-register-label register-doctor'>
                        <img src='/assets/images/patient-icon.svg' alt='Info' />
                        <span>{t('personal-info')}</span>
                      </div>

                      <div className='user-picture'>
                        <div className='profile-image'>
                          <div
                            className='profile-image-wrapper'
                            style={{
                              background: `url(${
                                profile_picture
                                  ? profile_picture
                                  : doctorPicture
                              })`,
                            }}
                          ></div>
                          <img
                            src={
                              profile_picture ? profile_picture : doctorPicture
                            }
                            alt='Doctor Name'
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label
                          htmlFor='file_name'
                          className='form-label edit_img_btn'
                        >
                          {t('edit-profile-picture')}
                        </label>
                        <input
                          type='file'
                          className='form-control hide'
                          id='file_name'
                          value={file_name}
                          onChange={async (e) => {
                            let imageName = e.target.value;
                            setFile_name(imageName);
                            const file = e.target.files[0];

                            if (file.size > 8000000) {
                              setImageMessage('Image must be under 8 mb');
                            } else {
                              loadImage(
                                file,
                                async (img) => {
                                  const base = await img.toDataURL();
                                  setProfile_picture(base);
                                  setImageMessage(null);
                                },
                                {
                                  maxWidth: 600,
                                  maxHeight: 300,
                                  minWidth: 100,
                                  minHeight: 50,
                                  canvas: true,
                                }
                              );
                            }
                          }}
                        />
                      </div>

                      <h2>
                        {t('hi')}, {token ? token.tokenParams.name : t('name')}!
                      </h2>
                      <p>{t('patient-update-message')}</p>
                    </div>

                    <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='emailAddress' className='form-label'>
                          {t('email')} <span className='required'>*</span>
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          id='emailAddress'
                          placeholder={t('email')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='phoneNumber' className='form-label'>
                          {t('phone-number')}{' '}
                          <span className='required'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='phoneNumber'
                          placeholder={t('phone-number')}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='firstName' className='form-label'>
                          {t('name')} <span className='required'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='firstName'
                          placeholder={t('name')}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='surname' className='form-label'>
                          {t('surname')} <span className='required'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='surname'
                          placeholder={t('surname')}
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='password' className='form-label'>
                          {t('password')} <span className='required'>*</span>
                        </label>
                        <input
                          autoComplete='new-password'
                          type='password'
                          className='form-control'
                          id='password'
                          placeholder={t('password')}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {/* <div className='form-text link'>Edit password</div> */}
                      </div>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='repeatPassword' className='form-label'>
                          {t('repeat-password')}
                          <span className='required'>*</span>
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          id='repeatPassword'
                          placeholder={t('repeat-password')}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='idnumber' className='form-label'>
                          {/* {t('id-number')} */}
                          {t('social-number')}
                          <span className='required'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='idnumber'
                          placeholder='*****'
                          value={idNumber}
                          required
                          onChange={(e) => setIdNumber(e.target.value)}
                        />
                      </div>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='sanitycard' className='form-label'>
                          {/* {t('sanitary-card')} */}
                          {t('healt-number')}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='sanitycard'
                          placeholder='**********'
                          value={sanitaryCard}
                          onChange={(e) => setSanitaryCard(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label
                          htmlFor='text'
                          className='form-label'
                          id='gender'
                        >
                          {t('gender')}
                        </label>

                        <select
                          className='form-select'
                          aria-label='Default select example'
                          name='gender'
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value=''>{t('open-this-select-menu')}</option>
                          <option value='male'>{t('male')}</option>
                          <option value='female'>{t('female')}</option>
                        </select>
                      </div>

                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='birth' className='form-label'>
                          {t('birth')}
                        </label>
                        <input
                          type='date'
                          className='form-control'
                          id='birth'
                          placeholder='13.12.1992'
                          value={birthday}
                          onChange={(e) => setBirthday(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='healthInsurance' className='form-label'>
                          {t('health-insurance')}?
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='healthInsurance'
                          placeholder='?'
                          value={healthInsurance}
                          onChange={(e) => setHealthInsurence(e.target.value)}
                        />
                      </div>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='info' className='form-label'>
                          {t('this-page-required-info')}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='info'
                          placeholder={t('this-page-required-info')}
                          value={info}
                          onChange={(e) => setInfo(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='exampleCheck1'
                          value={checked}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                          }}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='exampleCheck1'
                        >
                          {t('update-patient-agree')}
                        </label>
                      </div>
                      {patientInfo?.status && (
                        <Message variant='success'>
                          {t('profile-updated')}
                        </Message>
                      )}
                      {message && <Message variant='danger'>{message}</Message>}

                      {error && <Message variant='danger'>{error}</Message>}
                    </div>
                    <button
                      type='submit'
                      className='btn btn-flowers'
                      disabled={loading || elDisable}
                    >
                      {t('save')}
                      <span>{loading && <Loader />}</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePatientProfileScreen;
