import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PatientList = ({event}) => {

  const { t, i18n } = useTranslation();

  const [popupIsOpen, setPopupIsOpen] = useState(false);


  const togglePopup = () => {
    setPopupIsOpen(!popupIsOpen);
  }

  return (
    <div className='patient-list' >
        <div className='patient-list-see-all' onClick={() => togglePopup()}>
            {t('see-all')}
        </div>
        {popupIsOpen && (
            <div className='patient-list-popup'>
                <img className='patient-list-popup-close' src='/assets/images/cross.svg' alt='cross' onClick={() => togglePopup()} />
                {event?.events?.map((event, index) => {
                    return (
                        <div className='patient-list-item'>
                            <div className='patient-list-item-image'>
                                <img src='/assets/images/doctors/doctor.jpg' alt='Profile' title='Profile Picture' />
                            </div>
                            <div className='patient-list-item-name'>
                                {event.patient_user_appointment.surname} {event.patient_user_appointment.name}
                            </div>
                            <div className='patient-list-item-icons'>
                            <Link className="patient-list-item-chat" to='/chat'>
                                <img src='/assets/images/chat-black.svg' alt='chat' />
                            </Link>
                            <a href={`tel:${event.patient_user_appointment.phone_number}`} className='patient-list-item-phone'>
                                <img src='/assets/images/phone.svg' alt='phone' />
                            </a>
                            </div>
                        </div>
                    )}
                )}
            </div>
        )}
    </div>
  );
};

export default PatientList;
