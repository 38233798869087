import {
  CANCEL_APOINTMENT_FAIL,
  CANCEL_APOINTMENT_REQUEST,
  CANCEL_APOINTMENT_SUCCESS,
  CREATE_APOINTMENT_FAIL,
  CREATE_APOINTMENT_REQUEST,
  CREATE_APOINTMENT_SUCCESS,
  GET_ALL_APOINTMENT_FAIL,
  GET_ALL_APOINTMENT_REQUEST,
  GET_ALL_APOINTMENT_SUCCESS,
  GET_ALL_CITY_FAIL,
  GET_ALL_CITY_REQUEST,
  GET_ALL_CITY_SUCCESS,
  GET_APOINTMENT_BY_ID_FAIL,
  GET_APOINTMENT_BY_ID_REQUEST,
  GET_APOINTMENT_BY_ID_SUCCESS,
} from '../constants/apointmentConstants';

export const getAllCityReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CITY_REQUEST:
      return { loading: true };
    case GET_ALL_CITY_SUCCESS:
      return { loading: false, allCity: action.payload };
    case GET_ALL_CITY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllApointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_APOINTMENT_REQUEST:
      return { loading: true };
    case GET_ALL_APOINTMENT_SUCCESS:
      return { loading: false, allApointment: action.payload };
    case GET_ALL_APOINTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createApointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_APOINTMENT_REQUEST:
      return { loading: true };
    case CREATE_APOINTMENT_SUCCESS:
      return { loading: false, createApointment: action.payload };
    case CREATE_APOINTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cancelApointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_APOINTMENT_REQUEST:
      return { loading: true };
    case CANCEL_APOINTMENT_SUCCESS:
      return { loading: false, cancelApointment: action.payload };
    case CANCEL_APOINTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getApointmentByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_APOINTMENT_BY_ID_REQUEST:
      return { loading: true };
    case GET_APOINTMENT_BY_ID_SUCCESS:
      return { loading: false, doctor: action.payload };
    case GET_APOINTMENT_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
