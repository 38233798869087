export const GET_ALL_SPECIALIZATION_REQUEST = 'GET_ALL_SPECIALIZATION_REQUEST';
export const GET_ALL_SPECIALIZATION_SUCCESS = 'GET_ALL_SPECIALIZATION_SUCCESS';
export const GET_ALL_SPECIALIZATION_FAIL = 'GET_ALL_SPECIALIZATION_FAIL';

export const REGISTER_CLINIC_DOCTOR_REQUEST = 'REGISTER_CLINIC_DOCTOR_REQUEST';
export const REGISTER_CLINIC_DOCTOR_SUCCESS = 'REGISTER_CLINIC_DOCTOR_SUCCESS';
export const REGISTER_CLINIC_DOCTOR_FAIL = 'REGISTER_CLINIC_DOCTOR_FAIL';

export const REGISTER_FREELENCE_DOCTOR_REQUEST =
  ' REGISTER_FREELENCE_DOCTOR_REQUEST';
export const REGISTER_FREELENCE_DOCTOR_SUCCESS =
  ' REGISTER_FREELENCE_DOCTOR_SUCCESS';
export const REGISTER_FREELENCE_DOCTOR_FAIL = ' REGISTER_FREELENCE_DOCTOR_FAIL';

export const DOCTOR_UPDATE_PROFILE_REQUEST = 'DOCTOR_UPDATE_PROFILE_REQUEST';
export const DOCTOR_UPDATE_PROFILE_SUCCESS = 'DOCTOR_UPDATE_PROFILE_SUCCESS';
export const DOCTOR_UPDATE_PROFILE_FAIL = 'DOCTOR_UPDATE_PROFILE_FAIL';

export const DOCTOR_CREATE_TERMIN_REQUEST = 'DOCTOR_CREATE_TERMIN_REQUEST';
export const DOCTOR_CREATE_TERMIN_SUCCESS = 'DOCTOR_CREATE_TERMIN_SUCCESS';
export const DOCTOR_CREATE_TERMIN_FAIL = 'DOCTOR_CREATE_TERMIN_FAIL';

export const FREELENCE_DOCTOR_CREATE_PRICE_REQUEST =
  'FREELENCE_DOCTOR_CREATE_PRICE_REQUEST';
export const FREELENCE_DOCTOR_CREATE_PRICE_SUCCESS =
  'FREELENCE_DOCTOR_CREATE_PRICE_SUCCESS';
export const FREELENCE_DOCTOR_CREATE_PRICE_FAIL =
  'FREELENCE_DOCTOR_CREATE_PRICE_FAIL';

export const DOCTOR_GET_PROFILE_REQUEST = 'DOCTOR_GET_PROFILE_REQUEST';
export const DOCTOR_GET_PROFILE_SUCCESS = 'DOCTOR_GET_PROFILE_SUCCESS';
export const DOCTOR_GET_PROFILE_FAIL = 'DOCTOR_GET_PROFILE_FAIL';

export const DOCTOR_PROPOSE_ONLINE_REQUEST = 'DOCTOR_PROPOSE_ONLINE_REQUEST';
export const DOCTOR_PROPOSE_ONLINE_SUCCESS = 'DOCTOR_PROPOSE_ONLINE_SUCCESS';
export const DOCTOR_PROPOSE_ONLINE_FAIL = 'DOCTOR_PROPOSE_ONLINE_FAIL';

export const DOCTOR_PROPOSE_PHYSICAL_REQUEST =
  'DOCTOR_PROPOSE_PHYSICAL_REQUEST';
export const DOCTOR_PROPOSE_PHYSICAL_SUCCESS =
  'DOCTOR_PROPOSE_PHYSICAL_SUCCESS';
export const DOCTOR_PROPOSE_PHYSICAL_FAIL = 'DOCTOR_PROPOSE_PHYSICAL_FAIL';

export const DOCTOR_REFUSE_APOINTMENT_REQUEST =
  'DOCTOR_REFUSE_APOINTMENT_REQUEST';
export const DOCTOR_REFUSE_APOINTMENT_SUCCESS =
  'DOCTOR_REFUSE_APOINTMENT_SUCCESS';
export const DOCTOR_REFUSE_APOINTMENT_FAIL = 'DOCTOR_REFUSE_APOINTMENT_FAIL';

export const DOCTOR_PUBLIC_SCREEN_REQUEST = 'DOCTOR_PUBLIC_SCREEN_REQUEST';
export const DOCTOR_PUBLIC_SCREEN_SUCCESS = 'DOCTOR_PUBLIC_SCREEN_SUCCESS';
export const DOCTOR_PUBLIC_SCREEN_FAIL = 'DOCTOR_PUBLIC_SCREEN_FAIL';

export const GET_DOCTOR_BY_UID_REQUEST = 'GET_DOCTOR_BY_UID_REQUEST';
export const GET_DOCTOR_BY_UID_SUCCESS = 'GET_DOCTOR_BY_UID_SUCCESS';
export const GET_DOCTOR_BY_UID_FAIL = 'GET_DOCTOR_BY_UID_FAIL';
