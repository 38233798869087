import axios from 'axios';
import {
  CANCEL_APOINTMENT_FAIL,
  CANCEL_APOINTMENT_REQUEST,
  CANCEL_APOINTMENT_SUCCESS,
  CREATE_APOINTMENT_FAIL,
  CREATE_APOINTMENT_REQUEST,
  CREATE_APOINTMENT_SUCCESS,
  GET_ALL_APOINTMENT_FAIL,
  GET_ALL_APOINTMENT_REQUEST,
  GET_ALL_APOINTMENT_SUCCESS,
  GET_ALL_CITY_FAIL,
  GET_ALL_CITY_REQUEST,
  GET_ALL_CITY_SUCCESS,
  GET_APOINTMENT_BY_ID_FAIL,
  GET_APOINTMENT_BY_ID_REQUEST,
  GET_APOINTMENT_BY_ID_SUCCESS,
  GET_GROUP_APOINTMENT_BY_ID_FAIL,
  GET_GROUP_APOINTMENT_BY_ID_SUCCESS,
} from '../constants/apointmentConstants';
import { getItemFromLocalStorage } from '../helpers/helpers';

export const getAllCity = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CITY_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.get(`${process.env.REACT_APP_PRODUCTION_API}drop-down-locations`, {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: GET_ALL_CITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CITY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllApointment =
  (
    page = `${process.env.REACT_APP_PRODUCTION_API}filter-appointment-termins?page=1`,
    name = '',
    city = '',
    price = '',
    specialization_ids = []
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_APOINTMENT_REQUEST,
      });

      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      };

      const { data } = await axios.post(
        page,
        { name, city, price, specialization_ids },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: GET_ALL_APOINTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_APOINTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createApointmentActions =
  (
    doctor_id,
    first_visit,
    reason_visit,
    payment_price,
    termin_id,
    specialization_id,
    app_third_party_payment
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_APOINTMENT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/create`,
        {
          doctor_id,
          first_visit,
          reason_visit,
          payment_price,
          termin_id,
          specialization_id,
          app_third_party_payment,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status === 'error') {
        throw new Error(data.data);
      }

      dispatch({
        type: CREATE_APOINTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_APOINTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const cancelApointmentActions = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CANCEL_APOINTMENT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };
    // console.log('FIRST', first_visit)
    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/cancel`,
      {
        id,
      },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.status === 'error') {
      throw new Error(data.data);
    }

    dispatch({
      type: CANCEL_APOINTMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CANCEL_APOINTMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getApointmentById = (id) => async (dispatch) => {
  // console.log('Get Apointment By Id');
  try {
    dispatch({
      type: GET_APOINTMENT_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}appointment-termin-by-id/${id}`,
      {},
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: GET_APOINTMENT_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_APOINTMENT_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
