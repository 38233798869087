import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  API_ROUTES,
  NOTIFICATIONS_TYPES,
} from '../../helpers/constants';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../../components/LoadingIndicator';
import SuccessNotification from '../../components/Notifications/SuccessNotifications';
import AlertNotification from '../../components/Notifications/AlertNotification';
import DocumentNotification from '../../components/Notifications/DocumentNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const PatientNotificationScreen = () => {
  // Show doctor or patient popup
  const { t, i18n } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [notifications, setNotifications] = useState([]);

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const fetchNotifications = async (notification_type) => {
    setNotifications([]);
    setLoading(true);
    await axios
      .post(
        API_ROUTES.GET_NOTIFICATIONS,
        {
          language : i18n.language,
          notification_type : notification_type,
        },
        config
      )
      .then((response) => {
        setLoading(false);
        setNotifications(response.data.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const notificationsSort = [
    'all',
    'alert',
    // 'error',
    'payment',
    'success',
    'document',
  ];


  const lng = ['all', 'alert', 'payment', 'success', 'document'];

  useEffect(() => {
    fetchNotifications(lng[activeTab]);
  }, [activeTab]);


  return (
    <>
      {loading && <LoadingIndicator />}
      <div className='container-xxl page-heading'>
        <div className='row'>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
            <Link to='/dashboard' className='back-to'>
              {t('back-to-dashboard')}
            </Link>
          </div>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
            <div className='user-register-label notifications'>
              <img src='assets/images/chart.svg' alt='Chart' />
              <span>{t('notifications')}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='dashboard-container'>
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12'>
              <div className='sort-by'>
                <span>{t('sort-by')}</span>
                <ul>
                  {notificationsSort.map((element, index) => {
                    return (
                      <li
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setActiveTab(index)}
                        className={index === activeTab ? 'active' : ''}
                      >
                        {t(element)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'>
              <div className='notification-checkbox-custom'>
                <div className='form-check form-switch from-switch-text-left'>
                  <input className='form-check-input' type='checkbox' />
                  <div className='slider'>
                    <span className='on'>{t('on')}</span>
                    <span className='off'>{t('off')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='notifications'>
            <div className='row'>
              <div className='col-12'>
                {notifications &&
                  notifications.map((element, index) => {
                    return (
                      <div key={index}>
                        {element.notification_type ===
                          NOTIFICATIONS_TYPES.SUCCESS && (
                          <SuccessNotification message={element.text} />
                        )}
                        {element.notification_type ===
                          NOTIFICATIONS_TYPES.ALERT && (
                          <AlertNotification message={element.text} />
                        )}
                        {element.notification_type ===
                          NOTIFICATIONS_TYPES.DOCUMENT && (
                          <DocumentNotification message={element.text} />
                        )}
                        {element.notification_type ===
                          NOTIFICATIONS_TYPES.ERROR && (
                          <ErrorNotification message={element.text} />
                        )}
                      </div>
                    );
                  })}
              </div>
              {/* <div className="col-12">
                <div className="notification-item big">
                  <div className="notification-desc">
                    <div className="notification-info">
                      <h3>
                        {t("notification-info-1")}
                        <br />
                        {t("notification-info-2")}
                      </h3>
                      <p>{t("notification-info-3")}</p>
                      <a href="/" className="btn btn-flowers">
                        {t("add-to-calendar")}
                      </a>
                    </div>
                  </div>

                  <div className="profile-image notification-image">
                    <div
                      className="profile-image-wrapper"
                      style={{
                        background: "url(assets/images/doctors/doctor.jpg)",
                      }}
                    ></div>
                    <img
                      src="assets/images/doctors/doctor.jpg"
                      alt="Doctor Name"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientNotificationScreen;
