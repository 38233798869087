import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cancelApointmentActions } from '../../actions/apointmentActions';
const ConfirmApointmentScreen = () => {
  const dispatch = useDispatch();
  let history = useNavigate();
  const { t, i18n } = useTranslation();

  const cancelApointmentData = useSelector((state) => state.cancelApointment);
  const { cancelApointment } = cancelApointmentData;

  useEffect(() => {
    if (cancelApointment?.status_code === 200) {
      history('/cancel-apointment-confirm');
    }
  }, [history, cancelApointment]);

  let { id } = useParams();
  const cancelHandler = (id) => {
    dispatch(cancelApointmentActions(id));
  };

  return (
    <>
      <div
        className='modal modal-book fade show'
        tabIndex='-1'
        style={{ display: 'block' }}
        aria-modal='true'
        role='dialog'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              <h2>{t('confirm-appointment-title')}!</h2>
              <p>{t('confirm-appointment-message')}.</p>
            </div>
            <div className='modal-footer'>
              <a href="/patient-calendar"> 
                <button type='button' className='btn btn-ocean'>
                  {t('go-to-calendar')}
                </button>
              </a>
              {/* <button
                type='button'
                className='btn btn-sunset'
                onClick={() => {
                  cancelHandler(id);
                }}
              >
                {t('confirm-appointment-cancel')}
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className='modal-backdrop fade show'></div>

      <script src='node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
    </>
  );
};

export default ConfirmApointmentScreen;
