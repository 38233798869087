import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DocumentCard from '../../components/Documents/DocumentCard';
import LoadingIndicator from '../../components/LoadingIndicator';
import { API_ROUTES } from '../../helpers/constants';
import DocumentsUserInfoUpload from '../../components/DoctorUsers/DocumentsUserInfoUpload';
import DocumentUpload from '../../components/Documents/DocumentUpload';
import { getItemFromLocalStorage, toBase64 } from '../../helpers/helpers';
import DocumentHeader from '../../components/Documents/DocumentHeader';

const DoctorDocumentScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState([]);

  const { id } = useParams();

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const fetchDocuments = async () => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.DOCTOR.PATIENT_DOCUMENTS,
        {
          patient_id: id,
        },
        config
      )
      .then((response) => {
        setLoading(false);
        setDocuments(response.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  const onFilesUpload = async (files) => {
    setLoading(true);

    const documents = [];

    const length = files.length;

    for (let i = 0; i < length; i++) {
      documents.push({
        title: files[i].name,
        content: await toBase64(files[i]),
      });
    }

    await axios
      .post(
        API_ROUTES.DOCTOR.UPLOAD_DOCUMENTS,
        {
          user_id: id,
          documents,
        },
        config
      )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    fetchDocuments();
  };

  return (
    <>
      <DocumentHeader />
      <DocumentsUserInfoUpload patientId={id} />
      <DocumentUpload
        accept='.doc, .docx,.pdf,.jpg,.jpeg,.png'
        onFilesUpload={onFilesUpload}
      />
      <div className='dashboard-container'>
        <div className='container-xxl'>
          <div className='notifications'>
            <div className='row'>
              {documents &&
                documents.map((element, index) => {
                  return (
                    <div key={index}>
                      <DocumentCard
                        haveDownload={true}
                        doctors={[]}
                        data={element}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorDocumentScreen;
