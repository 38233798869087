import { Link, useParams } from 'react-router-dom';
import { appRoutes } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';

const HealthOverviewHeader = ({ data }) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  let doctorPicture = '/assets/images/doctors/doctor.jpg';
  if (data?.profile_picture != null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${data?.profile_picture}`;
  }

  return (
    <div className='container-xxl doctor_health_overview'>
      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-1'>
          <div className='profile'>
            <div className='profile-image'>
              <div className='profile-image-wrapper'
                style={{
                  background: `url(${doctorPicture})`,
                }}
              ></div>
              <img src={doctorPicture} alt='Doctor Name' />
              
            </div>
            <div className='profile-info'>
              <div className='personal'>
                <h3 className='name'>{data?.name}</h3>
                <ul>
                  <li>
                    <span>{data?.gender}</span>15.02.1997
                  </li>
                  <li>{data?.email}</li>
                  <li>{data?.phone_number}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 mb-1 cp'>
          <Link to={appRoutes.DOCTOR.DOCUMENTS + `/${id}`}>
            <div className='upload-document'>
              <div className='notification-icon sun'>
                <img src='/assets/images/document.svg' alt='' />
              </div>
              <p>
                  {t('documents')}
              </p>
            </div>
          </Link>
        </div>

        <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 mb-1'>
          <Link to={appRoutes.CHAT}>
            <div className='upload-document'>
              <div className='notification-icon ocean'>
                <img src='/assets/images/chat-bot.svg' alt='' />
              </div>
              <p>
                {t('send-a-message')}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HealthOverviewHeader;
