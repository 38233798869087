import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { currentMonth, newDate, todayDay } from "../../helpers/constants";
import { minTwoDigits } from "../../helpers/helpers";
import SmallCalendar from "../Calendar/SmallCalendar";

const DoctorScaleOfPain = ({ scaleOfPainData, onSelectDate }) => {
  const data = scaleOfPainData && scaleOfPainData[0];

  const { t, i18n } = useTranslation();

  const format = "DD-MM-YYYY";

  const [showCalendar, setShowCalendar] = useState(false);

  const [currentDate, setCurrentDate] = useState(
    moment(newDate).format(format)
  );

  const onSelectDay = async (year, month, date) => {
    const formatedSelectedDay = `${year}-${minTwoDigits(month + 1)}-${date}`;

    onSelectDate(formatedSelectedDay);

    setCurrentDate(formatedSelectedDay);

    setShowCalendar(false);
  };

  return (
    <div className="scale-pain">
      <div className="row">
        <div className="col-xxl-6">
          <div className="body-pain">
            <img
              className="img-fluid"
              src="/assets/health-overview.svg"
              alt=""
            />
          </div>
        </div>

        <div className="col-xxl-6">
          <div className="scale-describe results">
            <h3>
              {t("scale-of-pain")} <p>{t("of-the-patient")}</p>
            </h3>
            <div style={{ position: "relative" }}>
              <div
                onClick={() => setShowCalendar(true)}
                className="scale-the-date"
              >
                {t(moment(currentDate).format('MMMM'))} 
                {moment(currentDate).format(' Do YYYY')}

              </div>

              <div
                id="small-calendar"
                style={{
                  display: showCalendar ? "block" : "none",
                }}
              >
                <SmallCalendar
                  onSelectDay={onSelectDay}
                  activeDay={minTwoDigits(todayDay)}
                  formatCalendarFromMonth={currentMonth}
                />
              </div>
            </div>

            <ol className="scalce-results">
              {data && data.stomach ? (
                <li>
                  {t('stomach')}: {t("level-of-pain")}: {data.stomach}
                  <span>{data.stomach_comment}</span>
                </li>
              ): ""}
              {data && data.heart ? (
                <li>
                  {t('heart')}: {t("level-of-pain")}: {data.heart}
                  <span>{data.heart_comment}</span>
                </li>
              ): ""}
              {data && data.chest ? (
                <li>
                  {t('chest')}: {t("level-of-pain")}: {data.chest}
                  <span>{data.chest_comment}</span>
                </li>
              ): ""}
              {data && data.heat ? (
                <li>
                  {t('heat')}: {t("level-of-pain")}: {data.heat}
                  <span>{data.heat_comment}</span>
                </li>
              ): ""}
              {data && data.right_leg ? (
                <li>
                  {t('right_leg')}: {t("level-of-pain")}: {data.right_leg}
                  <span>{data.right_leg_comment}</span>
                </li>
              ): ""}
              {data && data.left_leg ? (
                <li>
                  {t('left_leg')}: {t("level-of-pain")}: {data.left_leg}
                  <span>{data.left_leg_comment}</span>
                </li>
              ): ""}
              {data && data.right_arm ? (
                <li>
                  {t('right_arm')}: {t("level-of-pain")}: {data.right_arm}
                  <span>{data.right_arm_comment}</span>
                </li>
              ): ""}
              {data && data.left_arm ? (
                <li>
                  {t('left_arm')}: {t("level-of-pain")}: {data.left_arm}
                  <span>{data.left_arm_comment}</span>
                </li>
              ): ""}
              {data && data.whole_body ? (
                <li>
                  {t('whole_body')}: {t("level-of-pain")}: {data.whole_body}
                  <span>{data.whole_body_comment}</span>
                </li>
              ): ""}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorScaleOfPain;
