import {
  PAYMENT_PLAN_FAIL,
  PAYMENT_PLAN_REQUEST,
  PAYMENT_PLAN_SUCCESS,
} from '../constants/payment';

export const paymentPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_PLAN_REQUEST:
      return { loading: true };
    case PAYMENT_PLAN_SUCCESS:
      return { loading: false, paymentPlan: action.payload };
    case PAYMENT_PLAN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
