import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./i18n";
import { Suspense } from "react";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div className="loading_wrapper">Loading...</div>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);


// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



