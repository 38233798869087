import React, { useEffect, useState } from 'react';
import Header from '../../components/DoctorUsers/Header';
import axios from 'axios';
import { API_ROUTES } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../components/LoadingIndicator';
import HealthOverViewContent from '../../components/DoctorUsers/HealthOverviewContent';
import { useParams } from 'react-router-dom';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const HealthOverviewDoctor = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let { id } = useParams();

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const [healthOverview, setHealthOverview] = useState([]);

  const [loading, setLoading] = useState(false);

  const getHealthStatus = async () => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.PATIENT.GET_HEALTH_OVERVIEW,
        {
          patient_id: id,
        },
        config
      )
      .then((response) => {
        setLoading(false);
        setHealthOverview(response.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHealthStatus();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Header />
      <HealthOverViewContent data={healthOverview} />
    </>
  );
};

export default HealthOverviewDoctor;
