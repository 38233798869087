import { useEffect, useState } from "react";
import "../../styles/smallcalendar.css";
import { formatCustomCalendar } from "../../helpers/helpers";
import { months } from "../../helpers/constants";
import { useTranslation } from 'react-i18next';

const SmallCalendar = ({ onSelectDay, formatCalendarFromMonth, activeDay }) => {
  const [currentYEAR, setCurrentYear] = useState(new Date().getFullYear());
  const [calendar, setCalendar] = useState([]);
  const [selectedDay, setSelectedDay] = useState(activeDay);
  const [currentMonth, setCurrentMonth] = useState(formatCalendarFromMonth);

  const { t, i18n } = useTranslation();


  const onBackMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear((year) => year - 1);
      setCurrentMonth(12);
      return;
    }
    setCurrentMonth((currentState) => currentState - 1);
  };

  const onNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear((year) => year + 1);
      setCurrentMonth(0);
      return;
    }
    setCurrentMonth((currentState) => currentState + 1);
  };

  useEffect(() => {
    setCalendar(formatCustomCalendar(currentMonth, currentYEAR));
  }, [currentMonth]);

  useEffect(() => {
    onSelectDay(currentYEAR, currentMonth, selectedDay);
  }, []);

  const onChooseDate = (element) => {
    setSelectedDay(element.value);
    onSelectDay(currentYEAR, currentMonth, element.value);
  };

  return (
    <div className="small-calendar-container">
      <div className="small-calendar-date-selector">
        <button
          onClick={onBackMonth}
          className="rd-back m-0"
          type="button"
        ></button>
        <button
          onClick={onNextMonth}
          className="rd-next m-0"
          type="button"
        ></button>
        <div className="text-center">
          {t(months[currentMonth])} {currentYEAR}
        </div>
      </div>
      <section className="small-calendar_header">
        <div className="small-calendar_header_item">S</div>
        <div className="small-calendar_header_item">M</div>
        <div className="small-calendar_header_item">T</div>
        <div className="small-calendar_header_item">W</div>
        <div className="small-calendar_header_item">T</div>
        <div className="small-calendar_header_item">F</div>
        <div className="small-calendar_header_item">S</div>
      </section>
      <section className="small-calendar_body">
        {calendar &&
          calendar.map((element, index) => {
            return (
              <div
                key={index}
                onClick={() => onChooseDate(element)}
                className={`small-calendar_item ${
                  selectedDay === element.value &&
                  element.active &&
                  "small-calendar_item-active"
                } ${!element.active && "small-calendar_item-inactive"}`}
              >
                <div>{element.value}</div>
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default SmallCalendar;
