export const PATIENT_REGISTER_REQUEST = 'PATIENT_REGISTER_REQUEST';
export const PATIENT_REGISTER_SUCCESS = 'PATIENT_REGISTER_SUCCESS';
export const PATIENT_REGISTER_FAIL = 'PATIENT_REGISTER_FAIL';

export const PATIENT_UPDATE_PROFILE_REQUEST = 'PATIENT_UPDATE_PROFILE_REQUEST';
export const PATIENT_UPDATE_PROFILE_SUCCESS = 'PATIENT_UPDATE_PROFILE_SUCCESS';
export const PATIENT_UPDATE_PROFILE_FAIL = 'PATIENT_UPDATE_PROFILE_FAIL';

export const PATIENT_ACCEPT_APOINTMENT_REQUEST =
  'PATIENT_ACCEPT_APOINTMENT_REQUEST';
export const PATIENT_ACCEPT_APOINTMENT_SUCCESS =
  'PATIENT_ACCEPT_APOINTMENT_SUCCESS';
export const PATIENT_ACCEPT_APOINTMENT_FAIL = 'PATIENT_ACCEPT_APOINTMENT_FAIL';

export const PATIENT_REFUSE_APOINTMENT_REQUEST =
  'PATIENT_REFUSE_APOINTMENT_REQUEST';
export const PATIENT_REFUSE_APOINTMENT_SUCCESS =
  'PATIENT_REFUSE_APOINTMENT_SUCCESS';
export const PATIENT_REFUSE_APOINTMENT_FAIL = 'PATIENT_REFUSE_APOINTMENT_FAIL';
