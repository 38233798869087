import React from 'react';
import { useTranslation } from 'react-i18next';

const DocumentNotification = ({ message }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='notification-item'>
      <div className='notification-desc'>
        <div className='notification-icon sun'>
          <img src='assets/images/document.svg' alt='Document' />
        </div>
        <div className='notification-info'>
          <h3>{t('document')}!</h3>
          <p>
            {message}
            {/* <a href="/">{t("check-it-now")}</a> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DocumentNotification;
