import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PatientCard = ({ data }) => {
  const { t, i18n } = useTranslation();
  // console.log('DATA CARD', data);

  const doctorPicture = data?.appointment?.patient?.profile_picture
    ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${data?.appointment?.patient?.profile_picturee}`
    : '/assets/images/doctors/doctor.jpg';

  return (
    <div className='profile'>
      <div className='profile-image'>
        <div
          className='profile-image-wrapper'
          style={{
            background: `url(${doctorPicture})`,
          }}
        ></div>
        <img src={doctorPicture} alt='Doctor Name' />
      </div>

      <div className='profile-info'>
        <div className='personal'>
          <h3 className='name'>
            {data?.appointment?.patient?.name}{' '}
            {data?.appointment?.patient?.surname}{' '}
          </h3>
          <ul>
            <li>
              {moment(new Date(data?.appointment?.start_date)).format(
                'DD-MM-YYYY'
              )}{' '}
              -{' '}
              {moment(new Date(data?.appointment?.start_date)).format(
                'hh:mm A'
              )}{' '}
              -{' '}
              {moment(new Date(data?.appointment?.end_date)).format('hh:mm A')}
            </li>
            <li>
              <div className='doctor-price'>
                <span>$ {data?.appointment?.payment_price}</span>
              </div>
            </li>
            <li>
              <div>
                {t('notes')}: {data?.appointment?.notes}
              </div>
            </li>
            <li>
              <div>
                {t('reason-visit')}: {data?.appointment?.reason_visit}
              </div>
            </li>
            <li>
              <div>
                {t('payment-date')}:{' '}
                {moment(new Date(data?.created_at)).format(
                  'DD-MM-YYYY hh:mm A'
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PatientCard;
