import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CalendarHeader = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='container-xxl page-heading'>
      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
          <Link to='/dashboard'>
            <div className='back-to'>{t('back-to-dashboard')}</div>
          </Link>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
          <div className='user-register-label documents'>
            <img src='/assets/images/calendar.svg' />
            <span>{t('calendar')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
