import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  createApointmentActions,
  getApointmentById,
} from '../../actions/apointmentActions';
import Message from '../../components/Message';
import axios from 'axios';

import io from 'socket.io-client';
import { getItemFromLocalStorage } from '../../helpers/helpers';

import {
  API_ROUTES,
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  socketToken,
  socketURL,
  SOCKET_ROUTES,
  STATUS,
} from '../../helpers/constants';

const socket = io(socketURL, {
  transports: [ "websocket" ],
  query: { token: socketToken },
});

const UpdateBookVisitScreen = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  let { id } = useParams();
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [first_visit, setFirstVisit] = useState(true);
  const [describe, setDescribe] = useState('');
  const [specialization_id, setSpecializationId] = useState('');
  const [price, setPrice] = useState('');

  const [drMessiAgree, setDrMesiAgree] = useState(false);
  const [personalHealtAgree, setPersonalHealthAgree] = useState(false);
  const [message, setMessage] = useState(null);

  const [acceptThirdParty, setAcceptThirdParty] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [app_third_party_payment, setAppThirdParty] = useState('');

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  let history = useNavigate();
  useEffect(() => {
    if (!userInfo) {
      history('/login');
    }
  }, [history, userInfo]);

  useEffect(() => {
    if (!socket.connected)
    socket.connect();
    return () => {
      if (socket.connected)
      socket.disconnect();
    }
  }
  , []);

  useEffect(() => {
    if (token?.tokenParams) {
      setEmail(token.tokenParams.email);
      setPhoneNumber(token.tokenParams.phone_number);
      setName(token.tokenParams.name);
      setSurname(token.tokenParams.surname);
    }
  }, [token]);

  const createApointmentData = useSelector((state) => state.createApointment);
  const { loading, error, createApointment } = createApointmentData;

  const makeFreePayment = async (appointment_id) => {
    await axios
      .post(
        API_ROUTES.PATIENT.MAKE_FREE_PAYMENT,
        {
          appointment_id: appointment_id,
        },
        {
          headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo?.data}`,
          }
        },
      )
  }

  useEffect(() => {
    if (createApointment?.status_code === 200) {
      let socketData = createApointment.data;
      
      if (parseInt(socketData.payment_price) === 0 && socketData.is_group_appointment === 1 ) {
        makeFreePayment(socketData.id);
      }
      socketData.appointment_status = STATUS.PENDING;
      socketData.patient_user_appointment = token.tokenParams;

      socketData.status = 1;
      socketData.payment_status = 0;


      socket.emit(
        SOCKET_ROUTES.EVENTS,
        doctor?.data?.doctor_id,
        STATUS.PENDING,
        socketData
      );
      history(`/confirm-apointment/${createApointment.data.id}`);
    }
  }, [createApointment, history, id]);

  //   console.log(token.tokenParams)

  const doctorData = useSelector((state) => state.doctor);
  const { doctor } = doctorData;

  useEffect(() => {
    dispatch(getApointmentById(id));
  }, [dispatch, id]);

  let specialization = [];
  if (doctor?.data) {
    specialization.push(
      doctor?.data?.doctor_user_appointment?.doctor_profile
        ?.primary_specialization
    );
    specialization.push(
      doctor?.data?.doctor_user_appointment?.doctor_profile
        ?.secondary_specialization
    );
  }


  const submitHandller = (e) => {
    e.preventDefault();
    // let found = getPrices(specialization_id);
    // console.log(found);
    if (!drMessiAgree) {
      setMessage(t('agree-message'));
    } else if (!personalHealtAgree) {
      setMessage(t('agree-info'));
    } else {
      setMessage(null);

      socket.emit(SOCKET_ROUTES.NOTIFICATIONS, doctor?.data?.doctor_id, {
        message: NOTIFICATIONS_TEXT.PATIENT.CREATE_APPOINTMENT,
        type: NOTIFICATIONS_TYPES.SUCCESS,
      });

      dispatch(
        createApointmentActions(
          doctor?.data?.doctor_id,
          Boolean(first_visit),
          describe,
          price,
          id,
          specialization_id,
          app_third_party_payment ? app_third_party_payment : null
        )
      );
    }
  };

  const onAcceptThirdParty = () => {
    setAcceptThirdParty(!acceptThirdParty);
    setAppThirdParty('');
  };

  const onChangeRadio = (event) => {
    setAppThirdParty(event.target.value);
  };

  const isGroupAppointement = (termin) => {
    if (termin?.spots > 1) {
      return (true);
    }
    return (false);
  }

  useEffect(() => {
    if (isGroupAppointement(doctor?.data)) {
      setSpecializationId(doctor?.data?.doctor_specialization_price?.specialization_id);
      setPrice(doctor?.data?.doctor_specialization_price?.price);
    }
  })

  return (
    <>
      <div className='booking-appointment'>
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-6 col-md-12 col-12 mx-auto'>
              <div className='booking booking-small'>
                <h1>{t('book-your-visit')}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='book-form-container'>
        <img className='lfimg' src='/assets/images/header/left-yellow.svg' />
        <img
          className='rgimg'
          src='/assets/images/book-your-visit/female-doctor.svg'
        />
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-6 col-md-12 col-12 mx-auto'>
              <div className='book-form-title'>
                <h3>
                  {t('personal-data')} <span>({t('automatic-refil')})</span>
                </h3>
              </div>
              <div className='user-form booking-form'>
                <form onSubmit={submitHandller}>
                  <div className='row'>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='emailAddress' className='form-label'>
                        {t('email')} <span className='required'>*</span>
                      </label>
                      <input
                        type='email'
                        className='form-control'
                        readOnly
                        id='emailAddress'
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='phoneNumber' className='form-label'>
                        {t('phone-number')} <span className='required'>*</span>
                      </label>
                      <input
                        type='text'
                        readOnly
                        className='form-control'
                        id='phoneNumber'
                        placeholder='+0012345678'
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='firstName' className='form-label'>
                        {t('name')} <span className='required'>*</span>
                      </label>
                      <input
                        type='text'
                        readOnly
                        className='form-control'
                        id='firstName'
                        placeholder={t('name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='surname' className='form-label'>
                        {t('surname')} <span className='required'>*</span>
                      </label>
                      <input
                        type='text'
                        readOnly
                        className='form-control'
                        id='surname'
                        placeholder={t('surname')}
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div
                      onChange={(e) => setFirstVisit(e.target.value)}
                      className='col-sm-6 col-12 mb-3'
                    >
                      <label
                        htmlFor='surname'
                        className='form-label label-block'
                      >
                        {t('first-visit-question')}
                      </label>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='first_visit'
                          value={true}
                          // onChange={(e) => setFirstVisit(e.target.value)}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='inlineRadio1'
                        >
                          {t('first-visit-answer-yes')}
                        </label>
                      </div>

                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='first_visit'
                          value={false}
                          // onChange={(e) => setFirstVisit(e.target.value)}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='inlineRadio2'
                        >
                          {t('first-visit-answer-no')}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* *************************
                  <div
                    className='row'
                    onChange={(e) => setAcceptThirdParty(e.target.value)}
                  >
                    <label htmlFor='accept_third_party' className='form-label'>
                      {t('accept-third-party')}
                    </label>
                    <div className='third_party_buttons'>
                      <div className='form-check mb-3'>
                        <input
                          className='form-check-input'
                          style={{ backgroundColor: '#79D2CC', display: 'inline' }}
                          type='radio'
                          name='accept_third_party'
                          value='YES'
                          // onChange={(e) => setFirstVisit(e.target.value)}
                        />
                        <label
                          className='form-check-label'
                          style={{ color: '#A1A5A8' }}
                          htmlFor='accept_third_party'
                        >
                          {t('first-visit-answer-yes')}
                        </label>
                      </div>

                      <div className='form-check mb-3'>
                        <input
                          className='form-check-input'
                          style={{ backgroundColor: '#79D2CC' }}
                          type='radio'
                          name='accept_third_party'
                          value='NO'
                          // onChange={(e) => setFirstVisit(e.target.value)}
                        />
                        <label
                          className='form-check-label'
                          style={{ color: '#A1A5A8' }}
                          htmlFor='accept_third_party'
                        >
                          {t('first-visit-answer-no')}
                        </label>
                      </div>
                    </div>
                  </div>
                  ************************* */}
                  { isGroupAppointement(doctor?.data) ?
                  <>
                  </>
                  :
                  <>
                    <select
                      className='form-select'
                      aria-label='Visit title'
                      value={specialization_id}
                      onChange={(e) => {
                        setSpecializationId(e.target.value);
                      }}
                    >
                    <option>{t('select-specialization-for-visit')}</option>
                    {specialization &&
                      specialization.map((sp) => {
                        return (
                          <option key={sp?.id} value={sp?.id}>
                            {t(sp?.title)}
                          </option>
                        );
                      })}
                    </select>
                    <br />
                    <select
                      className='form-select'
                      aria-label='Visit title'
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    >
                      <option>{t('select-price-for-specialization')}</option>
                      {doctor?.data?.doctor_user_appointment?.doctor_profile
                        ?.doctor_specialization_prices &&
                        doctor?.data?.doctor_user_appointment?.doctor_profile?.doctor_specialization_prices.map(
                          (sp) => {
                            if (specialization_id == sp.specialization_id) {
                              return (
                                <option key={sp.id} value={sp.price}>
                                  {sp.price} {t(sp.title)}
                                </option>
                              );
                            }
                          }
                        )}
                    </select>
                  </>
                  }

                  <div className='row'>
                    <div className='col-sm-12 col-12 mb-3'>
                      <label htmlFor='reason' className='form-label'>
                        {t('reason-for-visit')}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='reason'
                        placeholder={t('describe')}
                        value={describe}
                        onChange={(e) => setDescribe(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-12 col-12 mb-3'>
                      <label htmlFor='surname' className='form-label'>
                        {t('doctor-decide-visit')}
                        <br />
                        {t('based-on-payment')}
                      </label>

                      <div className='form-check'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='drMessiAgree'
                          value={drMessiAgree}
                          onClick={(e) => {
                            setDrMesiAgree(e);
                          }}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='drMessiAgree'
                        >
                          * {t('agree-for-appointment-privacy')}
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='personalHealtAgree'
                          value={personalHealtAgree}
                          onClick={(e) => {
                            setPersonalHealthAgree(e);
                          }}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='personalHealtAgree'
                        >
                          * {t('agree-for-personal-health')}.{' '}
                          <Link to='/' className='find-more'>
                            {t('find-out-more')}.{' '}
                          </Link>
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name=''
                          id='medicalAppointments'
                          value='3'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='medicalAppointments'
                        >
                          {t('agree-for-reciving-information')}.{' '}
                          <Link to='/' className='find-more'>
                            {t('find-out-more')}
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>
                  {doctor &&
                    doctor?.data &&
                    doctor.data.doctor_user_appointment.doctor_profile?.third_party_payment === 'YES' && (
                      <div>
                        <input
                          className='form-check-input'
                          id='thirdparty'
                          onChange={onAcceptThirdParty}
                          type='checkbox'
                        />
                        <label
                          htmlFor='thirdparty'
                          style={{ marginLeft: '5px', marginBottom: '10px' }}
                        >
                          {t('i-accept-third-party')}
                        </label>
                      </div>
                    )}

                  {acceptThirdParty && (
                    <div onChange={onChangeRadio}>
                      <span>{t('select-reason')}</span>
                      <div>
                        <input
                          type='radio'
                          value='pregnant'
                          id='pregnant'
                          name='pregnant'
                          className='form-check-input'
                          checked={app_third_party_payment == 'pregnant'}
                        />{' '}
                        <label htmlFor='pregnant'>{t('pregnant')}</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          value='beneficary'
                          id='beneficary'
                          name='beneficary'
                          className='form-check-input'
                          checked={app_third_party_payment == 'beneficary'}
                        />{' '}
                        <label htmlFor='beneficary'>{t('beneficiary')}</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          value='statemedical'
                          id='statemedical'
                          name='statemedical'
                          className='form-check-input'
                          checked={app_third_party_payment == 'statemedical'}
                        />{' '}
                        <label htmlFor='statemedical'>{t('recipient')}</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          value='nocase'
                          id='nocase'
                          name='nocase'
                          className='form-check-input'
                          checked={app_third_party_payment == 'nocase'}
                        />{' '}
                        <label htmlFor='nocase'>{t('none-case')}</label>
                      </div>
                    </div>
                  )}

                  {message && <Message variant='danger'>{message}</Message>}

                  {error && <Message variant='danger'>{error}</Message>}

                  <button type='submit' className='btn btn-ocean'>
                    {t('book-visit')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBookVisitScreen;
