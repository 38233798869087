import { useMemo } from 'react';

export const PAYMENT_PLAN_REQUEST = 'PAYMENT_PLAN_REQUEST';
export const PAYMENT_PLAN_SUCCESS = 'PAYMENT_PLAN_SUCCESS';
export const PAYMENT_PLAN_FAIL = 'PAYMENT_PLAN_FAIL';

export const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: '16px',
          letterSpacing: '0.025em',
          border: '1px solid red',
          backgroundColor: '#fff',
          padding: 16,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};
