import React, { useState, useEffect } from "react";
import jwt from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { login } from "../actions/userActions";
import { appRoutes } from "../helpers/constants";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  let navigate = useNavigate();
  useEffect(() => {
    if (token?.tokenParams) {
      navigate('/dashboard', { replace: true})
    }
  }, [token, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    // Dispatch Login
    dispatch(login(email, password));
  };

  return (
    <div className="container-xxl">
      <div className="row">
        <div className="col-xxl-4 col-md-12 col-12 mx-auto">
          <div className="user-form-container">
            <div className="user-text">
              <h2>{t("welcome-back")}</h2>
              <p>{t("clinic-doctor-message")}</p>
            </div>
            <div className="user-form">
              <form onSubmit={submitHandler}>
                {loading && <Loader />}
                {error && <Message variant="danger">{error}</Message>}
                <div className="mb-3">
                  <label htmlFor="emailAddress" className="form-label">
                    {t("email")}
                  </label>
                  <input
                    type="email"
                    required
                    className="form-control"
                    id="emailAddress"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    {t("password")}
                  </label>
                  <input
                    type="password"
                    required
                    className="form-control"
                    id="password"
                    placeholder={t("password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <!-- className:: error // Show error message --> */}
                  <div className="form-text error">
                    {t("must-be-eight-caracter")}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-end">
                    <Link to="/forgot-password" className="forgot-password">
                      {t("forgot-password")}?
                    </Link>
                  </div>
                </div>
                <button type="submit" className="btn btn-ocean">
                  {t("sign-in")}
                </button>
              </form>
              <div className="user-register-signup">
                <Link to="/register-patient" className="signup">
                  {t("dont-have-account")}? <span>{t("sign-up-for-free")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
