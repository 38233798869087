import axios from 'axios';
import {
  DOCTOR_CREATE_TERMIN_FAIL,
  DOCTOR_CREATE_TERMIN_REQUEST,
  DOCTOR_CREATE_TERMIN_SUCCESS,
  DOCTOR_GET_PROFILE_FAIL,
  DOCTOR_GET_PROFILE_REQUEST,
  DOCTOR_GET_PROFILE_SUCCESS,
  DOCTOR_PROPOSE_ONLINE_FAIL,
  DOCTOR_PROPOSE_ONLINE_REQUEST,
  DOCTOR_PROPOSE_ONLINE_SUCCESS,
  DOCTOR_PROPOSE_PHYSICAL_FAIL,
  DOCTOR_PROPOSE_PHYSICAL_REQUEST,
  DOCTOR_PROPOSE_PHYSICAL_SUCCESS,
  DOCTOR_PUBLIC_SCREEN_FAIL,
  DOCTOR_PUBLIC_SCREEN_REQUEST,
  DOCTOR_PUBLIC_SCREEN_SUCCESS,
  DOCTOR_REFUSE_APOINTMENT_FAIL,
  DOCTOR_REFUSE_APOINTMENT_REQUEST,
  DOCTOR_REFUSE_APOINTMENT_SUCCESS,
  DOCTOR_UPDATE_PROFILE_FAIL,
  DOCTOR_UPDATE_PROFILE_REQUEST,
  DOCTOR_UPDATE_PROFILE_SUCCESS,
  FREELENCE_DOCTOR_CREATE_PRICE_FAIL,
  FREELENCE_DOCTOR_CREATE_PRICE_REQUEST,
  FREELENCE_DOCTOR_CREATE_PRICE_SUCCESS,
  GET_ALL_SPECIALIZATION_FAIL,
  GET_ALL_SPECIALIZATION_REQUEST,
  GET_ALL_SPECIALIZATION_SUCCESS,
  GET_DOCTOR_BY_UID_FAIL,
  GET_DOCTOR_BY_UID_REQUEST,
  GET_DOCTOR_BY_UID_SUCCESS,
  REGISTER_CLINIC_DOCTOR_FAIL,
  REGISTER_CLINIC_DOCTOR_REQUEST,
  REGISTER_CLINIC_DOCTOR_SUCCESS,
  REGISTER_FREELENCE_DOCTOR_FAIL,
  REGISTER_FREELENCE_DOCTOR_REQUEST,
  REGISTER_FREELENCE_DOCTOR_SUCCESS,
} from '../constants/doctorConstants';
import { USER_LOGIN_SUCCESS } from '../constants/userConstants';
import { getItemFromLocalStorage } from '../helpers/helpers';

export const getAllSpecialization = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_SPECIALIZATION_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PRODUCTION_API}drop-down-specializations`,
      {},
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: GET_ALL_SPECIALIZATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SPECIALIZATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clinicDoctorRegisterActions =
  (
    email,
    phoneNumber,
    name,
    surname,
    password,
    primary_specialization_id,
    secondary_specialization_id
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_CLINIC_DOCTOR_REQUEST,
      });

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}register`,
        {
          email,
          password,
          name,
          surname,
          phone_number: phoneNumber,
          usertype: 'clinic_doctor',
          city: 'Amsterdam',
          street: 'Jan Hanzenstraat',
          house_number: '32',
          primary_specialization_id,
          secondary_specialization_id,
          plan: '6c43895b-a2dd-49e0-8655-e516edccba18',
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status === 'error') {
        throw new Error(data.data);
      }

      dispatch({
        type: REGISTER_CLINIC_DOCTOR_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      dispatch({
        type: REGISTER_CLINIC_DOCTOR_SUCCESS,
        payload: {},
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: REGISTER_CLINIC_DOCTOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const freelenceDoctorRegisterActions =
  (
    email,
    phoneNumber,
    name,
    surname,
    password,
    primary_specialization_id,
    secondary_specialization_id,
    city,
    address,
    nr,
    accept_third_party
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_FREELENCE_DOCTOR_REQUEST,
      });

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}register`,
        {
          email,
          password,
          name,
          surname,
          phone_number: phoneNumber,
          usertype: 'freelance_doctor',
          city: city,
          street: address,
          house_number: nr,
          primary_specialization_id,
          secondary_specialization_id,
          plan: '6c43895b-a2dd-49e0-8655-e516edccba18',
          third_party_payment: accept_third_party,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status_code === 400) {
        throw new Error(data.data);
      }

      dispatch({
        type: REGISTER_FREELENCE_DOCTOR_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      dispatch({
        type: REGISTER_FREELENCE_DOCTOR_SUCCESS,
        payload: {},
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: REGISTER_FREELENCE_DOCTOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doctorUpdatedProfileActions =
  (
    id,
    email,
    phone_number,
    name,
    surname,
    password,
    id_number,
    sanitary_card,
    gender,
    birthday,
    healt_insurance,
    this_page_required_info,
    file_name,
    profile_picture,
    nationality,
    country,
    city,
    street,
    house_number,
    safety_measures,
    services,
    formation_and_specialization,
    disease_treated,
    languages,
    third_party_payment,
    primary_specialization_id,
    secondary_specialization_id
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DOCTOR_UPDATE_PROFILE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}users/update`,
        {
          id,
          email,
          phone_number,
          name,
          surname,
          password,
          id_number,
          sanitary_card,
          gender,
          birthday,
          healt_insurance,
          this_page_required_info,
          file_name,
          profile_picture,
          nationality,
          country,
          city,
          street,
          house_number,
          safety_measures,
          services,
          formation_and_specialization,
          disease_treated,
          languages,
          third_party_payment,
          primary_specialization_id,
          secondary_specialization_id,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status_code === 400) {
        throw new Error(data.data);
      }

      dispatch({
        type: DOCTOR_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: DOCTOR_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doctorCreateTerminActions =
  (inputFields) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DOCTOR_CREATE_TERMIN_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}doctors/create-appointment-termin`,
        inputFields,
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status_code === 400) {
        throw new Error(data.data);
      }

      dispatch({
        type: DOCTOR_CREATE_TERMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOCTOR_CREATE_TERMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const freelenceDoctorCreatePriceActions =
  (specialization_id, title, price) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FREELENCE_DOCTOR_CREATE_PRICE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}doctors/create-specialization-price`,
        { specialization_id, title, price },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: FREELENCE_DOCTOR_CREATE_PRICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FREELENCE_DOCTOR_CREATE_PRICE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doctorGetProfileActions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOCTOR_GET_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };

    const { data } = await axios.post(`${process.env.REACT_APP_PRODUCTION_API}doctors/profile`, {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: DOCTOR_GET_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOCTOR_GET_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const doctorProposeOnlineActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DOCTOR_PROPOSE_ONLINE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}doctors/appointment/propose-online`,
        { id },
        config
      ).then((response) => {
        return response;
      }).catch((error) => {
        console.log(error);
      });

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: DOCTOR_PROPOSE_ONLINE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOCTOR_PROPOSE_ONLINE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doctorProposePhysicalActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DOCTOR_PROPOSE_PHYSICAL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}doctors/appointment/propose-studio`,
        { id },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: DOCTOR_PROPOSE_PHYSICAL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOCTOR_PROPOSE_PHYSICAL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doctorRefuseApointmentActions =
  (id, cancel_message) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DOCTOR_REFUSE_APOINTMENT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}doctors/appointment/cancel`,
        { id, cancel_message },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: DOCTOR_REFUSE_APOINTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOCTOR_REFUSE_APOINTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doctorPublicScreenActions = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DOCTOR_PUBLIC_SCREEN_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PRODUCTION_API}doctor/public-profile/${id}`,
      {},
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: DOCTOR_PUBLIC_SCREEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOCTOR_PUBLIC_SCREEN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDoctorByUidActions = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCTOR_BY_UID_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}doctors/profile-by-uid`,
      { uid: id },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: GET_DOCTOR_BY_UID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DOCTOR_BY_UID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
