import React from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../helpers/constants';

const FreelenceDoctorSetingsScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { t, i18n } = useTranslation();

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  return (
    <>
    <div className='doctor_settings_page'>
        <div className='page-hero'>
          <div className='container-xxl page-heading'>
            <div className='row'>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                <Link to='/dashboard' className='back-to'>
                    {t('back-to-dashboard')}
                </Link>
              </div>
              <div className='col-sm-4 col-12 text-center'>
                <div className='user-register-label nature'>
                  <img src='assets/images/money.svg' alt='Money' />
                  <span>{t('settings')}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='container-xxl'>
            <div className='row'>
              <div className='col-xxl-8 col-md-12 col-12 mx-auto'>
                <div className='page-info'>
                  <div className='page-text'>
                    <h1>{t('freelance-doctor-settings-message')}. </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-12'>
              <div className='users-box user-edit-profile'>
                <Link
                  to={appRoutes.DOCTOR.CREATE_PRICE}
                  className='user-register-type'
                >
                    <div href='#' className='user-register-label nature'>
                      <img src='assets/images/money.svg' alt='Money' />
                    </div>
                    <div className='user-register-description'>
                      <h4>{t('your-prices')}</h4>
                      <p>{t('freelance-working-hour-message-two')}</p>
                    </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreelenceDoctorSetingsScreen;
