import {
  DOCTOR_CREATE_TERMIN_FAIL,
  DOCTOR_CREATE_TERMIN_REQUEST,
  DOCTOR_CREATE_TERMIN_SUCCESS,
  DOCTOR_GET_PROFILE_FAIL,
  DOCTOR_GET_PROFILE_REQUEST,
  DOCTOR_GET_PROFILE_SUCCESS,
  DOCTOR_PROPOSE_ONLINE_FAIL,
  DOCTOR_PROPOSE_ONLINE_REQUEST,
  DOCTOR_PROPOSE_ONLINE_SUCCESS,
  DOCTOR_PROPOSE_PHYSICAL_FAIL,
  DOCTOR_PROPOSE_PHYSICAL_REQUEST,
  DOCTOR_PROPOSE_PHYSICAL_SUCCESS,
  DOCTOR_PUBLIC_SCREEN_FAIL,
  DOCTOR_PUBLIC_SCREEN_REQUEST,
  DOCTOR_PUBLIC_SCREEN_SUCCESS,
  DOCTOR_REFUSE_APOINTMENT_FAIL,
  DOCTOR_REFUSE_APOINTMENT_REQUEST,
  DOCTOR_REFUSE_APOINTMENT_SUCCESS,
  DOCTOR_UPDATE_PROFILE_FAIL,
  DOCTOR_UPDATE_PROFILE_REQUEST,
  DOCTOR_UPDATE_PROFILE_SUCCESS,
  FREELENCE_DOCTOR_CREATE_PRICE_FAIL,
  FREELENCE_DOCTOR_CREATE_PRICE_REQUEST,
  FREELENCE_DOCTOR_CREATE_PRICE_SUCCESS,
  GET_ALL_SPECIALIZATION_FAIL,
  GET_ALL_SPECIALIZATION_REQUEST,
  GET_ALL_SPECIALIZATION_SUCCESS,
  GET_DOCTOR_BY_UID_FAIL,
  GET_DOCTOR_BY_UID_REQUEST,
  GET_DOCTOR_BY_UID_SUCCESS,
  REGISTER_CLINIC_DOCTOR_FAIL,
  REGISTER_CLINIC_DOCTOR_REQUEST,
  REGISTER_CLINIC_DOCTOR_SUCCESS,
  REGISTER_FREELENCE_DOCTOR_FAIL,
  REGISTER_FREELENCE_DOCTOR_REQUEST,
  REGISTER_FREELENCE_DOCTOR_SUCCESS,
} from '../constants/doctorConstants';

export const getAllSpecializationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_SPECIALIZATION_REQUEST:
      return { loading: true };
    case GET_ALL_SPECIALIZATION_SUCCESS:
      return { loading: false, allSpecialization: action.payload };
    case GET_ALL_SPECIALIZATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const clinicDoctorRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_CLINIC_DOCTOR_REQUEST:
      return { loading: true };
    case REGISTER_CLINIC_DOCTOR_SUCCESS:
      return { loading: false, clinicDoctorRegister: action.payload };
    case REGISTER_CLINIC_DOCTOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const freelenceDoctorRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_FREELENCE_DOCTOR_REQUEST:
      return { loading: true };
    case REGISTER_FREELENCE_DOCTOR_SUCCESS:
      return { loading: false, freelenceDoctorRegister: action.payload };
    case REGISTER_FREELENCE_DOCTOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorUdpateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case DOCTOR_UPDATE_PROFILE_SUCCESS:
      return { loading: false, doctorInfo: action.payload };
    case DOCTOR_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorCreateTerminReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_CREATE_TERMIN_REQUEST:
      return { loading: true };
    case DOCTOR_CREATE_TERMIN_SUCCESS:
      return { loading: false, doctorCreateTermin: action.payload };
    case DOCTOR_CREATE_TERMIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const freelenceDoctorCreatePriceReducer = (state = {}, action) => {
  switch (action.type) {
    case FREELENCE_DOCTOR_CREATE_PRICE_REQUEST:
      return { loading: true };
    case FREELENCE_DOCTOR_CREATE_PRICE_SUCCESS:
      return { loading: false, freelenceDoctorCreatePrice: action.payload };
    case FREELENCE_DOCTOR_CREATE_PRICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorGetProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_GET_PROFILE_REQUEST:
      return { loading: true };
    case DOCTOR_GET_PROFILE_SUCCESS:
      return { loading: false, doctorProfile: action.payload };
    case DOCTOR_GET_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorProposeOnlineReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_PROPOSE_ONLINE_REQUEST:
      return { loading: true };
    case DOCTOR_PROPOSE_ONLINE_SUCCESS:
      return { loading: false, doctorProposeOnline: action.payload };
    case DOCTOR_PROPOSE_ONLINE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorProposePhysicalReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_PROPOSE_PHYSICAL_REQUEST:
      return { loading: true };
    case DOCTOR_PROPOSE_PHYSICAL_SUCCESS:
      return { loading: false, doctorProposePhysical: action.payload };
    case DOCTOR_PROPOSE_PHYSICAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorRefuseApointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_REFUSE_APOINTMENT_REQUEST:
      return { loading: true };
    case DOCTOR_REFUSE_APOINTMENT_SUCCESS:
      return { loading: false, doctorRefuseApointment: action.payload };
    case DOCTOR_REFUSE_APOINTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doctorPublicScreenReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCTOR_PUBLIC_SCREEN_REQUEST:
      return { loading: true };
    case DOCTOR_PUBLIC_SCREEN_SUCCESS:
      return { loading: false, publicDoctorScreen: action.payload };
    case DOCTOR_PUBLIC_SCREEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getDoctorByUidScreenReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DOCTOR_BY_UID_REQUEST:
      return { loading: true };
    case GET_DOCTOR_BY_UID_SUCCESS:
      return { loading: false, getDoctorByUid: action.payload };
    case GET_DOCTOR_BY_UID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
