import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { Loader } from 'react-bootstrap-typeahead';
import DatePicker, { registerLocale }from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { doctorCreateTerminActions } from '../../actions/doctorActions';
import Message from '../../components/Message';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { appRoutes } from '../../helpers/constants';

const DoctorCreateTerminScreen = () => {
  registerLocale('fr', fr);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [message, setMessage] = useState(null);
  const [elDisable, setElDisable] = useState(false);

  const [appointmentDuration, setAppointmentDuration] = useState(null);
  const [recurrenceString, setRecurrenceString] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  let history = useNavigate();
  useEffect(() => {
    if (!token) {
      history('/login');
    }
  }, [history, token]);

  const doctorCreateTerminData = useSelector(
    (state) => state.doctorCreateTermin
  );
  const { loading, error, doctorCreateTermin } = doctorCreateTerminData;

  const [inputFields, setInputFields] = useState([
    { day: new Date(), start: moment(new Date()).startOf("minute").toDate(), end: moment(new Date()).add(30, 'm').startOf('minute').toDate(), duration: 30, recurrence: '0', numberRecurrence: 1 },
  ]);

  useEffect(() => {
    updateDurationFormFields();
  }, []);

  const handleFormChange = (index, event, additionInfo = false) => {
    let data = [...inputFields];
    if (additionInfo) {
      if (additionInfo === 'day'){
        data[index]['start'].setDate(event.getDate());
        data[index]['start'].setMonth(event.getMonth());
        data[index]['start'].setFullYear(event.getFullYear());
        data[index]['end'].setDate(event.getDate());
        data[index]['end'].setMonth(event.getMonth());
        data[index]['end'].setFullYear(event.getFullYear());
        data[index][additionInfo] = event;
        setInputFields(data);
      }
      else {
        data[index][additionInfo] = event;
        setInputFields(data);
        updateDurationFormFields();
      }
    }
    else {
      data[index][event.target.name] = event.target.value;
      setInputFields(data);
      updateRecurrenceFormFields();
    }
  };

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { day: '', start: new Date(), end: new Date(), duration: 30, recurrence: '0', numberRecurrence: 1 };

    setInputFields([...inputFields, newfield]);
  };

  let removeFormFields = (e, i) => {
    e.preventDefault();
    let newFormValues = [...inputFields];
    newFormValues.splice(i, 1);
    setInputFields(newFormValues);
  };

  const updateDurationFormFields = (() => {
    
    if (inputFields.length === 0) {
      return;
    }

    if(inputFields[0].end > inputFields[0].start) {
      /* create a table with KEY = number of appointments VALUE = (start time - end time) / number of appointments(i)
      and value min 10 minutes */
      let appointmentArray = [];
      let duration = (inputFields[0].end - inputFields[0].start);
      // Convert to minutes rounded
      duration =  Math.round(duration / 60000);
      inputFields[0].duration = duration;
      appointmentArray.push({numberAppointments : 1 , duration : duration});
      for (let i = 2; duration > 10; i++) {
        duration = (inputFields[0].end - inputFields[0].start) / i;
        duration = duration / 60000;
        //Only add if duration is round value
        if (duration % 1 === 0){
          appointmentArray.push({numberAppointments : i , duration : duration});
        }
      }
      setAppointmentDuration(appointmentArray);
    }
  });

  const updateRecurrenceFormFields = (() => {
    switch (inputFields[0].recurrence) {
      case 'daily':
        setRecurrenceString(t('days'));
        break;
      case 'weekly':
        setRecurrenceString(t('weeks'));
        break;
      case 'monthly':
        setRecurrenceString(t('months'));
        break;
      // case 'daily-week':
      //   setRecurrenceString(t('days'));
      //   break;
      default:
        setRecurrenceString('');
        break;
    }
  });


  useEffect(() => {
    if (doctorCreateTermin?.status_code === 200) {
      setMessage(doctorCreateTermin?.data);
      // setDuration('');
      // setStartDate(new Date());
      // setEndDate(new Date());
    } else {
      setMessage(null);
    }
  }, [doctorCreateTermin]);

  let click = false;
  useEffect(() => {
    if (!error && click) {
      setElDisable(true);
    }
  }, [error, click]);

  const submitHandler = (e) => {
    e.preventDefault();

    let formatedArray = [];

    inputFields.forEach((element) => {
      element.start = moment(element.start).format('YYYY-MM-DD HH:mm');
      element.end = moment(element.end).format('YYYY-MM-DD HH:mm');
      formatedArray.push(element);
    });
    click = true;
    dispatch(doctorCreateTerminActions(inputFields));
    setInputFields([]);
  };

  return (
    <>
      <div className='doctor_working_hours'>
        <div className='container-fluid page-heading'>
          <div className='row'>
            <div className='col-sm-4 col-12'>
              <Link to='/doctor-setings' className='back-to'>
                {t('back-to-settings')}
              </Link>
            </div>
            <div className='col-sm-4 col-12 text-center'>
              <div className='user-register-label ocean'>
                <img
                  src='/assets/images/calendar-workingHours.svg'
                  alt='calendar'
                />
                <span>{t('working-hours')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-5 col-md-12 col-12 mx-auto'>
              <div className='user-form-container'>
                <div className='user-text'>
                  <p>{t('create-termin-message')}.</p>
                </div>

                <div className='user-form'>
                  <form onSubmit={submitHandler}>
                    {inputFields.map((input, index) => {
                      return (
                        <div className='col-sm-6 col-12 mb-3 w-100' key={index}>
                          <div className='d-flex flex-column mt-2'>
                            <label htmlFor='' className='form-label'>
                              {t('select-day')}
                            </label>
                            <DatePicker
                              locale={fr}
                              className='form-control'
                              selected={input.day}
                              onChange={(event) =>
                                handleFormChange(index, event, 'day')
                              }
                              name='day'
                              dateFormat='dd/MM/yyyy'
                              minDate={new Date()}
                              showDisabledMonthNavigation
                            />
                          </div>
                          <div className='d-flex flex-row justify-content-between mt-2'>
                            <div className='d-flex flex-column'>
                              <label htmlFor='' className='form-label'>
                                {t('start-hour')}
                              </label>
                              <DatePicker
                                locale={fr}
                                showTimeSelectOnly = {true}
                                timeIntervals={10}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className='form-control'
                                selected={input.start}
                                onChange={(event) =>
                                  handleFormChange(index, event, 'start')
                                }
                                showTimeSelect
                                name='start'
                              />
                            </div>
                            <div className='d-flex flex-column'>
                              <label htmlFor='' className='form-label'>
                                {t('end-hour')}
                              </label>
                              <DatePicker
                                locale={fr}
                                showTimeSelectOnly = {true}
                                timeIntervals={10}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className='form-control'
                                selected={input.end}
                                onChange={(event) =>
                                  handleFormChange(index, event, 'end')
                                }
                                showTimeSelect
                                name='end'
                              />
                            </div>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor='' className='form-label'>
                              {t('time-of-appointments')}
                            </label>  
                            <select
                              className='form-control'
                              name='duration'
                              value={input.duration}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                            {appointmentDuration && appointmentDuration.map((element, index) => {
                              return (
                                <option key={index} value={element.duration}>{element.numberAppointments} {element.numberAppointments === 1 ? t('slot') : t('slots')} | {element.duration} {t('minutes')}</option>
                              )
                            })}
                            </select>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor='' className='form-label'>
                              {t('recurrence')}
                            </label>  
                            <select
                              className='form-control'
                              name='recurrence'
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <option value='0'>{t('once')}</option>
                              <option value='daily'>{t('daily')}</option>
                              <option value='weekly'>{t('weekly')}</option>
                              <option value='monthly'>{t('monthly')}</option>
                              {/* <option value='daily-week'>{t('daily-no-weekend')}</option> */}
                            </select>
                          </div>
                          { input.recurrence &&
                            input.recurrence !== '0' && (
                            <div className='mt-2'>
                            <label htmlFor='' className='form-label'>
                              {t('number-of-recurrence')} {recurrenceString}
                            </label>  
                            <input
                              type='number'
                              className='form-control'
                              min={1}
                              name='numberRecurrence'
                              value={input.numberRecurrence}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </div>
                          )}
                        </div>
                      );
                    })}

                    {/* <button
                      className='user-register-label ocean add_more'
                      onClick={addFields}
                    >
                      {t('add-more')}
                    </button> */}

                    {inputFields.length > 1 && (
                      <button
                        style={{ margin: '0.5rem' }}
                        onClick={removeFormFields}
                      >
                        {t('remove')}
                      </button>
                    )}
                    {message && <Message variant='success'>{message}</Message>}
                    {error && <Message variant='danger'>{error}</Message>}

                    <div
                      className='d-flex flex-row justify-content-between'
                      style={{ gap: '10px' }}
                    >
                      <button
                        type='submit'
                        className='btn btn-darkblue'
                        disabled={loading || elDisable}
                      >
                        {t('save-edits')} <span>{loading && <Loader />}</span>
                      </button>
                      <Link
                        className='btn btn-darkblue'
                        to={appRoutes.DOCTOR.UPCOMING_TERMINS}
                      >
                        <div>{t('view-previous-edit')}</div>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorCreateTerminScreen;
