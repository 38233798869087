import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCity } from "../../actions/apointmentActions";
import { getAllSpecialization } from "../../actions/doctorActions";
import CustomMap from "../../components/CustomMap";
import DoctorAppointemnt from "../../components/DoctorAppointment";
import DoctorCard from "../../components/DoctorCard";
import LoadingIndicator from "../../components/LoadingIndicator";
import { API_ROUTES } from "../../helpers/constants";
import Hammer from "react-hammerjs";

const ShowAllAvailableApointmentScreen = () => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(1);

  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [appointmentPage, setAppointmentPage] = useState(0);
  const [price, setPrice] = useState();

  const [loading, setLoading] = useState(false);

  const [loadMore, setLoadMore] = useState(true);

  const [doctorList, setDoctorList] = useState([]);

  const [selectedSpecialization, setSelectedSpecialization] = useState([]);

  // Get all specialization from db
  const allSpecializationData = useSelector((state) => state.allSpecialization);

  const [tabSwitch, setTabSwitch] = useState(0);

  useEffect(() => {
    dispatch(getAllSpecialization());
  }, [dispatch]);

  // Get all city from db
  const allCityData = useSelector((state) => state.allCity);
  const { allCity } = allCityData;

  useEffect(() => {
    dispatch(getAllCity());
  }, [dispatch]);

  const fetchAllAppointments = async (
    pageNumber,
    name,
    city,
    price,
    specialization_ids
  ) => {
    setLoading(true);
    let doctors = [];
    await axios
      .post(API_ROUTES.GET_ALL_APPOINTMENTS, {
        page: pageNumber,
        name: name,
        city: city,
        price: price,
        specialization_ids: specialization_ids,
      })
      .then((response) => {
        setLoading(false);
        doctors = response;
      })
      .catch(() => {
        setLoading(false);
      });

    return doctors;
  };

  useEffect(() => {
    fetchAllAppointments(1).then((response) => {
      if (response.data.data.last_page == response.data.data.current_page) {
        setLoadMore(false);
      }

      setDoctorList(response.data.data.data);
    });
  }, []);

  const submitHandler = (e) => {
    if (e) e.preventDefault();
    setPage(1);
    setLoadMore(true);
    setDoctorList([]);
    fetchAllAppointments(1, name, city, price, selectedSpecialization).then(
      (response) => {
        if (response.data.data.last_page == response.data.data.current_page) {
          setLoadMore(false);
        }
        setDoctorList(response.data.data.data);
      }
    );
  };

  const addSpecialization = (item) => {
    const prevSpec = [...selectedSpecialization];
    const findIndex = prevSpec.findIndex((element) => element === item.id);
    if (findIndex == -1) {
      prevSpec.push(item.id);
    } else {
      prevSpec.splice(findIndex, 1);
    }

    setSelectedSpecialization(prevSpec);
  };

  const onLoadMore = () => {
    let prevDoctorList = [...doctorList];
    fetchAllAppointments(
      page + 1,
      name,
      city,
      price,
      selectedSpecialization
    ).then((response) => {
      if (response.data.data.last_page == response.data.data.current_page) {
        setLoadMore(false);
      }
      prevDoctorList = prevDoctorList.concat(response.data.data.data);
      setDoctorList(prevDoctorList);
    });
    setPage((page) => page + 1);
  };

  const loadAppointments = async (index, page) => {
    const prevDoctorList = [...doctorList];
    setLoading(true);
    await axios
      .post(API_ROUTES.GET_NEXT_APPOINTMENTS, {
        doctor_id: doctorList[index].id,
        page,
      })
      .then((response) => {
        if (response.data.length !== 0) {
          prevDoctorList[index].doctor_appointment_termins = response.data;
          setDoctorList(prevDoctorList);
          setAppointmentPage(page);
        }
        setLoading(false);
      });
  };

  const appointmentNextLoadMore = (index) => {
    loadAppointments(index, appointmentPage + 1);
  };

  const appointmentPreviusLoadMore = (index) => {
    // if (appointmentPage - 1 === 0) {
    //   fetchAllAppointments(1).then((response) => {
    //     if (response.data.data.last_page == response.data.data.current_page) {
    //       setLoadMore(false);
    //     }

    //     setDoctorList(response.data.data.data);
    //   });
    //   setAppointmentPage((page) => page - 1);
    //   return;
    // }
    loadAppointments(index, appointmentPage - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const handleSwipe = (e, index, appointmentPage) => {
    if (e.direction === 4 && appointmentPage !== 0) {
      appointmentPreviusLoadMore(index);
    } else if (e.direction === 2) {
      appointmentNextLoadMore(index);
    }
  };

  useEffect(() => {
    submitHandler();
  }, [selectedSpecialization]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <div className="booking-appointment">
        <div>
          <img
            className="book-your-visit-left-flag"
            src="/assets/images/book-your-visit/red-flag.svg"
          />
          <img
            className="book-your-visit-right-flag"
            src="/assets/images/book-your-visit/right-flag.svg"
          />
        </div>

        <form onSubmit={submitHandler}>
          <div className="container-xxl">
            <div className="row">
              <div className="col-xxl-6 col-md-12 col-12 mx-auto">
                <div className="booking">
                  <h1>{t("book-your-visit")}</h1>
                  <div className="job-tags">
                    {allSpecializationData.allSpecialization?.data &&
                      allSpecializationData.allSpecialization.data.map(
                        (specialization) => (
                          <label
                            onClick={() => addSpecialization(specialization)}
                            className={`cp ${
                              selectedSpecialization.includes(specialization.id)
                                ? "category-active"
                                : "category-inactive"
                            }`}
                            key={specialization.id}
                          >
                            {t(specialization.title)}
                          </label>
                        )
                      )}
                  </div>
                  <div className="booking-search">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("all-names")}
                          aria-label="All Names"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        >
                          <option value="">{t("locations")}</option>
                          {allCity?.data &&
                            allCity.data.map((c) => (
                              <option key={c.id} value={c.name}>
                                {c.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4 col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("price-range")}
                          aria-label="Price Range"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-ocean">
                          <span>{t("look-for-doctors")}</span>{" "}
                          <img src="assets/images/plane.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="doctors-container">
        <div className="container-xxl">
          <div className="row">
            <div className="col-xxl-12">
              {/* <!-- Tabs --> */}
              <div className="doctors-tabs">
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      onClick={() => setTabSwitch(0)}
                      id="book-box"
                      data-bs-toggle="tab"
                      data-bs-target="#bookbox"
                      type="button"
                      role="tab"
                      aria-controls="bookbox"
                      aria-selected="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M4.8125 8.25C5.95159 8.25 6.875 7.32659 6.875 6.1875C6.875 5.04841 5.95159 4.125 4.8125 4.125C3.67341 4.125 2.75 5.04841 2.75 6.1875C2.75 7.32659 3.67341 8.25 4.8125 8.25Z"
                          fill="#000"
                        />
                        <path
                          d="M4.8125 17.875C5.95159 17.875 6.875 16.9516 6.875 15.8125C6.875 14.6734 5.95159 13.75 4.8125 13.75C3.67341 13.75 2.75 14.6734 2.75 15.8125C2.75 16.9516 3.67341 17.875 4.8125 17.875Z"
                          fill="#000"
                        />
                        <path
                          d="M20.625 15.125H11V16.5H20.625V15.125Z"
                          fill="#000"
                        />
                        <path
                          d="M20.625 5.5H11V6.875H20.625V5.5Z"
                          fill="#000"
                        />
                      </svg>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      onClick={() => setTabSwitch(1)}
                      id="book-map"
                      data-bs-toggle="tab"
                      data-bs-target="#bookmap"
                      type="button"
                      role="tab"
                      aria-controls="bookmap"
                      aria-selected="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M22.7597 2.27435L16.2597 0.649347C16.0939 0.608174 15.9193 0.619966 15.7606 0.683066L7.87932 3.83557L1.63469 2.27435C1.51491 2.24438 1.38987 2.24211 1.26907 2.2677C1.14827 2.2933 1.0349 2.34609 0.937564 2.42207C0.840228 2.49805 0.761488 2.59521 0.707328 2.70618C0.653168 2.81714 0.625013 2.93899 0.625 3.06247V20.9375C0.625018 21.1186 0.685582 21.2946 0.797066 21.4374C0.908551 21.5802 1.06456 21.6816 1.24031 21.7256L7.74031 23.3506C7.80483 23.3666 7.87103 23.3748 7.9375 23.375C8.04095 23.375 8.14344 23.3552 8.23943 23.3166L16.1207 20.1641L22.3656 21.7257C22.4853 21.7556 22.6103 21.7578 22.7311 21.7322C22.8519 21.7066 22.9652 21.6538 23.0625 21.5778C23.1599 21.5018 23.2386 21.4047 23.2927 21.2937C23.3468 21.1828 23.375 21.0609 23.375 20.9375V3.06247C23.375 2.88131 23.3144 2.70535 23.2029 2.56255C23.0914 2.41976 22.9354 2.31832 22.7597 2.27435ZM21.75 7.93747H16.875V2.47796L21.75 3.69671V7.93747ZM7.125 14.4375H2.25V9.56247H7.125V14.4375ZM8.75 7.93747V5.23762L15.25 2.63762V7.93747H8.75ZM15.25 9.56247V14.4375H8.75V9.56247H15.25ZM8.75 16.0625H15.25V18.7622L8.75 21.3622V16.0625ZM16.875 9.56247H21.75V14.4375H16.875V9.56247ZM7.125 5.32171V7.93747H2.25V4.10296L7.125 5.32171ZM2.25 16.0625H7.125V21.5218L2.25 20.3031V16.0625ZM16.875 18.6777V16.0625H21.75V19.8968L16.875 18.6777Z"
                          fill="#000"
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              </div>
              {/* <!-- /END Tabs --> */}
            </div>
          </div>

          {/* <!-- Tabs Content --> */}
          {tabSwitch == 0 && (
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="bookbox"
                role="tabpanel"
                aria-labelledby="book-box"
              >
                <div className="row">
                  <div className="col-xxl-12">
                    {doctorList &&
                      doctorList.length > 0 &&
                      doctorList.map((doctor, index) => {
                        // if (
                        //   isObjEmpty(doctor?.doctor_appointment_termins) !==
                        //   true
                        // ) {
                        return (
                          <div
                            key={doctor.id}
                            className="doctor-item doctor-col-2"
                          >
                            <DoctorCard data={doctor} />
                            <Hammer
                              onSwipe={(e) =>
                                handleSwipe(e, index, appointmentPage)
                              }
                            >
                              <div
                                style={{
                                  overflowX: "auto",
                                  flexWrap: "nowrap",
                                }}
                                className="doctor-book"
                              >
                                {doctor?.doctor_appointment_termins?.length !=
                                  0 && (
                                  <nav className="slide-calendar">
                                    <div
                                      style={{
                                        visibility:
                                          appointmentPage === 0 ? "hidden" : "",
                                      }}
                                      onClick={() =>
                                        appointmentPreviusLoadMore(index)
                                      }
                                      className={
                                        appointmentPage === 1
                                          ? "disabled arrows slide-left"
                                          : " arrows slide-left"
                                      }
                                    >
                                      <img
                                        src="assets/images/arrow-left-right.svg"
                                        alt="left-right"
                                      />
                                    </div>
                                    <div
                                      onClick={() =>
                                        appointmentNextLoadMore(index)
                                      }
                                      className="arrows slide-right"
                                    >
                                      <img
                                        src="assets/images/arrow-left-right.svg"
                                        alt="left-right"
                                      />
                                    </div>
                                  </nav>
                                )}

                                {Object.keys(
                                  doctor?.doctor_appointment_termins
                                ).map((termin, index) => {
                                  return (
                                    <DoctorAppointemnt
                                      day={termin}
                                      index={index}
                                      key={index}
                                      noArrow
                                      termins={Object.values(
                                        doctor?.doctor_appointment_termins
                                      )}
                                    />
                                  );
                                })}
                              </div>
                            </Hammer>
                          </div>
                        );
                        // } else {
                        //   return null;
                        // }
                      })}
                    {/* <!-- / END Doctor Box Item --> */}
                  </div>

                  {loadMore && (
                    <div className="col-xxl-12 load-more">
                      <button onClick={onLoadMore} className="btn btn-ocean">
                        {t("load-more")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {doctorList && tabSwitch === 1 && (
        <CustomMap height={824} data={doctorList} />
      )}
    </>
  );
};

export default ShowAllAvailableApointmentScreen;
