import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className='legal_page'>
        <div className='legal_wrapper'>
          <div className='container-xxl'>
            <h2>{t('privacy')}</h2>
            <div className='content_wrapper'>
              <p className='normal'>{t('privacy-message')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
