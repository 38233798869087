import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import jwt from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getApointmentById } from '../../actions/apointmentActions';
import LoadingIndicator from '../../components/LoadingIndicator';
import Message from '../../components/Message';
import moment from 'moment';


const BookYourApointmentLoggedScreen = () => {
  let { id } = useParams();

  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let history = useNavigate();
  // useEffect(() => {
  //   if (!userInfo) {
  //     history(`/book-your-visit/book-your-visit/${id}`);
  //   }
  // }, [history, userInfo, id]);

  const dispatch = useDispatch();

  const doctorData = useSelector((state) => state.doctor);
  const { loading, error, doctor } = doctorData;

  useEffect(() => {
    dispatch(getApointmentById(id));
  }, [dispatch, id]);

  let doctorPicture = '/assets/images/doctors/doctor.jpg';

  if (doctor?.data?.doctor_user_appointment?.profile_picture !== null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${doctor?.data?.doctor_user_appointment?.profile_picture}`;
  }

  let token;
  if (userInfo) {
    token = jwt(userInfo?.data);
  }

  const updateBookVisit = () => {
    if (userInfo) {
      history(`/update-book-visit/${id}`);
      return;
    }

    history('/register-patient', {state : { from: `/book-your-visit/book-your-visit-patient/${id}` }});
  };

  const [elDisable, setElDisable] = useState(true);

  useEffect(() => {
    if (!token?.tokenParams) {
      setElDisable(false);
      return;
    }
    if (token?.tokenParams?.usertype !== 'patient') {
      setElDisable(true);
      return;
    }
    setElDisable(false);
  }, [token]);

  return (
    <>
      <div className='doctor_book_aapointment_modal'>
        {loading && <LoadingIndicator />}
        {error && <Message variant='danger'>{error}</Message>}
        <div className='booking-appointment'>
          <div className='booking booking-small'>
            <h1>{t('book-your-visit')}</h1>
          </div>
        </div>

        <div className='doctors-container'>
          <div className='container-xxl'>
            {/* <!-- Tabs Content --> */}
            <div className='row'>
              <div className='col-xxl-12'>
                {/* <!-- Doctor Box Item -->
                <!-- className:: doctor-col-2 :: Use when u have 2 columns for doctors box --> */}
                <div className='doctor-item doctor-col-2'>
                  <div className='doctor'>
                    <div className='profile-image'>
                      <div
                        style={{
                          background: `url(${doctorPicture})`,
                        }}
                        className='profile-image-wrapper'
                      >
                        {doctor?.data?.doctor_user_appointment
                          ?.profile_picture ? (
                          <img
                            src={`/api/show-file/${doctor?.data?.doctor_user_appointment?.profile_picture}`}
                            alt='Doctor Name'
                          />
                        ) : (
                          <img
                            src='/assets/images/doctors/doctor.jpg'
                            alt='Doctor Name'
                          />
                        )}
                      </div>
                    </div>
                    <div className='doctor-info'>
                      <div className='doctor-name'>
                        Dr. {doctor?.data?.doctor_user_appointment?.name}
                      </div>
                      <div className='doctor-job'>
                        <div className='job-tags'>
                          {doctor?.data?.doctor_user_appointment?.doctor_profile
                            ?.primary_specialization?.title && (
                            <span className='btn-nature'>
                              {
                                doctor?.data?.doctor_user_appointment
                                  ?.doctor_profile?.primary_specialization
                                  ?.title
                              }
                            </span>
                          )}
                          {doctor?.data?.doctor_user_appointment?.doctor_profile
                            ?.secondary_specialization?.title && (
                            <span
                              className='btn-nature'
                              style={{ margin: '0.5rem' }}
                            >
                              {
                                doctor?.data?.doctor_user_appointment
                                  ?.doctor_profile?.secondary_specialization
                                  ?.title
                              }
                            </span>
                          )}
                        </div>
                      </div>
                      <p className='desc light hidden-mobile'>
                        {
                          doctor?.data?.doctor_user_appointment
                            .this_page_required_info
                        }
                      </p>

                      <div className='doctor-location hidden-mobile'>
                        <p>
                          {`${doctor?.data?.doctor_user_appointment?.doctor_profile?.street} ${doctor?.data?.doctor_user_appointment?.doctor_profile?.city} ${doctor?.data?.doctor_user_appointment?.doctor_profile?.country}`}
                        </p>
                      </div>

                      <div className='doctor-price hidden-mobile'>
                        <select>
                          <option>{t('show-prices')}</option> 
                          {doctor?.data?.doctor_user_appointment?.doctor_profile
                            ?.doctor_specialization_prices &&
                            doctor?.data?.doctor_user_appointment?.doctor_profile?.doctor_specialization_prices.map(
                              (price) => (
                                <option
                                  disabled
                                  key={price?.id}
                                  className='category'
                                >
                                  {price?.price} € {price?.title}
                                </option>
                              )
                            )}
                        </select>
                      </div>

                      {doctor?.data?.doctor_user_appointment.doctor_profile?.third_party_payment === 'YES' && (
                        <div className='third_party_info hidden-mobile'>
                          <span>{t('accept-third-paty-payment')}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='doctor doctor-mobile'>
                    <div className='doctor-info'>
                      <div className='doctor-location '>
                        <p>
                          {`${doctor?.data?.doctor_user_appointment?.doctor_profile?.street} ${doctor?.data?.doctor_user_appointment?.doctor_profile?.city} ${doctor?.data?.doctor_user_appointment?.doctor_profile?.country}`}
                        </p>
                      </div>
                      <p className='desc light'>
                        {
                          doctor?.data?.doctor_user_appointment
                            .this_page_required_info
                        }
                      </p>
                      <div className='doctor-price '>
                        <select>
                          <option>{t('show-prices')}</option>
                          {doctor?.data?.doctor_user_appointment?.doctor_profile
                            ?.doctor_specialization_prices &&
                            doctor?.data?.doctor_user_appointment?.doctor_profile?.doctor_specialization_prices.map(
                              (price) => (
                                <option
                                  disabled
                                  key={price.id}
                                  className='category'
                                >
                                  {price.price} € {price.title}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                      {doctor?.data?.doctor_user_appointment.doctor_profile?.third_party_payment === 'YES' && (
                        <div className='third_party_info'>
                          <span>{t('accept-third-paty-payment')}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='doctor-book-step'>
                    <div className='doctor-book-title'>
                      <h3>{doctor?.data?.doctor_specialization_price?.title}</h3>
                    </div>
                    <div className='doctor-reservation-date'>
                      <div className='doctor-reservation-icon'>
                        <img src='/assets/images/clock.svg' alt='' />
                      </div>
                      <div className='doctor-reservation-time-date'>
                        <p>
                          {moment(doctor?.data?.start_date).format("ddd, MMM DD YYYY, H:m")} - {moment(doctor?.data?.end_date).format("H:m")}
                        </p>
                        <Link to='/book-your-visit' className='change-date'>
                          {t('change-dates')}
                        </Link>
                      </div>
                    </div>
                    <ul style={{ opacity: !userInfo ? 1 : 0 }}>
                      <li>{t('book-appointment-not-loged-message-one')}</li>
                      <li>{t('book-appointment-not-loged-message-two')}</li>
                      <li>{t('book-appointment-not-loged-message-three')} </li>
                      <li>{t('book-appointment-not-loged-message-forth')} </li>
                    </ul>
                    <button
                      className='btn btn-ocean'
                      disabled={elDisable}
                      onClick={updateBookVisit}
                    >
                      {' '}
                      {t(userInfo ? 'book-visit' : 'sign-in-to-book')}
                    </button>
                  </div>
                </div>
                {/* <!-- / END Doctor Box Item --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookYourApointmentLoggedScreen;
