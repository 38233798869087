import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  // adminLoginDoctorAction,
  getAdminAllPaientsActions,
} from '../../actions/adminActions';
import LoadingIndicator from '../../components/LoadingIndicator';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

const AllDoctorsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector(state => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
      return;
    }
    const token = jwtDecode(userInfo.data);
    if (!['superadmin', 'clinic_admin'].includes(token.tokenParams.usertype)) {
      navigate('/unauthorized');
      return;
    }
  }, [dispatch, navigate, userInfo]);

  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  // Get list of all doctors
  const adminAllDoctorsData = useSelector((state) => state.adminAllDoctors);
  const { loading, error, adminAllDoctors } = adminAllDoctorsData;

  useEffect(() => {
    dispatch(getAdminAllPaientsActions());
  }, [dispatch]);

  // console.log('adminAllDoctors', adminAllDoctors);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Pagination
  useEffect(() => {
    if (adminAllDoctors?.data) {
      setFirstPage('1');
      setPreviousPage(adminAllDoctors?.data?.prev_page_url);
      setLastPage(adminAllDoctors?.data?.last_page);
      setNextPage(adminAllDoctors?.data?.next_page_url);
      setCurrentPage(adminAllDoctors?.data?.current_page);
    }
  }, [adminAllDoctors]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    dispatch(getAdminAllPaientsActions(page));
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    dispatch(getAdminAllPaientsActions(page));
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    dispatch(getAdminAllPaientsActions(page));
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    dispatch(getAdminAllPaientsActions(page));
  };


  const { t, i18n } = useTranslation();

  return (
    <div className="dashboard-container admin_pages_wrapper">
      {loading && <LoadingIndicator />}
      <div className="container-xxl">
        <h1 className="row row-flex justify-content-center">All patients</h1>
        {userInfo && ['clinic_admin'].includes(userInfo.usertype) && (
          <div style={{ position: 'relative' }}>
            <Link to="/add-new-patient">
              <button className="btn btn-flowers" style={{ position: 'absolute', right: '0', top: '-50px' }}>
                + Add patient
              </button>
            </Link>
          </div>
        )}
        <div class="Rtable Rtable--9cols">
          <div class="Rtable-cell">
            <h3>{t('first-name')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('last-name')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('date-of-registration')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('profile-status')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('subscription-status')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('clinic-freelence')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('login-doctor')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('earnings')}</h3>
          </div>
          <div class="Rtable-cell">
            <h3>{t('view-profile')}</h3>
          </div>
          {adminAllDoctors?.data?.data &&
            adminAllDoctors?.data?.data.map((doctor) => (
              <>
                <div class="Rtable-cell">{doctor?.name}</div>
                <div class="Rtable-cell">{doctor?.surname}</div>

                <div class="Rtable-cell">
                  {/* {doctor?.created_at} */}
                  {moment(new Date(doctor?.created_at)).format(
                    'DD-MM-YYYY hh:mm A'
                  )}
                </div>

                {/* Profile Status 0=inactive 1=active 2=on_hold */}
                <div class="Rtable-cell">
                  <strong>
                    <h3>
                      {doctor?.status === 0
                        ? 'In active'
                        : doctor?.status === 1
                          ? 'Active'
                          : 'On hold'}{' '}
                    </h3>
                  </strong>
                  <Link
                    to="/change-profile-status"
                    state={{
                      doctorId: doctor?.id,
                      status: doctor?.status,
                      usertype: doctor?.usertype,
                    }}
                  >
                    {t('change-status')}
                  </Link>
                </div>
                <div class="Rtable-cell">
                  {doctor?.stripe_subscription_id ? 'ACTIVE' : 'INACTIVE'}
                </div>
                <div class="Rtable-cell">
                  {doctor?.usertype} <br />
                  <Link
                    to="/change-doctor-usertype"
                    state={{
                      doctorId: doctor?.id,
                      status: doctor?.status,
                      usertype: doctor?.usertype,
                    }}
                  >
                    {t('change-status')}
                  </Link>
                </div>

                <div class="Rtable-cell">
                  <button
                    onClick={async () => {
                      console.log('copy pasta')
                    }}
                  >
                    {t('login-doctor')}
                  </button>
                </div>

                <div class="Rtable-cell">
                  <Link
                    to={`/see-doctor-earnings`}
                    state={{ doctorId: doctor?.id }}
                  >
                    {t('earnings')}
                  </Link>
                </div>
                <div class="Rtable-cell">
                  <Link to={`/see-doctor-profile`} state={{ doctor }}>
                    {t('view-profile')}
                  </Link>
                </div>
              </>
            ))}
        </div>

        {/* Pagination */}
        <Pagination className="justify-content-center mb-4 my-5">
          <Pagination.First onClick={paginationFirstHandler} />
          <Pagination.Prev onClick={paginationPrevHandler} />
          {/* <Pagination.Ellipsis /> */}
          <Pagination.Item disabled>{currentPage}</Pagination.Item>
          <Pagination.Next onClick={paginationNextHandler} />
          <Pagination.Last onClick={paginationLastHandler} />
        </Pagination>
      </div>
    </div>
  );
};

export default AllDoctorsScreen;
