import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  doctorProposeOnlineActions,
  doctorProposePhysicalActions,
} from '../../actions/doctorActions';
import {
    patientAcceptApointmentActions,
    patientRefuseApointmentActions,
  } from '../../actions/patientActions';

import io from 'socket.io-client';
import { Link } from 'react-router-dom';

import {
  API_ROUTES,
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  SOCKET_ROUTES,
  socketToken,
  socketURL,
  STATUS,
} from '../../helpers/constants';
import axios from 'axios';
import { getItemFromLocalStorage } from '../../helpers/helpers';
import LoadingIndicator from '../LoadingIndicator';

const MiniEventsItemActions = ({event, onEventAction, status , isDoctor}) => {

    const { t, i18n } = useTranslation();

    const [pay, setPay] = useState(false);
    const [popupLocationIsOpen, setPopupLocationIsOpen] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [changeLocation, setChangeLocation] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const socket = io(socketURL, {
        transports: [ "websocket" ],
        query: { token: socketToken },
      });

    useEffect(() => {
        if (!socket.connected)
            socket.connect();
        return () => {
            if (socket.connected)
            socket.disconnect();
        }
    }, []);

    const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
    };

    
    useEffect(() => {
        setCancel(getCancel());
        setChangeLocation(getChangeLocation());
        setPay(getPay());
    }, [event, isDoctor, status]);


    /* CANCEL */
    
    const getCancel = () => {
        if (event.type === "termin" || event.type === "group-termin"){
            return (
                <div className='btn btn-darkblue btn-cancel' onClick={cancelTerminAPI}>
                    {t('cancel')}
                </div>
            )
        }
        else if (event.type === "appointment" && event.event.payment_status === 0){
            return (
                <div className='btn btn-darkblue btn-cancel' onClick={() => cancelAppointmentAPI(event.event.id)}>
                    {t('cancel')}
                </div>
            )
        }
    };

    const cancelTerminAPI = async () => {
        setIsLoading(true);
        await axios
        .delete(`${API_ROUTES.DOCTOR.DELETE_TERMIN}/${event.event.id}`, config)
        .then((response) => {
            onEventAction();
            setIsLoading(false);
        })
        .catch((error) => {
            console.log('SOME ERROR APPEAR HERE');
        });
    }

    const cancelAppointmentAPI = async (id) => {

        let customObject = {
          cancel_reason: "",
          id: id,
        };
    
        await axios
          .post(
            API_ROUTES.DOCTOR.CANCEL_APPOINTEMNT,
            {
              id: id,
              cancel_message: "",
            },
            config
          )
          .then(() => {
    
            socket.emit(
              SOCKET_ROUTES.EVENTS,
              event.event?.patient_id,
              STATUS.CANCEL_APPOINTEMNT,
              customObject
            );
            socket.emit(SOCKET_ROUTES.NOTIFICATIONS, event.event?.patient_id, {
              message: NOTIFICATIONS_TEXT.DOCTOR.CANCEL_APPOINTEMNT,
              type: NOTIFICATIONS_TYPES.ERROR,
            });
            onEventAction();
            setIsLoading(false);
          });
    }

    /* CHANGE LOCATION */

    useEffect(() => {
        setChangeLocation(getChangeLocation());
    }, [popupLocationIsOpen]);

    const getChangeLocation = () => {
        if (event.type === "appointment" &&
            status === "pending" &&
            isDoctor) {
            return (
                <div className='choose-location'>
                    <div className='btn btn-ocean propose-appointment' onClick={() => setPopupLocationIsOpen(!popupLocationIsOpen)}>
                        {t('yes')}
                        <img src='/assets/images/chevron-down.svg' alt='chevron-down' />
                    </div>
                    {popupLocationIsOpen &&
                        <div className='popup-choose-location'>
                            <div className='popup-location' onClick={proposePhysical}>
                                <img src='/assets/images/map-grey.svg' alt='map' />
                                {t('propose-studio')}
                            </div>
                            <div className='popup-location' onClick={proposeOnline}>
                                <img src='/assets/images/camera.svg' alt='camera' />
                                {t('propose-online')}
                            </div>
                        </div>
                    }
                </div>
            )
        }
        else if ( event.type === "appointment" &&
                  !isDoctor) {
            if (status === "propose_online" || status === "propose_studio") {
                return (
                    <>
                        <div className='btn btn-ocean' onClick={confirmLocation}>
                            {t('confirm-location')}
                        </div>
                        <div className='btn btn-sunset' onClick={refuseLocation}>
                            {t('refuse-location')}
                        </div>
                    </>

                )
            }
        }
    }

  const proposeOnline = () => {
    setIsLoading(true);

    socket.emit(
      SOCKET_ROUTES.EVENTS,
      event.event.patient_id,
      STATUS.PROPOSE_ONLINE,
      event.event.id
    );

    socket.emit(SOCKET_ROUTES.NOTIFICATIONS, event.event.patient_id, {
      message: NOTIFICATIONS_TEXT.DOCTOR.PROPOSE_ONLINE,
      type: NOTIFICATIONS_TYPES.SUCCESS,
    });

    dispatch(doctorProposeOnlineActions(event.event.id)).then(() => {
        setIsLoading(false);
        onEventAction();
    });
  };

  const proposePhysical = () => {
    setIsLoading(true);
    socket.emit(
      SOCKET_ROUTES.EVENTS,
      event.event.patient_id,
      STATUS.PROPOSE_STUDIO,
      event.event.id
    );

    dispatch(doctorProposePhysicalActions(event.event.id)).then(() => {
        setIsLoading(false);
        onEventAction();
    });
  };

  const confirmLocation = () => {
    setIsLoading(true);
    socket.emit(
      SOCKET_ROUTES.EVENTS,
      event.event.doctor_id,
      STATUS.ACCEPT_ONLINE,
      event.event.id
    );
    socket.emit(SOCKET_ROUTES.NOTIFICATIONS, event.event.doctor_id, {
      message: NOTIFICATIONS_TEXT.PATIENT.PROPOSE_ONLINE,
      type: NOTIFICATIONS_TYPES.ALERT,
    });

    dispatch(patientAcceptApointmentActions(event.event.id)).then(() => {
        setIsLoading(false);
        onEventAction();
    });

  };

    const refuseLocation = () => {
        setIsLoading(true);
        dispatch(patientRefuseApointmentActions(event.event.id)).then(() => {
            setIsLoading(false);
            onEventAction();
        });

        socket.emit(
        SOCKET_ROUTES.EVENTS,
        event.event.doctor_id,
        STATUS.PENDING,
        event.event.id
        );
        socket.emit(SOCKET_ROUTES.NOTIFICATIONS, event.event.doctor_id, {
        message: NOTIFICATIONS_TEXT.PATIENT.CANCEL_APPOINTEMNT,
        type: NOTIFICATIONS_TYPES.ALERT,
        });
    };

    /* PAY */

    const getPay = () => {
        if (event.type === "appointment" &&
            event.event.payment_status === 0 &&
            event.event.confirmed_price === 1 &&
            !isDoctor &&
            status === "accept_online") {
                
                if (parseInt(event.event.payment_price) === 0) {
                    return (
                        <div className='btn btn-pay' onClick={() => makeFreePayment(event.event.id)} >
                            {t('confirm')}
                        </div>
                    )
                } 
                else { 
                    return (
                        <div className='btn btn-pay'>
                            <Link
                                to={`/payment/${event.event.id}/${event.event.payment_price}/${event.event.doctor_id}`}
                            >
                                {t('pay-termin')}
                            </Link>
                        </div>
                    )
            }
        }
        else if (event.type === "group-appointment" &&
                 event.events?.[0]?.payment_status === 0 &&
                 event.events?.[0]?.confirmed_price === 1 &&
                 !isDoctor &&
                 status === "accept_online") {

                if (parseInt(event.events?.[0]?.payment_price) === 0) {
                    return (
                        <div className='btn btn-pay' onClick={() => makeFreePayment(event.events?.[0]?.id)} >
                            {t('pay-termin')}
                        </div>
                    )
                } 
                else { 
                    return (
                        <div className='btn btn-pay'>
                            <Link
                                to={`/payment/${event.events?.[0]?.id}/${event.events?.[0]?.payment_price}/${event.events?.[0]?.doctor_id}`}
                            >
                                {t('pay-termin')}
                            </Link>
                        </div>
                    )
                } 
            }
    }

    const makeFreePayment = async (appointment_id) => {
        setIsLoading(true)
        await axios
          .post(
            API_ROUTES.PATIENT.MAKE_FREE_PAYMENT,
            {
              appointment_id: appointment_id,
            },
            {
              headers: {
              'Content-type': 'application/json',
              'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
              Authorization: `Bearer ${userInfo?.data}`,
              }
            },
          )
          .then((response) => {
            onEventAction();
            setIsLoading(false);
          });
    
    }


    return (
        <div className='mini-events-item-action'>
        {isLoading && <LoadingIndicator />}
        {changeLocation}
        {pay}
        {cancel}
        </div>
    );
};

export default MiniEventsItemActions;
