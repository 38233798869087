import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { API_ROUTES, lastSevenDays, newDate } from '../../helpers/constants';
import { getItemFromLocalStorage } from '../../helpers/helpers';
import CurveChart from '../Charts/CurveChart';
import HealthOverviewHeader from '../DoctorUsers/HealthOverViewHeader';
import PeriodCalendar from '../HealthOverview/PeriodCalendar';
import DoctorScaleOfPain from './DoctorScaleOfPain';

const HealthOverViewContent = ({ data }) => {
  const labels = lastSevenDays(newDate, 'MMMM Do');

  const defaultChartValues = [0, 0, 0, 0, 0, 0, 0];

  const chartValueLength = 6;
  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const [healthOverViewHistory, setHealthOverviewHistory] = useState({
    blood_pressure: defaultChartValues,
    blood_sugar: defaultChartValues,
    bmi: defaultChartValues,
    glass_per_day: defaultChartValues,
    height: defaultChartValues,
    scale_of_pain: defaultChartValues,
    steps: defaultChartValues,
    the_arterial_pressure: defaultChartValues,
    weight: defaultChartValues,
  });

  const [scaleOfPain, setScaleOfPain] = useState([]);

  const onSelectDate = async (date) => {
    await axios
      .post(
        API_ROUTES.PATIENT.GET_HEALTH_OVERVIEW,
        {
          health_overview_date: date,
          patient_id: id,
        },
        config
      )
      .then((response) => {
        setScaleOfPain(response?.data?.data.health_overview_for_date);
        let historyResponse = formatChart(
          response?.data?.data?.health_overview_history,
          response?.data?.data?.health_overview_for_date
        );
        setHealthOverviewHistory(historyResponse);
      })
      .catch((error) => {
        console.log('ERROR JE', error);
      });
  };

  const formatChart = (health_overview_history, health_overview_for_date) => {
    let historyResponse = health_overview_history;

    if (health_overview_for_date?.length > 0) {
      const destructed = health_overview_for_date[0];

      historyResponse.blood_pressure.push(destructed.blood_pressure);
      historyResponse.blood_sugar.push(destructed.blood_sugar);
      historyResponse.height.push(destructed.height);
      historyResponse.weight.push(destructed.weight);
      historyResponse.bmi.push(destructed.bmi);
      historyResponse.steps.push(destructed.steps);
      historyResponse.the_arterial_pressure.push(
        destructed.the_arterial_pressure
      );
      historyResponse.glass_per_day.push(destructed.glass_per_day);
      historyResponse.scale_of_pain.push(destructed.scale_of_pain);
    } else {
      historyResponse.blood_pressure.push(0);
      historyResponse.blood_sugar.push(0);
      historyResponse.height.push(0);
      historyResponse.weight.push(0);
      historyResponse.bmi.push(0);
      historyResponse.steps.push(0);
      historyResponse.the_arterial_pressure.push(0);
      historyResponse.glass_per_day.push(0);
      historyResponse.scale_of_pain.push(0);
    }
    return historyResponse;
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let historyResponse = formatChart(
        data?.health_overview_history,
        data?.health_overview_date
      );
      setScaleOfPain(data.health_overview_for_date);
      setHealthOverviewHistory(historyResponse);
    }
  }, [data]);

  return (
    <>
      <HealthOverviewHeader data={data.patient} />

      <section className='container-xxl health_overview_page'>
        <div className='row'>
          <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
            <div className='health-overview-card'>
              <h3>{t('nbm')}</h3>
              <CurveChart
                borderColor={'#4066B7'}
                values={healthOverViewHistory?.blood_sugar}
                labels={labels}
              />
              <div className='result'>
                <input
                  type='number'
                  disabled={true}
                  className='b-none'
                  value={healthOverViewHistory?.blood_sugar[chartValueLength]}
                />
              </div>
            </div>
          </div>

          <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
            <div className='health-overview-card'>
              <h3>{t('weight')}</h3>
              <CurveChart
                borderColor={'#F5C84F'}
                values={healthOverViewHistory?.weight}
                labels={labels}
              />
              <div className='result'>
                <input
                  type='number'
                  disabled={true}
                  className='b-none'
                  value={healthOverViewHistory?.weight[chartValueLength]}
                />
              </div>
            </div>
          </div>

          <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
            <div className='health-overview-card'>
              <h3>{t('oxygen-saturation')}</h3>
              <CurveChart
                borderColor={'#79D2CC'}
                values={healthOverViewHistory?.bmi}
                labels={labels}
              />
              <div className='result'>
                <input
                  type='number'
                  disabled={true}
                  className='b-none'
                  value={healthOverViewHistory?.bmi[chartValueLength]}
                />
              </div>
            </div>
          </div>

          <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
            <div className='health-overview-card'>
              <h3>{t('respiratory-frequency')}</h3>
              <CurveChart
                borderColor={'#EC5C78'}
                values={healthOverViewHistory?.steps}
                labels={labels}
              />
              <div className='result'>
                <input
                  type='number'
                  disabled={true}
                  className='b-none'
                  value={healthOverViewHistory?.steps[chartValueLength]}
                />
              </div>
            </div>
          </div>

          <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
            <div className='health-overview-card'>
              <h3>{t('temperature')}</h3>
              <CurveChart
                borderColor={'#EC5C78'}
                values={healthOverViewHistory?.scale_of_pain}
                labels={labels}
              />
              <div className='result'>
                <input
                  type='number'
                  disabled={true}
                  className='b-none'
                  value={healthOverViewHistory?.scale_of_pain[chartValueLength]}
                />
              </div>
            </div>
          </div>
          <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
            <div className='health-overview-card'>
              <h3>{t('liter-water-per-day')}</h3>

              <h3 style={{ marginTop: '40px' }}>
                {healthOverViewHistory?.height[chartValueLength]}
              </h3>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xxl-6'>
            <div className='row'>
              <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-6'>
                <div className='health-overview-card'>
                  <h3>{t('cigarettes-per-day')}</h3>
                  <h3 style={{ marginTop: '40px' }}>
                    {
                      healthOverViewHistory?.the_arterial_pressure[
                        chartValueLength
                      ]
                    }
                  </h3>
                </div>
              </div>
              <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-6'>
                <div className='health-overview-card'>
                  <h3>{t('smoker-for-how-many-years')}</h3>
                  <h3 style={{ marginTop: '40px' }}>
                    {healthOverViewHistory?.glass_per_day[chartValueLength]}
                  </h3>
                </div>
              </div>
              <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-6'>
                <div className='health-overview-card'>
                  <h3>{t('toxic-consumption')}</h3>
                  <h3 style={{ marginTop: '40px' }}>
                    {healthOverViewHistory?.blood_pressure[chartValueLength]}
                  </h3>
                </div>
              </div>
              <div
                style={{ position: 'relative' }}
                className='col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12'
              >
                <div
                  className={
                    data?.patient?.gender === null ||
                    data?.patient?.gender === 'male'
                      ? 'disable_period'
                      : ''
                  }
                >
                  {/* <PeriodCalendar /> */}
                </div>
                {(data?.patient?.gender === null ||
                  data?.patient?.gender === 'male') && (
                  <button className='not_applicable'>
                    {t('not-applicable')}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='col-xxl-6'>
            <DoctorScaleOfPain
              onSelectDate={onSelectDate}
              scaleOfPainData={scaleOfPain}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HealthOverViewContent;
