import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import io from 'socket.io-client';
import {
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  socketToken,
  socketURL,
  SOCKET_ROUTES,
} from '../../helpers/constants';
import { useEffect } from 'react';

const socket = io(socketURL, {
  transports: [ "websocket" ],
  query: { token: socketToken },
});

const DocumentUpload = ({ onFilesUpload, accept = '*' }) => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (!socket.connected)
      socket.connect();
    return () => {
      if (socket.connected)
      socket.disconnect();
    }
  }
  , []);
  const onFileInputUpload = (event) => {
    const files = event.target.files;
    onFilesUpload(files);

    socket.emit(SOCKET_ROUTES.NOTIFICATIONS, id, {
      message: NOTIFICATIONS_TEXT.DOCTOR.DOCUMENT,
      type: NOTIFICATIONS_TYPES.DOCUMENT,
    });
  };

  return (
    <div className='container-xxl cp'>
      <div className='row'>
        <label htmlFor='file-input_upload'>
          <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 mb-1'>
            <div className='upload-document'>
              <div className='notification-icon'>
                <img src='/assets/images/document-upload.svg' alt='' />
              </div>
              <p>{t('upload-patient-doc')}</p>
            </div>
          </div>
        </label>
      </div>

      <input
        id='file-input_upload'
        onChange={onFileInputUpload}
        type='file'
        name='file-input_upload'
        hidden
        multiple
        accept={accept}
      />
    </div>
  );
};

export default DocumentUpload;
