import React, { useState, useEffect } from 'react'
import jwt from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  doctorGetProfileActions,
  doctorUpdatedProfileActions,
  getAllSpecialization,
} from '../../actions/doctorActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import loadImage from 'blueimp-load-image'

const UpdateDoctorProfileScreen = () => {
  const { t, i18n } = useTranslation()
  // Declare variable
  const [checked, setChecked] = useState(false)
  const [email, setEmail] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [id_number, setId_number] = useState('')
  const [sanitary_card, setSanitary_card] = useState('')
  const [gender, setGender] = useState('')
  let [birthday, setBirthday] = useState('')
  const [healt_insurance, setHealt_insurance] = useState('')
  const [this_page_required_info, setThis_page_required_info] = useState('')
  let [file_name, setFile_name] = useState('')
  const [profile_picture, setProfile_picture] = useState(null)

  const [nationality, setNationality] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')
  const [house_number, setHouse_number] = useState('')
  const [safety_measures, setSafety_measures] = useState('')
  const [services, setServices] = useState('')
  const [formation_and_specialization, setFormation_and_specialization] =
    useState('')
  const [disease_treated, setDisease_treated] = useState('')
  const [languages, setLanguages] = useState('')

  const [message, setMessage] = useState(null)
  const [messageSuccess, setMessageSuccess] = useState(null)
  const [third_party_payment, setAcceptThirdParty] = useState('')

  const [imageMessage, setImageMessage] = useState(null)
  const [elDisable, setElDisable] = useState(false)

  const [primary_specialization_id, setPrimarySpecialization] = useState('')
  const [secondary_specialization_id, setSecondarySpeciazlization] =
    useState('')

  const convertTo64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (err) => {
        reject(err)
      }
    })
  }

  //   Select redux info
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const doctorUpdateProfile = useSelector((state) => state.doctorUpdateProfile)
  const { loading, error, doctorInfo } = doctorUpdateProfile

  let token
  if (userInfo) {
    token = jwt(userInfo.data)
  }

  let history = useNavigate()
  useEffect(() => {
    if (!token) {
      history('/login')
    }
  }, [history, token])

  const doctorProfileData = useSelector((state) => state.doctorProfile)
  const { doctorProfile } = doctorProfileData

  useEffect(() => {
    dispatch(doctorGetProfileActions())
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (doctorProfile?.data) {
      let third =
        doctorProfile.data.doctor_profile?.third_party_payment === 'NO'
          ? false
          : true
      setChecked(third)
      setEmail(doctorProfile.data.email)
      setPhone_number(doctorProfile.data.phone_number)
      setName(doctorProfile.data.name)
      setSurname(doctorProfile.data.surname)
      setId_number(doctorProfile.data.id_number)
      setSanitary_card(doctorProfile.data.sanitary_card)
      setBirthday(doctorProfile.data.birthday)
      setHealt_insurance(doctorProfile.data.healt_insurance)
      setThis_page_required_info(doctorProfile.data.this_page_required_info)
      setNationality(doctorProfile.data.doctor_profile?.nationality)
      setCountry(doctorProfile.data.doctor_profile?.country)
      setCity(doctorProfile.data.doctor_profile?.city)
      setStreet(doctorProfile.data.doctor_profile?.street)
      setHouse_number(doctorProfile.data.doctor_profile?.house_number)
      setSafety_measures(doctorProfile.data.doctor_profile?.safety_measures)
      setServices(doctorProfile.data.doctor_profile?.services)
      setFormation_and_specialization(
        doctorProfile.data.doctor_profile?.formation_and_specialization
      )
      setDisease_treated(doctorProfile.data.doctor_profile?.disease_treated)
      setLanguages(doctorProfile.data.doctor_profile?.languages)
      setPrimarySpecialization(
        doctorProfile?.data?.doctor_profile?.primary_specialization?.id
      )
      setSecondarySpeciazlization(
        doctorProfile?.data?.doctor_profile?.secondary_specialization?.id
      )
      setGender(doctorProfile?.data?.gender)
    }
    setAcceptThirdParty(
      doctorProfile?.data?.doctor_profile?.third_party_payment
    )
  }, [doctorProfile])

  const id = token?.tokenParams?.id

  useEffect(() => {
    if (doctorInfo?.status_code === 200) {
      setMessageSuccess(t('profile-updated'))
      // dispatch(doctorGetProfileActions());
      document.location.reload()
    }
  }, [doctorInfo, dispatch, t])

  const onChangeThirdParty = () => {
    setChecked(!checked)
  }

  const allSpecializationData = useSelector((state) => state.allSpecialization)
  const { allSpecialization } = allSpecializationData

  useEffect(() => {
    dispatch(getAllSpecialization())
  }, [dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    if (
      secondary_specialization_id &&
      primary_specialization_id === secondary_specialization_id
    ) {
      setMessage(t('not-be-same'))
    } else if (password !== confirmPassword) {
      setMessage(t('password-not-match'))
    } else {
      setMessage(null)
      if (birthday) {
        let birthday_arr = birthday.split('.').reverse()
        birthday = birthday_arr.join('.')
      }

      let imageName
      imageName = file_name.split('\\')
      imageName = imageName[imageName.length - 1].split('.')
      imageName = imageName[0]
      file_name = imageName

      dispatch(
        doctorUpdatedProfileActions(
          id,
          email,
          phone_number,
          name,
          surname,
          password,
          id_number,
          sanitary_card,
          gender,
          birthday,
          healt_insurance,
          this_page_required_info,
          file_name,
          profile_picture,
          nationality,
          country,
          city,
          street,
          house_number,
          safety_measures,
          services,
          formation_and_specialization,
          disease_treated,
          languages,
          third_party_payment,
          primary_specialization_id,
          secondary_specialization_id,
          checked === true ? 'YES' : 'NO'
        )
      )
    }
  }

  let doctorPicture

  doctorPicture = doctorProfile?.data?.profile_picture
    ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${doctorProfile?.data?.profile_picture}`
    : '/assets/images/doctors/doctor.jpg'

  // Disable
  useEffect(() => {
    if (error || imageMessage) {
      setElDisable(true)
    }
    if (!error && !imageMessage) {
      setElDisable(false)
    }
  }, [error, imageMessage])

  return (
    <>
      <div className="doctor_profile">
        <div className="container-xxl">
          <div className="container-fluid user-back">
            <div className="row">
              <div className="col">
                <Link to='/dashboard' className="back-to">
                  {t('back-to-dashboard')}
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-5 col-md-12 col-12 mx-auto">
              <div className="user-form-container user-edit">
                <div className="user-form">
                  <form onSubmit={submitHandler}>
                    <div className="row">
                      {loading && <Loader/>}
                      {imageMessage && (
                        <Message variant="danger">{imageMessage}</Message>
                      )}

                      <div className="user-text">
                        <div className="user-register-label register-doctor">
                          <img
                            src="assets/images/hospital-icon.svg"
                            alt="Hospital"
                          />
                          <span>{t('personal-info')}</span>
                        </div>

                        <div className="user-picture">
                          <div className="profile-image">
                            <div
                              className="profile-image-wrapper"
                              style={{
                                background: `url(${profile_picture ? profile_picture : doctorPicture})`,
                              }}
                            ></div>
                            <img src={profile_picture ? profile_picture : doctorPicture} alt="Doctor Name"/>
                          </div>
                        </div>

                        <div className="col-sm-6 col-12 mb-3">
                          <label
                            htmlFor="file_name"
                            className="form-label edit_img_btn"
                          >
                            {t('edit-profile-picture')}
                          </label>
                          <input
                            type="file"
                            className="form-control hide"
                            id="file_name"
                            value={file_name}
                            onChange={async (e) => {
                              const imageName = e.target.value
                              const file = e.target.files[0]
                              setFile_name(imageName)
                              if (file.size > 8000000) {
                                setImageMessage('Image must be under 8 mb')
                              } else {
                                loadImage(
                                  file,
                                  async (img) => {
                                    const base = await img.toDataURL()
                                    setProfile_picture(base)
                                    setImageMessage(null)
                                  },
                                  {
                                    maxWidth: 600,
                                    maxHeight: 300,
                                    minWidth: 100,
                                    minHeight: 50,
                                    canvas: true,
                                  }
                                )
                              }
                            }}
                          />
                        </div>

                        <h2>
                          {t('hi')}, Dr.{' '}
                          {token ? token.tokenParams.name : t('name')}!
                        </h2>
                        <p className="desc">{t('create-termin-message')}.</p>
                      </div>

                      {/* <div>
                        {doctorProfile?.data && (
                          <input
                            onChange={onChangeThirdParty}
                            type='checkbox'
                            defaultChecked={
                              doctorProfile?.data.doctor_profile
                                .third_party_payment === 'YES'
                                ? true
                                : false
                            }
                          />
                        )}
                        <label style={{ marginLeft: '5px' }}>
                          Accept third party payment
                        </label>
                      </div> */}

                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="emailAddress" className="form-label">
                          {t('email')} <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="emailAddress"
                          placeholder={t('email')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="phoneNumber" className="form-label">
                          {t('phone-number')}{' '}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phoneNumber"
                          placeholder={t('phone-number')}
                          value={phone_number}
                          onChange={(e) => setPhone_number(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          {t('name')} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder={t('name')}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="surname" className="form-label">
                          {t('surname')} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="surname"
                          placeholder={t('surname')}
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="password" className="form-label">
                          {t('password')} <span className="required">*</span>
                        </label>
                        <input
                          autoComplete="new-password"
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder={t('password')}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <div className="form-text link">Edit password</div>
                      </div>
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="repeatPassword" className="form-label">
                          {t('repeat-password')}{' '}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="repeatPassword"
                          placeholder={t('repeat-password')}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='password' className='form-label'>
                          Social security number
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          id='idnumber'
                          placeholder='*****'
                          value={id_number}
                          onChange={(e) => setId_number(e.target.value)}
                        />
                      </div> */}
                      {/* <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='repeatPassword' className='form-label'>
                          Health insurance number
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='sanitycard'
                          placeholder='**********'
                          value={sanitary_card}
                          onChange={(e) => setSanitary_card(e.target.value)}
                        />
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-3">
                        <label
                          htmlFor="gender"
                          id="gender"
                          className="form-label"
                        >
                          {t('gender')}
                        </label>
                        <select
                          className="form-select"
                          aria-label={t('gender')}
                          name="gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="">{t('open-this-select-menu')}</option>
                          <option value="male">{t('male')}</option>
                          <option value="female">{t('female')}</option>
                        </select>
                      </div>
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="birth" className="form-label">
                          {t('birth')}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="birth"
                          placeholder={t('birth')}
                          value={birthday}
                          onChange={(e) => setBirthday(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="text" className="form-label">
                          {t('specialization')}{' '}
                          <span className="required">*</span>
                        </label>
                        <select
                          className="form-select"
                          required
                          aria-label="Default select example"
                          name="primary_specialization_id"
                          value={`${primary_specialization_id}`}
                          onChange={(e) =>
                            setPrimarySpecialization(e.target.value)
                          }
                        >
                          {allSpecialization?.data &&
                            allSpecialization.data.sort((a, b) => a.title > b.title ? 1 : -1).map((spec) => (
                              <option key={spec.id} value={`${spec.id}`}>
                                {`${t(spec.title)}`}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="text" className="form-label">
                          {t('additional-specialization')}{' '}
                          {/*<span className='required'>*</span>*/}
                        </label>
                        <select
                          className="form-select"
                          // required
                          aria-label="Default select example"
                          name="secondary_specialization_id"
                          value={`${secondary_specialization_id}`}
                          onChange={(e) =>
                            setSecondarySpeciazlization(e.target.value)
                          }
                        >
                          <option value=""></option>
                          {allSpecialization?.data &&
                            allSpecialization.data.sort((a, b) => a.title > b.title ? 1 : -1).map((spec) => (
                              <option key={spec.id} value={`${spec.id}`}>
                                {`${t(spec.title)}`}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='healthInsurance' className='form-label'>
                          Health insurance?
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='healthInsurance'
                          placeholder='?'
                          value={healt_insurance}
                          onChange={(e) => setHealt_insurance(e.target.value)}
                        />
                      </div> */}
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="info" className="form-label">
                          {t('short-dsc')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="info"
                          placeholder={t('short-dsc')}
                          value={this_page_required_info}
                          onChange={(e) =>
                            setThis_page_required_info(e.target.value)
                          }
                        />
                      </div>

                      <div
                        className="row"
                        onChange={(e) => setAcceptThirdParty(e.target.value)}
                      >
                        <label
                          htmlFor="accept_third_party"
                          className="form-label"
                        >
                          {t('accept-third-party')}
                        </label>
                        <div className="third_party_buttons">
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              style={{
                                backgroundColor: '#79D2CC',
                                display: 'inline',
                              }}
                              type="radio"
                              name="third_party_payment"
                              value="YES"
                              checked={third_party_payment === 'YES'}
                              onChange={onChangeThirdParty}
                            />
                            <label
                              className="form-check-label"
                              style={{ color: '#A1A5A8' }}
                              htmlFor="third_party_payment"
                            >
                              {t('first-visit-answer-yes')}
                            </label>
                          </div>

                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              style={{ backgroundColor: '#79D2CC' }}
                              type="radio"
                              name="third_party_payment"
                              value="NO"
                              checked={third_party_payment === 'NO'}
                              onChange={onChangeThirdParty}
                            />
                            <label
                              className="form-check-label"
                              style={{ color: '#A1A5A8' }}
                              htmlFor="third_party_payment"
                            >
                              {t('first-visit-answer-no')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="nationality" className="form-label">
                          {t('nationality')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="nationality"
                          placeholder={t('nationality')}
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6 col-12 mb-3">
                        <label htmlFor="country" className="form-label">
                          {t('country')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          placeholder={t('country')}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-5 col-12 mb-3">
                        <label htmlFor="address" className="form-label">
                          {t('city')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder={t('city')}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-5 col-12 mb-3">
                        <label htmlFor="street" className="form-label">
                          {t('street')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="street"
                          placeholder={t('street')}
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-2 col-12 mb-3">
                        <label htmlFor="nr" className="form-label">
                          {t('nr')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="nr"
                          placeholder={t('nr')}
                          value={house_number}
                          onChange={(e) => setHouse_number(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mb-3">
                        <label htmlFor="smtits" className="form-label">
                          {t('safetly')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="smtits"
                          placeholder={t('safetly')}
                          value={safety_measures}
                          onChange={(e) => setSafety_measures(e.target.value)}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label htmlFor="services" className="form-label">
                          {t('services')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="services"
                          placeholder={t('services')}
                          value={services}
                          onChange={(e) => setServices(e.target.value)}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label
                          htmlFor="formationsSpecial"
                          className="form-label"
                        >
                          {t('formation')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formationsSpecial"
                          placeholder={t('formation')}
                          value={formation_and_specialization}
                          onChange={(e) =>
                            setFormation_and_specialization(e.target.value)
                          }
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label htmlFor="disease" className="form-label">
                          {t('diseases')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="disease"
                          placeholder={t('diseases')}
                          value={disease_treated}
                          onChange={(e) => setDisease_treated(e.target.value)}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label htmlFor="languages" className="form-label">
                          {t('languages')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="languages"
                          placeholder={t('Languages')}
                          value={languages}
                          onChange={(e) => setLanguages(e.target.value)}
                        />
                      </div>
                      {messageSuccess && (
                        <Message variant="success">{messageSuccess}</Message>
                      )}
                      {message && <Message variant="danger">{message}</Message>}
                      {error && <Message variant="danger">{error}</Message>}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-flowers"
                      disabled={loading || elDisable}
                    >
                      {t('save')}
                      <span>{loading && <Loader/>}</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateDoctorProfileScreen
