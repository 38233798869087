import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import {
  clinicDoctorRegisterActions,
  getAllSpecialization,
} from '../../actions/doctorActions';

const RegisterClinicDoctorScreen = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [message, setMessage] = useState(null);

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [elDisable, setElDisable] = useState(false);
  const specializations = [];

  // let primary_specialization_id;
  const [primary_specialization_id, setPrimarySpecialization] = useState('');

  // Autocomplete
  const [singleSelections, setSingleSelections] = useState([]);
  let secondary_specialization_id;

  const allSpecializationData = useSelector((state) => state.allSpecialization);
  const { allSpecialization } = allSpecializationData;

  // Autocomplete
  const optionsKey = [];
  const options = [];
  if (allSpecialization?.data) {
    allSpecialization?.data.map((s) => {
      optionsKey.push(s);
      options.push(s.title);
    });
  }

  const clinicDoctorRegisterData = useSelector(
    (state) => state.clinicDoctorRegister
  );
  const { loading, error, clinicDoctorRegister } = clinicDoctorRegisterData;

  let navigate = useNavigate();
  useEffect(() => {
    if (clinicDoctorRegister?.status === 'success') {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, clinicDoctorRegister]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    dispatch(getAllSpecialization());
  }, [dispatch]);

  let click = false;
  useEffect(() => {
    if (!error && click) {
      setElDisable(true);
    }
  }, [error, click]);

  let findId;
  const submitHandler = async (e) => {
    e.preventDefault();

    if (singleSelections.length === 0) {
      setMessage(t('choise-secondary-cpecialization'));
    } else {
      findId = optionsKey.filter((x) => x.title === singleSelections[0]);
      secondary_specialization_id = findId[0].id;
    }

    if (
      secondary_specialization_id &&
      primary_specialization_id === secondary_specialization_id
    ) {
      setMessage(t('not-be-same'));
    } else {
      if (password !== confirmPassword) {
        setMessage(t('password-not-match'));
      } else {
        // Dispatch
        specializations.push(primary_specialization_id);
        specializations.push(secondary_specialization_id);
        click = true;
        dispatch(
          clinicDoctorRegisterActions(
            email,
            phoneNumber,
            name,
            surname,
            password,
            primary_specialization_id,
            secondary_specialization_id
          )
        );
      }
    }
  };

  return (
    <div className="container-xxl">
      <div className="row">
        <div className="col-xxl-5 col-md-12 col-12 mx-auto">
          <div className="user-form-container">
            <div className="user-text">
              <div className="user-register-label register-doctor">
                <img src="assets/images/hospital-icon.svg" alt="Doctor" />
                <span>{t('clinic-doctor')}</span>
              </div>
              <h2>{t('clinic-doctor-title')}</h2>
              <p>{t('clinic-doctor-message')}</p>
            </div>
            <div className="user-form">
              <form onSubmit={submitHandler}>
                {message && <Message variant="danger">{message}</Message>}
                {error && <Message variant="danger">{error}</Message>}
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="emailAddress" className="form-label">
                      {t('email')} <span className="required">*</span>
                    </label>
                    <input
                      autoComplete="new_email"
                      type="email"
                      className="form-control"
                      id="emailAddress"
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      {t('phone-number')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="+0012345678"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="firstName" className="form-label">
                      {t('name')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder={t('name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="surname" className="form-label">
                      {t('surname')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="surname"
                      placeholder={t('surname')}
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="password" className="form-label">
                      {t('password')} <span className="required">*</span>
                    </label>
                    <input
                      autoComplete="new_password"
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder={t('password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="repeatPassword" className="form-label">
                      {t('repeat-password')} <span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="repeatPassword"
                      placeholder={t('repeat-password')}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="text" className="form-label">
                      {t('specialization')} <span className="required">*</span>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="primary_specialization_id"
                      value={primary_specialization_id}
                      onChange={(e) => setPrimarySpecialization(e.target.value)}
                    >
                      {allSpecialization?.data &&
                        allSpecialization.data.map((spec) => (
                          <option key={spec.id} value={spec.id}>
                            {`${spec.title}`}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-sm-6 col-12 mb-3">
                    <label className="form-label">
                      {t('additional-specialization')}{' '}
                      <span className="required">*</span>
                    </label>
                    <div className="tagin-wrapper">
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="id"
                        onChange={setSingleSelections}
                        options={options}
                        placeholder="Type"
                        selected={singleSelections}
                      />
                      {(() => {
                        if (singleSelections.length) {
                          return (
                            <span className="tagin-tag">
                              {singleSelections}
                            </span>
                          );
                        }
                        return null;
                      })()}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-flowers"
                  disabled={loading || elDisable}
                >
                  {t('sign-up')}
                  <span>{loading && <Loader />}</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterClinicDoctorScreen;
