import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { curveChartOption } from "../../helpers/chartOptions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CurveChart = ({ labels, values, borderColor }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "Value",
        data: values,
        borderColor,
        backgroundColor: "#fff",
        tension: 0.5,
        borderWidth: 1.5,
        pointBorderColor: "#fff",
        pointBackgroundColor: borderColor,
        pointStyle: "curveRot",
        pointRadius: function (context) {
          let index = context.dataIndex;
          if (index == 6) {
            return 6;
          } else {
            return 3;
          }
        },
      },
    ],
  };

  return <Line options={curveChartOption} data={data} />;
};

export default CurveChart;
