import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import locationVector from '../map-location.svg';
import DoctorCard from './DoctorCard';
import { mapStyle } from '../helpers/constants';

const CustomMap = (props) => {
  const containerStyle = {
    width: '100%',
    height: `${props.height}px`,
  };

  const Marker = ({ text, item }) => (
    <img
      onClick={() => onMarkerClickHandler(item)}
      src={locationVector}
      alt={text}
      title={text}
      width={50}
      height={50}
      style={{ transform: 'translate(-25px, -50px)' }}
    />
  );

  const { data } = props;

  const doctorLat = Number(data[0]?.doctor_profile?.latitude) ? Number(data[0]?.doctor_profile?.latitude) : 0;

  const doctorLng = Number(data[0]?.doctor_profile?.longitude) ? Number(data[0]?.doctor_profile?.longitude) : 0;

  const center = { lat: doctorLat, lng: doctorLng };

  const zoom = 1;

  const [selectedPlace, setSelectedPlace] = useState(null);

  const [infoOpen, setInfoOpen] = useState(false);

  const onMarkerClickHandler = (item) => {
    setSelectedPlace(item);

    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);
  };

  const hideCard = () => {
    setSelectedPlace(null);
    setInfoOpen(false);
  };

  return (
    <div style={containerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDj0VG96eKPNbh-tjXTT55aPVf3SAp1g9c' }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        options={{ styles: mapStyle }}
      >
        {data.map((place) => (
          (place?.doctor_profile?.latitude !== null && place?.doctor_profile?.longitude !== null) &&
          <Marker
            key={place.id}
            lat={Number(place.doctor_profile?.latitude ? place.doctor_profile?.latitude : 0)}
            lng={Number(place.doctor_profile?.longitude ? place.doctor_profile?.longitude : 0)}
            text={place.name}
            item={place}
          />
        ))}
        {infoOpen && selectedPlace && (
          <div
            lat={Number(selectedPlace.doctor_profile?.latitude ? selectedPlace.doctor_profile?.latitude : 0)}
            lng={Number(selectedPlace.doctor_profile?.longitude ? selectedPlace.doctor_profile?.longitude : 0)}
            className='doctor-item'
            style={{ width: '500px' }}
          >
            <DoctorCard
              onCardClick={hideCard}
              isMapCard={true}
              data={selectedPlace}
            />
          </div>
        )}
      </GoogleMapReact>
    </div>
  );
};

export default CustomMap;
