import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { adminChangeProfileStatusDoctorActions, getAdminAllDoctorsActions } from '../actions/adminActions';

const ChangeProfileStatusScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [status, setStatus] = useState('');
  const doctorId = location?.state?.doctorId;

  useEffect(() => {
    if (location?.state?.status) {
      setStatus(location?.state?.status.toString()); // Ensure the status is set as a string if needed
    }
  }, [location]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(adminChangeProfileStatusDoctorActions(doctorId, status, location?.state?.usertype));
    dispatch(getAdminAllDoctorsActions());
    navigate('/doctors');
  };

  return (
    <div className="container-xxl">
      <div className="container-xxl page-heading">
        <div className="row">
          <div className="col-sm-4 col-12">
            <Link to='/doctors' className="back-to">
              {t('back-to-list')}
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-6 col-md-12 col-12 mx-auto">
          <div className="user-view">
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <label htmlFor="status" className="form-label">{t('select-doctor-status')}</label>
                <select
                  className="form-select"
                  required
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="0">{t('in-active')}</option>
                  <option value="1">{t('active')}</option>
                  <option value="2">{t('on-hold')}</option>
                </select>
              </div>
              <button type="submit" className="btn btn-nature">
                {t('change-status')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeProfileStatusScreen;
