// screens/ClinicsScreen.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClinics } from '../../actions/clinicActions';
import LoadingIndicator from '../../components/LoadingIndicator';
import Pagination from 'react-bootstrap/Pagination';
import { useTranslation } from 'react-i18next';

const AllClinicsScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const clinicList = useSelector((state) => state.clinicList);
    const { loading, error, clinics } = clinicList;

    useEffect(() => {
        dispatch(getClinics());
    }, [dispatch]);

    return (
        <div className='dashboard-container admin_pages_wrapper'>
            {loading && <LoadingIndicator />}
            {error && <div>Error: {error}</div>}
            <div className='container-xxl'>
                <h1>{t('all-clinics')}</h1>
                <div className='Rtable Rtable--5cols'>
                    <div className='Rtable-cell'><strong>{t('name')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('address')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('phone-number')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('Capacity')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('Current Patient Count')}</strong></div>
                    {clinics && clinics.length > 0 ? (
                        clinics.map((clinic) => (
                            <React.Fragment key={clinic.id}>
                                <div className='Rtable-cell'>{clinic.name}</div>
                                <div className='Rtable-cell'>{clinic.address}</div>
                                <div className='Rtable-cell'>{clinic.phone_number}</div>
                                <div className='Rtable-cell'>{clinic.capacity}</div>
                                <div className='Rtable-cell'>{"Calculated patient count"}</div>
                            </React.Fragment>
                        ))
                    ) : (
                        <div className='Rtable-cell'>No clinics found</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllClinicsScreen;
