import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import jwt from 'jwt-decode';

const Faq = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='legal_page'>
      <div className='container-xxl page-heading'>
        <div className='row'>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
            <Link to='/dashboard'>{t('back-to-dashboard')}</Link>
          </div>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
            <div className='user-register-label notifications'>
              <img src='assets/images/help.svg' alt='FAQ' />
              <span>{t('faq')}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='legal_wrapper'>
        <div className='container-xxl'>
          <div className='accordion accordion-faq' id='accordionExample'>
            {/* Dynamically create FAQ items here */}
            {Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className='accordion-item'>
                <h2 className='accordion-header' id={`heading${index}`}>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${index}`}
                    aria-expanded='false'
                    aria-controls={`collapse${index}`}
                  >
                    <div className='accordion-image'>
                      <img src='assets/images/help.svg' alt='help' />
                    </div>
                    <div className='accordion-title'>
                      <h3>{t('faq-review')}</h3>
                      <p className='normal light'>{t('faq-message')}</p>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className='accordion-collapse collapse'
                  aria-labelledby={`heading${index}`}
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    {t('faq-review-message')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
