import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import Message from '../../components/Message';
import { patientRegisterActions } from '../../actions/patientActions';

const RegisterPatientScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState('');
  const [message, setMessage] = useState(null);
  const { t, i18n } = useTranslation();
  const [elDisable, setElDisable] = useState(false);

  const dispatch = useDispatch();
  const patientRegisterData = useSelector((state) => state.patientRegister);
  const { loading, error, patientRegister } = patientRegisterData;

  let navigate = useNavigate();
  let location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      console.log(location, 'navigate.state')
      console.log(location.state?.from.length, 'navigate.state')
      if (location.state?.from.length > 0) {
        navigate(location.state.from);
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
  }, [navigate, userInfo]);

  let click = false;
  useEffect(() => {
    if (!error && click) {
      setElDisable(true);
    }
  }, [error, click]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    }
    // else if (!gender) {
    //   setMessage('Gender is required');
    // }
    else {
      click = true;
      dispatch(
        patientRegisterActions(email, name, surname, phoneNumber, password)
      );
      setMessage(null);
    }
  };

  console.log(location, 'location')

  return (
    <div className='register_patient'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-xxl-5 col-md-12 col-12 mx-auto'>
            <div className='user-form-container'>
              <div className='user-text'>
                <div className='user-register-label register-patient'>
                  <img
                    src='assets/images/patient-icon.svg'
                    alt='Patient-icon'
                  />
                  <span>{t('patient')}</span>
                </div>
                <h2>{t('register-as-patient')}</h2>
                <p>{t('clinic-doctor-message')}</p>
              </div>
              <div className='user-form'>
                <form onSubmit={submitHandler}>
                  <div className='row'>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='emailAddress' className='form-label'>
                        {t('email')} <span className='required'>*</span>
                      </label>
                      <input
                        autoComplete='new-email'
                        type='email'
                        className='form-control'
                        id='emailAddress'
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='phoneNumber' className='form-label'>
                        {t('phone-number')} <span className='required'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='phoneNumber'
                        placeholder={t('phone-number')}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='firstName' className='form-label'>
                        {t('name')} <span className='required'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='firstName'
                        placeholder={t('name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='surname' className='form-label'>
                        {t('surname')} <span className='required'>*</span>
                      </label>
                      <input
                        autoComplete='new-surname'
                        type='text'
                        className='form-control'
                        id='surname'
                        placeholder={t('surname')}
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='password' className='form-label'>
                        {t('password')} <span className='required'>*</span>
                      </label>
                      <input
                        autoComplete='new-password'
                        type='password'
                        className='form-control'
                        id='password'
                        placeholder={t('password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6 col-12 mb-3'>
                      <label htmlFor='confirmPassword' className='form-label'>
                        {t('repeat-password')}
                        <span className='required'>*</span>
                      </label>
                      <input
                        type='password'
                        className='form-control'
                        id='confirmPassword'
                        placeholder={t('repeat-password')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>

                    {/* <div className='row'>
                      <div className='col-sm-6 col-12 mb-3'>
                        <label htmlFor='text' className='form-label' id='gender'>
                          {t('gender')}
                        </label>

                        <select
                          className='form-select'
                          aria-label='Default select example'
                          required
                          name='gender'
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option>{t('open-this-select-menu')}</option>
                          <option value='male'>{t('male')}</option>
                          <option value='female'>{t('female')}</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                  {message && <Message variant='danger'>{message}</Message>}
                  {error && <Message variant='danger'>{error}</Message>}
                  <button
                    type='submit'
                    className='btn btn-ocean'
                    disabled={loading || elDisable}
                  >
                    {t('sign-up')}
                    <span>{loading && <Loader />}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPatientScreen;
