import React from "react";
import { useTranslation } from "react-i18next";


const SuccessNotification = ({ message }) => {
    const { t, i18n } = useTranslation();
    return (
      <div className="notification-item">
        <div className="notification-desc">
          <div className="notification-icon nature">
            <img src="assets/images/successfull.svg" alt="Success" />
          </div>
          <div className="notification-info">
            <h3>{t("success")}!</h3>
            <p>
              {message}
              {/* <a href="/">{t("check-it-now")}</a> */}
            </p>
          </div>
        </div>
      </div>
    );
  };

  export default SuccessNotification;