import {
  GET_CLINIC_LIST_FAIL,
  GET_CLINIC_LIST_REQUEST,
  GET_CLINIC_LIST_SUCCESS,
  CLINIC_CREATE_DOCTOR_REQUEST,
  CLINIC_CREATE_DOCTOR_SUCCESS,
  CLINIC_CREATE_DOCTOR_FAIL,
} from '../constants/clinicConstants';

// reducers/clinicReducers.js
const initialState = {
  clinics: [],
  loading: false,
  error: null,
};

export const clinicListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLINIC_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_CLINIC_LIST_SUCCESS:
      return { ...state, loading: false, clinics: action.payload };
    case GET_CLINIC_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CLINIC_CREATE_DOCTOR_REQUEST:
      return { ...state, loading: true };
    case CLINIC_CREATE_DOCTOR_SUCCESS:
      return { ...state, loading: false };
    case CLINIC_CREATE_DOCTOR_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
