import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CommentModal = ({
  data,
  onCloseModal,
  objectProperty,
  onModalConfirm,
}) => {
  const [currentComment, setCurrentComment] = useState(data);

  const setNewComment = (event) => {
    const prevState = { ...currentComment };
    prevState[objectProperty] = event.target.value;
    setCurrentComment(prevState);
  };

  let { t, i18n } = useTranslation();

  return (
    <div
      className='modal modal-book modal-health-overview fade show'
      tabIndex='-1'
      style={{ display: 'block' }}
      aria-modal='true'
      role='dialog'
    >
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              onClick={onCloseModal}
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <h2>{t('describe-your-pain')}</h2>
          </div>
          <div className='modal-footer'>
            <textarea
              value={`${
                currentComment[objectProperty] == null
                  ? ''
                  : currentComment[objectProperty]
              }`}
              onChange={setNewComment}
              className='form-control'
              id=''
              rows='3'
              placeholder='Type...'
            ></textarea>
            <button
              onClick={() => onModalConfirm(currentComment)}
              type='button'
              className='btn btn-ocean'
            >
              {t('add-comment')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScaleOfPainItem = ({
  title,
  painScale,
  onSelectPainScale,
  onCommentClick,
  onRemoveComment,
}) => {
  const onSelectValue = (event) => {
    onSelectPainScale(event.target.value);
  };

  return (
    <div className={`item mb-1 ${painScale > 0 ? 'selected' : ''}`}>
      <div className='scale-tools'>
        <a onClick={() => painScale > 0 && onCommentClick()} className='chat'>
          <img src='assets/images/chat.svg' alt='Chart' title='Chart' />
        </a>

        <a className='delete'>
          <img
            onClick={onRemoveComment}
            src='assets/images/trash.svg'
            alt='Trash'
            title='Trash'
          />
        </a>
      </div>

      <div className='input-group'>
        <span className='input-group-text'>{t(title)}</span>
        <select
          onChange={onSelectValue}
          value={painScale}
          className='form-select select-pain'
        >
          <option value='0'>= 0 {t('no-pain')}</option>
          <option value='1'>= 1 {t('weak-pain')}</option>
          <option value='2'>= 2 {t('moderate-pain')}</option>
          <option value='3'>= 3 {t('severe-pain')}</option>
          <option value='4'>= 4 {t('unberable-pain')}</option>
        </select>
        <i className='fa-solid fa-caret-down'></i>
      </div>
    </div>
  );
};

const ScaleOfPain = ({ data, onChangeScalePain }) => {
  const [scaleOfPainData, setScaleOfPain] = useState();

  const [openCommentModal, setOpenCommentModal] = useState(false);

  const [commentObjProperty, setCommentObjProperty] = useState('');

  useEffect(() => {
    setScaleOfPain(data);
  }, [data]);

  const onSelectPainScale = (objectProperty, value) => {
    const prevState = { ...scaleOfPainData };
    prevState[objectProperty] = Number(value);
    onChangeScalePain(prevState);
  };

  const onRemoveComment = (objectProperty) => {
    const prevState = { ...scaleOfPainData };
    prevState[objectProperty] = 0;
    prevState[objectProperty + '_comment'] = null;
    onChangeScalePain(prevState);
  };

  const onCommentClick = (objectProperty) => {
    setCommentObjProperty(objectProperty + '_comment');
    setOpenCommentModal(true);
  };

  const onCloseModal = () => {
    setOpenCommentModal(false);
  };

  const onModalConfirm = (data) => {
    setScaleOfPain(data);
    onChangeScalePain(data);
    setOpenCommentModal(false);
  };

  return (
    <section className='scale-pain'>
      {openCommentModal && (
        <CommentModal
          onCloseModal={onCloseModal}
          objectProperty={commentObjProperty}
          onModalConfirm={onModalConfirm}
          data={scaleOfPainData}
        />
      )}
      <div className='row'>
        <div className='col-xxl-6'>
          <div className='body-pain'>
            <img
              className='img-fluid'
              src='assets/health-overview.svg'
              alt='Health Overview'
              title='Health Overview'
            />
          </div>
          <div className='health-select-date d-lg-none'>
            <div className='select-date'>
              <Link to='#' className='change-date'>
                {t('today-novenber')} 2021
              </Link>
            </div>
          </div>
        </div>
        <div className='col-xxl-6'>
          <div className='scale-describe'>
            <h3>
              {t('scale-of-pain')} <p>{t('describe')},</p>
            </h3>
            <div className='scale-describe-items'>
              <ScaleOfPainItem
                comment={scaleOfPainData?.stomach_comment}
                painScale={scaleOfPainData?.stomach}
                onRemoveComment={() => onRemoveComment('stomach')}
                onCommentClick={() => onCommentClick('stomach')}
                onSelectPainScale={(value) =>
                  onSelectPainScale('stomach', value)
                }
                title='scale-of-pain-stomach'
              />

              <ScaleOfPainItem
                painScale={scaleOfPainData?.heart}
                onRemoveComment={() => onRemoveComment('heart')}
                onCommentClick={() => onCommentClick('heart')}
                onSelectPainScale={(value) => onSelectPainScale('heart', value)}
                title='scale-of-pain-hearth'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.chest}
                onRemoveComment={() => onRemoveComment('chest')}
                onCommentClick={() => onCommentClick('chest')}
                onSelectPainScale={(value) => onSelectPainScale('chest', value)}
                title='scale-of-pain-chest'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.heat}
                onRemoveComment={() => onRemoveComment('heat')}
                onCommentClick={() => onCommentClick('heat')}
                onSelectPainScale={(value) => onSelectPainScale('heat', value)}
                title='scale-of-pain-heat'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.right_leg}
                onRemoveComment={() => onRemoveComment('right_leg')}
                onCommentClick={() => onCommentClick('right_leg')}
                onSelectPainScale={(value) =>
                  onSelectPainScale('right_leg', value)
                }
                title='scale-of-pain-right-leg'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.left_leg}
                onRemoveComment={() => onRemoveComment('left_leg')}
                onCommentClick={() => onCommentClick('left_leg')}
                onSelectPainScale={(value) =>
                  onSelectPainScale('left_leg', value)
                }
                title='scale-of-pain-left-leg'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.left_arm}
                onRemoveComment={() => onRemoveComment('left_arm')}
                onCommentClick={() => onCommentClick('left_arm')}
                onSelectPainScale={(value) =>
                  onSelectPainScale('left_arm', value)
                }
                title='scale-of-pain-left-arm'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.right_arm}
                onRemoveComment={() => onRemoveComment('right_arm')}
                onCommentClick={() => onCommentClick('right_arm')}
                onSelectPainScale={(value) =>
                  onSelectPainScale('right_arm', value)
                }
                title='scale-of-pain-right-arm'
              />
              <ScaleOfPainItem
                painScale={scaleOfPainData?.whole_body}
                onRemoveComment={() => onRemoveComment('whole_body')}
                onCommentClick={() => onCommentClick('whole_body')}
                onSelectPainScale={(value) =>
                  onSelectPainScale('whole_body', value)
                }
                title='scale-of-pain-whole-body'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScaleOfPain;
