import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../components/DoctorUsers/Header';
import UserCard from '../../components/DoctorUsers/UserCard';
import LoadingIndicator from '../../components/LoadingIndicator';
import { API_ROUTES, appRoutes } from '../../helpers/constants';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const DoctorUserListScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const [userList, setUserList] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.DOCTOR.LIST_PATIENTS,
        {
          page: 1,
        },
        config
      )
      .then((response) => {
        setLoading(false);
        setUserList(response.data.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Header />
      <div className='doctor_patient_list'>
        <div className='dashboard-container'>
          <div className='container-xxl'>
            <div className='user-view'>
              <div className='row'>
                {userList &&
                  userList.map((element, index) => {
                    return (
                        <div key={index} className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto'>
                          <UserCard data={element} />
                        </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorUserListScreen;
