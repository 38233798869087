import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CurveChart from '../../components/Charts/CurveChart';
import ScaleOfPain from '../../components/HealthOverview/ScaleOfPain';
import HealthOverviewHeader from '../../components/HealthOverview/HealthOverviewHeader';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_ROUTES, lastSevenDays, newDate } from '../../helpers/constants';
import PeriodCalendar from '../../components/HealthOverview/PeriodCalendar';
import jwt from 'jwt-decode';

import moment from 'moment';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const defaultChartValues = [0, 0, 0, 0, 0, 0, 0];

const chartValueLength = 6;

const HealthOverviewScreen = () => {
  const [todayDate, setTodayDate] = useState(newDate);

  const [loading, setLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [isEditable, setIsEditable] = useState(true);

  const { t, i18n } = useTranslation();

  const [healthOverViewHistory, setHealthOverviewHistory] = useState({
    blood_pressure: defaultChartValues,
    blood_sugar: defaultChartValues,
    bmi: defaultChartValues,
    glass_per_day: defaultChartValues,
    height: defaultChartValues,
    scale_of_pain: defaultChartValues,
    steps: defaultChartValues,
    the_arterial_pressure: defaultChartValues,
    weight: defaultChartValues,
  });

  const [scaleOfPainData, setScaleOfPain] = useState({
    stomach: 0,
    stomach_comment: null,
    heart: 0,
    heart_comment: null,
    chest: 0,
    chest_comment: null,
    heat: 0,
    heat_comment: null,
    right_leg: 0,
    right_leg_comment: null,
    left_leg: 0,
    left_leg_comment: null,
    right_arm: 0,
    right_arm_comment: null,
    left_arm: 0,
    left_arm_comment: null,
    whole_body: 0,
    whole_body_comment: null,
  });

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const user = jwt(userInfo.data).tokenParams;

  const [GENDER, setGender] = useState(null);

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const onSelectedDate = async (date, isActive) => {
    setIsEditable(isActive);
    setTodayDate(new Date(date));
    await axios
      .post(
        API_ROUTES.PATIENT.GET_HEALTH_OVERVIEW,
        {
          health_overview_date: date,
        },
        config
      )
      .then((response) => {
        let historyResponse = response.data.data.health_overview_history;

        if (response.data.data.health_overview_for_date.length > 0) {
          const destructed = response.data.data.health_overview_for_date[0];
          setScaleOfPain(destructed);

          historyResponse.blood_pressure.push(destructed.blood_pressure);
          historyResponse.blood_sugar.push(destructed.blood_sugar);
          historyResponse.height.push(destructed.height);
          historyResponse.weight.push(destructed.weight);
          historyResponse.bmi.push(destructed.bmi);
          historyResponse.steps.push(destructed.steps);
          historyResponse.the_arterial_pressure.push(
            destructed.the_arterial_pressure
          );
          historyResponse.glass_per_day.push(destructed.glass_per_day);
          historyResponse.scale_of_pain.push(destructed.scale_of_pain);
        } else {
          historyResponse.blood_pressure.push(0);
          historyResponse.blood_sugar.push(0);
          historyResponse.height.push(0);
          historyResponse.weight.push(0);
          historyResponse.bmi.push(0);
          historyResponse.steps.push(0);
          historyResponse.the_arterial_pressure.push(0);
          historyResponse.glass_per_day.push(0);
          historyResponse.scale_of_pain.push(0);
        }
        setHealthOverviewHistory(historyResponse);
        setGender(response?.data?.data?.patient?.gender);
      });
  };

  const labels = lastSevenDays(todayDate, 'MMMM Do');

  const onCardUpdate = (event, objectProperty) => {
    const prevState = { ...healthOverViewHistory };
    setIsDisabled(false);
    prevState[objectProperty][chartValueLength] = event.target.value;
    setHealthOverviewHistory(prevState);
  };

  const onSaveOverView = async () => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.PATIENT.UPDATE_HEALTH_OVERVIEW,
        {
          health_overview_date: moment().format('YYYY-MM-DD'),
          stomach: scaleOfPainData.stomach,
          stomach_comment: scaleOfPainData.stomach_comment,
          heart: scaleOfPainData.heart,
          heart_comment: scaleOfPainData.heart_comment,
          chest: scaleOfPainData.chest,
          chest_comment: scaleOfPainData.chest_comment,
          heat: scaleOfPainData.heat,
          heat_comment: scaleOfPainData.heat_comment,
          right_leg: scaleOfPainData.right_leg,
          right_leg_comment: scaleOfPainData.right_leg_comment,
          left_leg: scaleOfPainData.left_leg,
          left_leg_comment: scaleOfPainData.left_leg_comment,
          right_arm: scaleOfPainData.right_arm,
          right_arm_comment: scaleOfPainData.right_arm_comment,
          left_arm: scaleOfPainData.left_arm,
          left_arm_comment: scaleOfPainData.left_arm_comment,
          whole_body: scaleOfPainData.whole_body,
          whole_body_comment: scaleOfPainData.whole_body_comment,
          blood_sugar: healthOverViewHistory.blood_sugar[chartValueLength],
          blood_pressure:
            healthOverViewHistory.blood_pressure[chartValueLength],
          height: healthOverViewHistory.height[chartValueLength],
          weight: healthOverViewHistory.weight[chartValueLength],
          bmi: healthOverViewHistory.bmi[chartValueLength],
          steps: healthOverViewHistory.steps[chartValueLength],
          the_arterial_pressure:
            healthOverViewHistory.the_arterial_pressure[chartValueLength],
          glass_per_day: healthOverViewHistory.glass_per_day[chartValueLength],
          scale_of_pain: healthOverViewHistory.scale_of_pain[chartValueLength],
        },
        config
      )
      .then(() => {
        setIsDisabled(true);
        setLoading(false);
      })
      .catch(() => {
        setIsDisabled(true);
        setLoading(false);
      });
  };

  const textInputHealthChange = (
    type,
    title,
    whatToUpdate,
    subtitle,
    inputWidth
  ) => {
    return (
      <div className='health-overview-card d-flex flex-column justify-content-between'>
        <h3>{t(title)}</h3>
        <div
          className='d-flex flex-row w-100 align-items-center'
          style={{ padding: '10px' }}
        >
          <input
            type={type}
            disabled={!isEditable}
            style={{ width: inputWidth }}
            onChange={(event) => onCardUpdate(event, whatToUpdate)}
            value={healthOverViewHistory?.[whatToUpdate][chartValueLength]}
          />
          <p style={{ marginLeft: '10px' }} className='mb-0'>
            {t(subtitle)}
          </p>
        </div>
      </div>
    );
  };

  const onChangeScalePain = (data) => {
    setIsDisabled(false);
    setScaleOfPain(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className='container-xxl health_overview_page'>
      {loading && <LoadingIndicator />}
      <div className='page-heading'>
        <HealthOverviewHeader selectedDate={onSelectedDate} />
      </div>
      {isEditable && (
        <div className='d-flex flex-row justify-content-center cp mt-2'>
          <div
            onClick={() => !isDisabled && onSaveOverView()}
            className={`user-register-label chart ${
              isDisabled ? 'save-disabled' : ''
            }`}
          >
            <img src='assets/images/chart.svg' />
            <span>{t('save')}</span>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
          <div className='health-overview-card'>
            <h3>{t('nbm')}</h3>
            <CurveChart
              borderColor={'#4066B7'}
              values={healthOverViewHistory?.blood_sugar}
              labels={labels}
            />
            <div className='result'>
              <input
                type='number'
                disabled={!isEditable}
                className='b-none'
                onChange={(event) => onCardUpdate(event, 'blood_sugar')}
                value={healthOverViewHistory?.blood_sugar[chartValueLength]}
              />
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
          <div className='health-overview-card'>
            <h3>{t('weight')}</h3>
            <CurveChart
              borderColor={'#F5C84F'}
              values={healthOverViewHistory?.weight}
              labels={labels}
            />
            <div className='result'>
              <input
                type='number'
                disabled={!isEditable}
                className='b-none'
                onChange={(event) => onCardUpdate(event, 'weight')}
                value={healthOverViewHistory?.weight[chartValueLength]}
              />
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
          <div className='health-overview-card'>
            <h3>{t('oxygen-saturation')}</h3>
            <CurveChart
              borderColor={'#79D2CC'}
              values={healthOverViewHistory?.bmi}
              labels={labels}
            />
            <div className='result'>
              <input
                type='number'
                disabled={!isEditable}
                className='b-none'
                onChange={(event) => onCardUpdate(event, 'bmi')}
                value={healthOverViewHistory?.bmi[chartValueLength]}
              />
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
          <div className='health-overview-card'>
            <h3>{t('respiratory-frequency')}</h3>
            <CurveChart
              borderColor={'#EC5C78'}
              values={healthOverViewHistory?.steps}
              labels={labels}
            />
            <div className='result'>
              <input
                type='number'
                disabled={!isEditable}
                className='b-none'
                onChange={(event) => onCardUpdate(event, 'steps')}
                value={healthOverViewHistory?.steps[chartValueLength]}
              />
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
          <div className='health-overview-card'>
            <h3>{t('temperature')}</h3>
            <CurveChart
              borderColor={'#EC5C78'}
              values={healthOverViewHistory?.scale_of_pain}
              labels={labels}
            />
            <div className='result'>
              <input
                type='number'
                disabled={!isEditable}
                className='b-none'
                onChange={(event) => onCardUpdate(event, 'scale_of_pain')}
                value={healthOverViewHistory?.scale_of_pain[chartValueLength]}
              />
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
          {textInputHealthChange(
            'number',
            'liter-water-per-day',
            'height',
            'water-per-day',
            '60%'
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-xxl-6'>
          <div className='row'>
            <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
              {textInputHealthChange(
                'number',
                'cigarettes-per-day',
                'the_arterial_pressure',
                'per-day',
                '60%'
              )}
            </div>
            <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-4'>
              {textInputHealthChange(
                'number',
                'smoker-for-how-many-years',
                'glass_per_day',
                '',
                '100%'
              )}
            </div>
            <div className='col-xxl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-4'>
              {textInputHealthChange(
                'text',
                'toxic-consumption',
                'blood_pressure',
                '',
                '100%'
              )}
            </div>
            <div
              style={{ position: 'relative' }}
              className='col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12'
            >
              <div
                className={
                  GENDER === null || GENDER === 'male' ? 'disable_period' : ''
                }
              >
                {/* <PeriodCalendar /> */}
              </div>

              {GENDER === null ||
                (GENDER === 'male' && (
                  <button className='not_applicable'>
                    {t('not-applicable')}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div className='col-xxl-6'>
          <ScaleOfPain
            onChangeScalePain={onChangeScalePain}
            data={scaleOfPainData}
          />
        </div>
      </div>
    </section>
  );
};

export default HealthOverviewScreen;
