import axios from 'axios';
import { useState, useEffect } from 'react';
import DocumentHeader from '../../components/Documents/DocumentHeader';
import DocumentUpload from '../../components/Documents/DocumentUpload';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getItemFromLocalStorage, toBase64 } from '../../helpers/helpers';
import { API_ROUTES } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import DocumentCard from '../../components/Documents/DocumentCard';

const DocumentScreen = () => {
  const [loading, setLoading] = useState(false);

  const [uploadedDocs, setUploadDocs] = useState([]);

  const [doctors, setDoctors] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const fetchDoctors = async () => {
    setLoading(true);
    await axios
      .post(API_ROUTES.PATIENT.GET_DOCTORS_FROM_DOCUMENT, null, config)
      .then((response) => {
        setLoading(false);
        setDoctors(response.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchDocuments = async () => {
    setLoading(true);
    await axios
      .post(API_ROUTES.PATIENT.GET_DOCUMENTS, {}, config)
      .then((response) => {
        setUploadDocs(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDoctors();
    fetchDocuments();
  }, []);

  const onFilesUpload = async (files) => {
    setLoading(true);


    const documents = [];

    const length = await files.length;

    for (let i = 0; i < length; i++) {
      documents.push({
        title: await files[i].name,
        content: await toBase64(files[i]),
      });
    }

    await axios
      .post(
        API_ROUTES.PATIENT.UPLOAD_DOCUMENTS,
        {
          user_id: token.tokenParams.id,
          documents,
        },
        config
      )
      .then(() => {
        window.location.reload()
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    fetchDocuments();
  };

  return (
    <>
      <div className='document_share_page'>
        {loading && <LoadingIndicator />}
        <DocumentHeader />
        <DocumentUpload
          accept='.doc, .docx,.pdf,.jpg,.jpeg,.png'
          onFilesUpload={onFilesUpload}
        />
        <div className='dashboard-container'>
          <div className='container-xxl'>
            <div className='notifications'>
              <div className='row'>
                {uploadedDocs &&
                  uploadedDocs.map((element, index) => {
                    return (
                      <div key={index}>
                        <DocumentCard
                          haveDownload={true}
                          doctors={doctors}
                          data={element}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentScreen;
