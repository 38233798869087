import {
  CALENDAR_FOR_MONTH_FAIL,
  CALENDAR_FOR_MONTH_REQUEST,
  CALENDAR_FOR_MONTH_SUCCESS,
  UPCOMING_EVENTS_FAIL,
  UPCOMING_EVENTS_REQUEST,
  UPCOMING_EVENTS_SUCCESS,
} from '../constants/calendarConstants';

export const calendarForMonthReducer = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_FOR_MONTH_REQUEST:
      return { loading: true };
    case CALENDAR_FOR_MONTH_SUCCESS:
      return { loading: false, calendarInfo: action.payload };
    case CALENDAR_FOR_MONTH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const upcomingEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPCOMING_EVENTS_REQUEST:
      return { loading: true };
    case UPCOMING_EVENTS_SUCCESS:
      return { loading: false, upcomingEvents: action.payload };
    case UPCOMING_EVENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
