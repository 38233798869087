import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { paymentPlanActions } from '../actions/paymentActions';

import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { API_ROUTES } from '../helpers/constants';
import jwt from 'jwt-decode';
import LoadingIndicator from './LoadingIndicator';
import { getItemFromLocalStorage } from '../helpers/helpers';

let stripeKey =
  'pk_live_51LI9WXEkaw9DT5033vxFkcob5YhjTSUrQiL4c8Jrhv30zSB2dbtVkZ16JTFUp3F2OACiA8emkIAc27oNt6NECZzh00O6xauwUr';

const stripePromise = loadStripe(stripeKey);

const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: '16px',
          letterSpacing: '0.025em',
          border: '1px solid red',
          backgroundColor: '#fff',
          padding: 16,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};

const StripeForm = ({ submitePayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardNumberElement);

    // stripe's create payment method Api
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('error:', error);
    } else {
      submitePayment(paymentMethod);
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className='mb-10'>
        <div className='mb-4'>
          <p className='text-grey'>{t('card-number')}</p>
          <CardNumberElement options={options} />
        </div>

        <div className='mb-4'>
          <p className='text-grey'>{t('expiration-date')}</p>
          <CardExpiryElement options={options} />
        </div>

        <div className=''>
          <p className='text-grey'>CVC</p>
          <CardCvcElement options={options} />
        </div>
      </div>

      <button
        className='btn btn-darkblue'
        disabled={!stripe || !elements}
        onClick={handleSubmit}
      >
        {t('pay')}
      </button>
    </div>
  );
};

const PaymentPlan = () => {
  const dispatch = useDispatch();
  const paymentPlanData = useSelector((state) => state.paymentPlan);

  const [showPaymentPlan, setShowPaymentPlan] = useState(false);

  const [plan, setPlan] = useState(null);

  const { loading, error, paymentPlan } = paymentPlanData;

  const [loaader, setLoading] = useState(false);

  const [subscriptionID, setSubscriptionID] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  const getProfileInfo = async () => {
    setLoading(true);
    await axios
      .post(API_ROUTES.DOCTOR.GET_SUBSCRIPTION, null, config)
      .then((response) => {
        // console.log('SUBSCRIPTION', response);
        setSubscriptionID(response.data.data.plan.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // console.log('PAYMENT PLAN', paymentPlan?.data);

  useEffect(() => {
    dispatch(paymentPlanActions());
  }, [dispatch]);

  useEffect(() => {
    getProfileInfo();
  }, []);

  const submitPayment = async (data) => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.DOCTOR.SUBSCRIPTION_PLAN,
        {
          plan_id: plan.id,
          stripe_payment_method_id: data.id,
        },
        config
      )
      .then(() => {
        setLoading(false);
        window.location.reload();
        setShowPaymentPlan(false);
      });
  };

  const { t, i18n } = useTranslation();

  const subscription_plan = localStorage.getItem('subscription_plan')
    ? JSON.parse(localStorage.getItem('subscription_plan')).plan
    : false;

  // console.log(notSubscription);
  return (
    <div className='container-xxl doctor_payment_plan_page'>
      <div className='container-xxl page-heading'>
        <div className='row'>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
            <Link to='/dashboard'>
              <div className='back-to'>{t('back-to-dashboard')}</div>
            </Link>
          </div>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
            <div className='user-register-label register-freelance'>
              <img src='/assets/images/freelance-icon.svg' alt='Freelance' />
              <span>{t('freelance-doctor')}</span>
            </div>
          </div>
        </div>
      </div>
      {loaader && <LoadingIndicator />}
      <div className='row'>
        <div className='col-xxl-5 col-md-12 col-12 mx-auto'>
          <div className='user-form-container'>
            <div className='user-text'>
              <h2>{t('find-plan')}</h2>
              <p>{t('clinic-doctor-message')}</p>
            </div>
          </div>
        </div>
      </div>
      {subscription_plan && (
        <Link to='/your-credit' className='btn btn-darkblue see_credits_btn'>
          {t('see-your-credit')}
        </Link>
      )}
      <div className='register-plans'>
        <div className='row'>
          {paymentPlan?.plans &&
            paymentPlan?.plans.map((plan) => (
              <div
                className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'
                key={plan?.id}
              >
                <div className='register-plan'>
                  <div className='register-plan-name'>{plan.nickname}</div>
                  <div className='register-plan-price'>
                    {plan.currency === 'usd' && '$'}
                    {plan.amount / 100}
                  </div>
                  <div className='register-plan-feature-list'>
                    <ul>
                      <li>
                        {i18n.language === 'sr'
                          ? plan.metadata.first_paragraph_srb
                          : plan.metadata.first_paragraph}
                      </li>
                      <li>
                        {i18n.language === 'sr'
                          ? plan.metadata.second_paragraph_srb
                          : plan.metadata.second_paragraph}
                      </li>
                      <li className='check-red'>
                        {i18n.language === 'sr'
                          ? plan.metadata.third_paragraph_srb
                          : plan.metadata.third_paragraph}
                      </li>
                      <li className='check-disable'>
                        {i18n.language === 'sr'
                          ? plan.metadata.fourth_paragraph_srb
                          : plan.metadata.fourth_paragraph}
                      </li>
                      <li className='check-disable'>
                        {i18n.language === 'sr'
                          ? plan.metadata.fifth_paragraph_srb
                          : plan.metadata.fifth_paragraph}
                      </li>
                    </ul>
                  </div>

                  {!token?.tokenParams ? (
                    <Link to='/doctor-register' className='btn btn-darkblue'>
                      {t('register-as-doctor')}
                    </Link>
                  ) : (
                    subscriptionID !== plan.id && (
                      <div className='register-plan-button'>
                        {subscriptionID !== plan.id &&
                        !token?.tokenParams?.stripe_subscription_id ? (
                          <Link
                            to='/buy-plan'
                            state={{ plan: plan }}
                            className='btn btn-darkblue'
                          >
                            {t('buy-plan')}
                          </Link>
                        ) : (
                          <Link
                            to='/change-plan'
                            state={{ plan: plan }}
                            className='btn btn-darkblue'
                          >
                            {t('start-now')}
                          </Link>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* permanent plan */}
      {token &&
        paymentPlan?.permanently_plan &&
        paymentPlan?.permanently_plan.map((plan) => (
          <div className='one_day_plan ' key={plan?.id}>
            <div className='user_text'>
              <h2>{t('single-credit-meeting')}</h2>
            </div>
            <div className='register-plan col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12'>
              <div className='register-plan-name'>{plan.nickname}</div>
              <div className='register-plan-price'>
                {plan.currency === 'usd' && '$'}
                {plan.amount / 100}
              </div>
              <div className='register-plan-feature-list'>
                <ul>
                  <li>
                    {i18n.language === 'sr'
                      ? plan.first_paragraph_srb
                      : plan.fifth_paragraph}
                  </li>
                  <li>
                    {i18n.language === 'sr'
                      ? plan.second_paragraph_srb
                      : plan.second_paragraph}
                  </li>
                  <li className='check-red'>
                    {i18n.language === 'sr'
                      ? plan.third_paragraph_srb
                      : plan.third_paragraph}
                  </li>
                  <li className='check-disable'>
                    {i18n.language === 'sr'
                      ? plan.fourth_paragraph_srb
                      : plan.fourth_paragraph}
                  </li>
                  <li className='check-disable'>
                    {i18n.language === 'sr'
                      ? plan.fifth_paragraph_srb
                      : plan.fifth_paragraph}
                  </li>
                </ul>
              </div>

              {subscriptionID !== plan.id && (
                <div className='register-plan-button'>
                  {subscriptionID !== plan.id && (
                    <Link
                      to='/permamently-plan'
                      state={{ plan: plan }}
                      className='btn btn-darkblue'
                    >
                      {t('buy-package')}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      {/* end permanent plan */}
      {showPaymentPlan && (
        <>
          <h2>
            {t('you-choise-plan')}: {plan.currency === 'usd' && '$'}
            {plan.amount / 100} {plan.nickname}
          </h2>
          <Elements stripe={stripePromise} options={{}}>
            <StripeForm submitePayment={submitPayment} />
          </Elements>
          <div className='second_payment_option'>
            <p>
              {t('in case you wish to pay with a different payment method please contact our support staff, they will help you set up the payment')}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentPlan;
