import React, { useState, useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import {
  getAllSpecialization, freelenceDoctorRegisterActions,
} from '../../actions/doctorActions'

const RegisterFreelanceDoctorScreen = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [message, setMessage] = useState(null)

  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [nr, setNr] = useState('')
  const [accept_third_party, setAcceptThirdParty] = useState('')
  const [elDisable, setElDisable] = useState(false)
  const specializations = []

  // let primary_specialization_id;
  const [primary_specialization_id, setPrimarySpecialization] = useState('')

  // Autocomplete
  const [singleSelections, setSingleSelections] = useState([])
  let secondary_specialization_id

  const allSpecializationData = useSelector((state) => state.allSpecialization)
  const { allSpecialization } = allSpecializationData

  // Autocomplete
  const optionsKey = []
  const options = []
  if (allSpecialization?.data) {
    allSpecialization?.data.sort((a, b) => a.title > b.title ? 1 : -1).map((s) => {
      optionsKey.push(s)
      options.push(s.title)
    })
  }

  const freelenceDoctorRegisterData = useSelector((state) => state.freelenceDoctorRegister)
  const { loading, error, freelenceDoctorRegister } = freelenceDoctorRegisterData

  let navigate = useNavigate()
  useEffect(() => {
    if (freelenceDoctorRegister?.status === 'success') {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, freelenceDoctorRegister])

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // console.log(userInfo);
    if (userInfo) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, userInfo])

  useEffect(() => {
    dispatch(getAllSpecialization())
  }, [dispatch])

  let click = false
  useEffect(() => {
    if (!error && click) {
      setElDisable(true)
    }
  }, [error, click])

  useEffect(() => {
    if (allSpecialization?.data.length > 0) {
      const firstSpecialization = allSpecialization.data.sort((a, b) => a.title > b.title ? 1 : -1)[0]
      setPrimarySpecialization(firstSpecialization.id)
    }
  }, [])

  let findId
  const submitHandler = async (e) => {
    e.preventDefault()

    /* if (singleSelections.length === 0) {
      setMessage(t('choise-secondary-cpecialization'));
    } else {
      findId = optionsKey.filter((x) => x.title === singleSelections[0]);
      secondary_specialization_id = findId[0].id;
    } */

    if (singleSelections.length > 0) {
      findId = optionsKey.filter((x) => x.title === singleSelections[0])
      secondary_specialization_id = findId[0]?.id
    }

    if (secondary_specialization_id && primary_specialization_id === secondary_specialization_id) {
      setMessage(t('not-be-same'))
    }

    if (password !== confirmPassword) {
      setMessage(t('password-not-match'))
    } else {
      // Dispatch
      specializations.push(primary_specialization_id)
      specializations.push(secondary_specialization_id)
      click = true
      dispatch(freelenceDoctorRegisterActions(email, phoneNumber, name, surname, password, primary_specialization_id, secondary_specialization_id, city, address, nr, accept_third_party))
    }
  }

  return (<div className="register_doctor">
    <div className="container-xxl">
      <div className="row">
        <div className="col-xxl-5 col-md-12 col-12 mx-auto">
          <div className="user-form-container">
            <div className="user-text">
              <div className="user-register-label register-freelance">
                <img src="assets/images/freelance-icon.svg" alt="Doctor"/>
                <span>{t('freelance-doctor')}</span>
              </div>
              <h2>{t('freelance-doctor-title')}</h2>
              <p>{t('clinic-doctor-message')}</p>
            </div>
            <div className="user-form">
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="emailAddress" className="form-label">
                      {t('email')} *
                    </label>
                    <input
                      type="email"
                      autoComplete="new_email"
                      required
                      className="form-control"
                      id="emailAddress"
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      {t('phone-number')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      placeholder={t('phone-number')}
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="firstName" className="form-label">
                      {t('name')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="firstName"
                      placeholder={t('name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="surname" className="form-label">
                      {t('surname')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="surname"
                      placeholder={t('surname')}
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="password" className="form-label">
                      {t('password')} <span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      autoComplete="new_password"
                      className="form-control"
                      required
                      id="password"
                      placeholder={t('password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="repeatPassword" className="form-label">
                      {t('repeat-password')}{' '}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      id="repeatPassword"
                      placeholder={t('repeat-password')}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="text" className="form-label">
                      {t('specialization')}{' '}
                      <span className="required">*</span>
                    </label>
                    <select
                      className="form-select"
                      required
                      aria-label="Default select example"
                      name="primary_specialization_id"
                      value={primary_specialization_id}
                      onChange={(e) => setPrimarySpecialization(e.target.value)}
                    >
                      {allSpecialization?.data && allSpecialization.data.sort((a, b) => a.title > b.title ? 1 : -1).map((spec) => (
                        <option key={spec.id} value={spec.id}>
                          {t(spec.title)}
                        </option>))}
                    </select>
                  </div>

                  <div className="col-sm-6 col-12 mb-3">
                    <label className="form-label">
                      {t('additional-specialization')}{' '}
                      {/*<span className='required'>*</span>*/}
                    </label>
                    <div className="tagin-wrapper">
                      {/* <!-- TODO:: Delete inline style after logic --> */}
                      {/* <input
                          type='text'
                          className='form-control'
                          placeholder='Type'
                          style={{ paddingLeft: '80px' }}
                        />
                        <span className='tagin-tag'>Orthopedic</span> */}

                      <Typeahead
                        required
                        id="basic-typeahead-single"
                        labelKey="id"
                        onChange={setSingleSelections}
                        options={options.map(option => t(option))}
                        placeholder="Type"
                        selected={singleSelections}
                      />
                      {(() => {
                        if (singleSelections.length) {
                          return (<span className="tagin-tag">
                                {singleSelections}
                              </span>)
                        }
                        return null
                      })()}
                    </div>
                  </div>
                </div>

                <div
                  className="row"
                  onChange={(e) => setAcceptThirdParty(e.target.value)}
                >
                  <label htmlFor="accept_third_party" className="form-label">
                    {t('accept-third-party')}
                  </label>
                  <div className="third_party_buttons">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        style={{
                          backgroundColor: '#79D2CC', display: 'inline',
                        }}
                        type="radio"
                        name="accept_third_party"
                        value="YES"
                        // onChange={(e) => setFirstVisit(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        style={{ color: '#A1A5A8' }}
                        htmlFor="accept_third_party"
                      >
                        {t('first-visit-answer-yes')}
                      </label>
                    </div>

                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        style={{ backgroundColor: '#79D2CC' }}
                        type="radio"
                        name="accept_third_party"
                        value="NO"
                        // onChange={(e) => setFirstVisit(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        style={{ color: '#A1A5A8' }}
                        htmlFor="accept_third_party"
                      >
                        {t('first-visit-answer-no')}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-12 mb-3">
                    <label htmlFor="address" className="form-label">
                      {t('address')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="address"
                      placeholder={t('address')}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 col-12 mb-3">
                    <label htmlFor="address" className="form-label">
                      {t('city')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id=""
                      placeholder={t('city')}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-3 col-12 mb-3">
                    <label htmlFor="nr" className="form-label">
                      {t('nr')} . <span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      required
                      className="form-control"
                      id="nr"
                      placeholder={t('nr')}
                      value={nr}
                      onChange={(e) => setNr(e.target.value)}
                    />
                  </div>
                  {message && <Message variant="danger">{message}</Message>}
                  {error && <Message variant="danger">{error}</Message>}
                </div>

                <div className="row">
                  <div className="col-sm-6 col-12 mb-3">
                    <label htmlFor="firstName" className="form-label">
                      {t('postal-code')} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder={t('postal-code')}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-nature"
                  disabled={loading || elDisable}
                >
                  {t('sign-up')}
                  <span>{loading && <Loader/>}</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default RegisterFreelanceDoctorScreen
