import axios from 'axios';
import {
  ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_FAIL,
  ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_REQUEST,
  ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_SUCCESS,
  ADMIN_LOGIN_DOCTOR_FAIL,
  ADMIN_LOGIN_DOCTOR_REQUEST,
  ADMIN_LOGIN_DOCTOR_SUCCESS,
  CREATE_CLINIC_FAIL,
  CREATE_CLINIC_REQUEST,
  CREATE_CLINIC_SUCCESS,
  GET_ALL_DOCTOR_FAIL,
  GET_ALL_DOCTOR_REQUEST,
  GET_ALL_DOCTOR_SUCCESS,
  GET_EARNINGS_DOCTOR_FAIL,
  GET_EARNINGS_DOCTOR_REQUEST,
  GET_EARNINGS_DOCTOR_SUCCESS,
} from '../constants/adminConstants';
import { USER_LOGOUT } from '../constants/userConstants';
import { getItemFromLocalStorage } from '../helpers/helpers';

export const getAdminAllDoctorsActions =
  (page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_DOCTOR_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}admin/all-doctors`,
        { page },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: GET_ALL_DOCTOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_DOCTOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAdminAllPaientsActions =
  (page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_DOCTOR_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}doctors/list-patients`,
        { page },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: GET_ALL_DOCTOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_DOCTOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createClinic = (clinicData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_CLINIC_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}clinics`,
      clinicData,
      config
    );

    dispatch({
      type: CREATE_CLINIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CLINIC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getAdminEarningsDoctorsActions =
  (doctor_id, page, year, month) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_EARNINGS_DOCTOR_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}admin/doctor-transactions`,
        { doctor_id, page, year, month },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: GET_EARNINGS_DOCTOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EARNINGS_DOCTOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const adminChangeProfileStatusDoctorActions =
  (doctor_id, status, usertype) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };
      status = Number(status);
      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}admin/change-doctor-status`,
        { doctor_id, status, usertype },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const adminLoginDoctorAction = (uid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_DOCTOR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}admin/generate-token`,
      { uid },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    // dispatch({
    //   type: USER_LOGOUT,
    //   payload: data,
    // });

    dispatch({
      type: ADMIN_LOGIN_DOCTOR_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_DOCTOR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
