import {
  PATIENT_ACCEPT_APOINTMENT_FAIL,
  PATIENT_ACCEPT_APOINTMENT_REQUEST,
  PATIENT_ACCEPT_APOINTMENT_SUCCESS,
  PATIENT_REFUSE_APOINTMENT_FAIL,
  PATIENT_REFUSE_APOINTMENT_REQUEST,
  PATIENT_REFUSE_APOINTMENT_SUCCESS,
  PATIENT_REGISTER_FAIL,
  PATIENT_REGISTER_REQUEST,
  PATIENT_REGISTER_SUCCESS,
  PATIENT_UPDATE_PROFILE_FAIL,
  PATIENT_UPDATE_PROFILE_REQUEST,
  PATIENT_UPDATE_PROFILE_SUCCESS,
} from '../constants/patientConstants';

export const patientRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_REGISTER_REQUEST:
      return { loading: true };
    case PATIENT_REGISTER_SUCCESS:
      return { loading: false, patientRegister: action.payload };
    case PATIENT_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const patientUdpateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case PATIENT_UPDATE_PROFILE_SUCCESS:
      return { loading: false, patientInfo: action.payload };
    case PATIENT_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const patientAcceptApointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_ACCEPT_APOINTMENT_REQUEST:
      return { loading: true };
    case PATIENT_ACCEPT_APOINTMENT_SUCCESS:
      return { loading: false, patientAcceptApointment: action.payload };
    case PATIENT_ACCEPT_APOINTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const patientRefuseApointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_REFUSE_APOINTMENT_REQUEST:
      return { loading: true };
    case PATIENT_REFUSE_APOINTMENT_SUCCESS:
      return { loading: false, patientRefuseApointment: action.payload };
    case PATIENT_REFUSE_APOINTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
