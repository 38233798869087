import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CreateIndividualAppointmentForm from './CreateIndividualAppointmentForm';
import CreateGroupAppointmentForm from './CreateGroupAppointmentForm';


const CreateAppointmentPopup = ({closePopup, onCreateAppointment}) => {

    const { t, i18n } = useTranslation();
    const [typeOfConsultation, setTypeOfConsultation] = useState('choose');

    const selectTypeOfConsultation = (type) => {
        setTypeOfConsultation(type);
    }

    const onClosePopup = () => {
        closePopup();
    }

    const getHeader = (type) => {
        if (type === 'choose') {
            return (
                <div className='create-appointment-popup-header-text'>{t("choose-type-of-consultation")}</div>
            );
        } else if (type === 'individual') {
            return (
                <div className='create-appointment-popup-header-text individual'>
                    <img src='assets/images/user.svg' alt='individual'/>
                    {t("individual-consultation")}
                </div>
            );
        } else if (type === 'group') {
            return (
                <div className='create-appointment-popup-header-text group'>
                    <img src='assets/images/group.svg' alt='group'/>
                    {t("group-consultation")}
                </div>
            );
        }
    }

    
    

    return (
        <>
        <div className='create-appointment-popup-background' onClick={() => onClosePopup()}></div>
        <div className='create-appointment-popup'>
            <div className='create-appointment-popup-header'>
                {getHeader(typeOfConsultation)}
                <a className='create-appointment-popup-header-close' onClick={() => onClosePopup()}><img src='/assets/images/cross.svg' alt='close'/></a>
            </div>
            <div className='create-appointment-popup-inner'>
            {typeOfConsultation === 'choose' && (
                <div className='container-xxl'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='box-popup-container'>
                                <a
                                className='box-popup'
                                onClick={() => selectTypeOfConsultation('individual')}
                                >
                                    <div className='box-popup-icon sunset'>
                                    <img
                                        src='assets/images/user.svg'
                                        alt='individual'
                                    />
                                    </div>
                                    <div className='box-popup-description'>
                                    <h4>{t('individual-consultation')}</h4>
                                    <p>{t('freelance-working-hour-message')}</p>
                                    </div>
                                </a>
                                <a
                                className='box-popup'
                                onClick={() => selectTypeOfConsultation('group')}
                                >
                                    <div href='#' className='box-popup-icon nature'>
                                    <img src='assets/images/group.svg' alt='group' />
                                    </div>
                                    <div className='box-popup-description'>
                                    <h4>{t('group-consultation')}</h4>
                                    <p>{t('group-session-message')}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                {typeOfConsultation === 'individual' && (
                    <CreateIndividualAppointmentForm onCreateAppointment={onCreateAppointment}/>
                )}
                {typeOfConsultation === 'group' && (
                    <CreateGroupAppointmentForm onCreateAppointment={onCreateAppointment}/>
                )}
            </div>
        </div>
        </>
    );
};

export default CreateAppointmentPopup;
