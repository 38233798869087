import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import jwt from 'jwt-decode';
import moment from 'moment';
import LoadingIndicator from '../components/LoadingIndicator';
import { getAdminAllDoctorsActions } from '../actions/adminActions';

const Doctors = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo } = useSelector(state => state.userLogin);
    const { loading, adminAllDoctors } = useSelector(state => state.adminAllDoctors);
    const { t } = useTranslation();

    useEffect(() => {
        if (!userInfo) {
            navigate('/login');
            return;
        }
        const token = jwt(userInfo.data);
        if (!['superadmin', 'clinic_admin'].includes(token.tokenParams.usertype)) {
            navigate('/unauthorized');
            return;
        }
        dispatch(getAdminAllDoctorsActions());
    }, [dispatch, navigate, userInfo]);


    console.log('USER INFO FROM DOCTOS SCREEN', userInfo)
    const loginDoctor = async (uid) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo?.data}`,
            },
        };
        await axios.post('/api/admin/generate-token', { uid }, config)
            .then((response) => {
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                navigate('/');
                window.location.reload();
            });
    };

    const handlePaginationChange = (url) => {
        if (!url) return;
        const page = new URL(url).searchParams.get('page');
        dispatch(getAdminAllDoctorsActions(page));
    };

    return (
        <div className='dashboard-container admin_pages_wrapper'>
            {loading && <LoadingIndicator />}
            <div className='container-xxl'>
                <h1 className='row row-flex justify-content-center'>{t('all-doctors')}</h1>
                {userInfo && ['clinic_admin'].includes(userInfo.usertype) && (
                    <div style={{ position: 'relative' }}>
                        <Link to="/add-new-doctor">
                            <button className="btn btn-flowers" style={{ position: 'absolute', right: '0', top: '-50px' }}>
                                + Add doctor
                            </button>
                        </Link>
                    </div>
                )}
                <div className='Rtable Rtable--9cols'>
                    <div className='Rtable-cell'><strong>{t('first-name')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('last-name')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('date-of-registration')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('profile-status')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('subscription-status')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('clinic-freelence')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('login-doctor')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('earnings')}</strong></div>
                    <div className='Rtable-cell'><strong>{t('view-profile')}</strong></div>
                    {adminAllDoctors?.data?.data.map(doctor => (
                        <React.Fragment key={doctor.id}>
                            <div className='Rtable-cell'>{doctor.name}</div>
                            <div className='Rtable-cell'>{doctor.surname}</div>
                            <div className='Rtable-cell'>{moment(doctor.created_at).format('DD-MM-YYYY hh:mm A')}</div>
                            <div className='Rtable-cell'>
                                {doctor.status === 0 ? 'Inactive' : doctor.status === 1 ? 'Active' : 'On Hold'}
                                <br />
                                <Link
                                    to={`/change-doctor-profile-status`}
                                    state={{
                                        doctorId: doctor?.id,
                                        status: doctor?.status,
                                        usertype: doctor?.usertype,
                                    }}>
                                    Change Status
                                </Link>
                            </div>
                            <div className='Rtable-cell'>{doctor.stripe_subscription_id ? 'Active' : 'Inactive'}</div>
                            <div className='Rtable-cell'>
                                {doctor.usertype}<br />
                                <Link
                                    to={`/change-doctor-usertype/${doctor.id}`}
                                    state={{
                                        doctorId: doctor?.id,
                                        status: doctor?.status,
                                        usertype: doctor?.usertype,
                                    }}>
                                    Change Usertype
                                </Link>
                            </div>
                            <div className='Rtable-cell'>
                                <button onClick={() => loginDoctor(doctor.id)}>{t('login-doctor')}</button>
                            </div>
                            <div className='Rtable-cell'>
                                <Link to={`/see-doctor-earnings/${doctor.id}`}>{t('earnings')}</Link>
                            </div>
                            <div className='Rtable-cell'>
                                <Link to={`/see-doctor-profile/${doctor.id}`}>{t('view-profile')}</Link>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <Pagination className='justify-content-center mb-4 my-5'>
                    {adminAllDoctors?.data && <>
                        <Pagination.First onClick={() => handlePaginationChange(adminAllDoctors.data.first_page_url)} disabled={!adminAllDoctors.data.prev_page_url} />
                        <Pagination.Prev onClick={() => handlePaginationChange(adminAllDoctors.data.prev_page_url)} disabled={!adminAllDoctors.data.prev_page_url} />
                        <Pagination.Item active>{adminAllDoctors.data.current_page}</Pagination.Item>
                        <Pagination.Next onClick={() => handlePaginationChange(adminAllDoctors.data.next_page_url)} disabled={!adminAllDoctors.data.next_page_url} />
                        <Pagination.Last onClick={() => handlePaginationChange(adminAllDoctors.data.last_page_url)} disabled={!adminAllDoctors.data.next_page_url} />
                    </>}
                </Pagination>
            </div>
        </div>
    );
};

export default Doctors;
