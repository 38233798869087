import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAdminEarningsDoctorsActions } from '../../actions/adminActions';
import LoadingIndicator from '../../components/LoadingIndicator';
import PatientCard from '../../components/PatientCard';
import { ExportToExcel } from '../../components/ExportToExcel';
import moment from 'moment';

const SeeDoctorEarningsScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const doctor_id = location?.state?.doctorId;

  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [year, setYear] = useState('2023');
  const [month, setMonth] = useState('');
  const { t, i18n } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);

  // Get list of all doctors
  const adminEarningsDoctorData = useSelector(
    (state) => state.adminEarningsDoctor
  );
  const { loading, error, adminEarningsDoctor } = adminEarningsDoctorData;

  useEffect(() => {
    dispatch(getAdminEarningsDoctorsActions(doctor_id));
  }, [dispatch, doctor_id]);

  console.log('adminEarningsDoctor ', adminEarningsDoctor);

  const initalPrice = 0;
  useEffect(() => {
    if (adminEarningsDoctor?.data) {
      const payedPrice = adminEarningsDoctor?.data?.filter(
        (el) => el.appointment.payment_status === 1
      );
      const sumPrice = payedPrice.reduce(
        (previousValue, currentValue) =>
          previousValue + Number(currentValue.amount),
        initalPrice
      );
      setTotalPrice(sumPrice);
    }
  }, [adminEarningsDoctor]);

  let exportList = [];

  // Pagination
  useEffect(() => {
    if (adminEarningsDoctor?.data) {
      setFirstPage('1');
      setPreviousPage(adminEarningsDoctor?.data?.prev_page_url);
      setLastPage(adminEarningsDoctor?.data?.last_page);
      setNextPage(adminEarningsDoctor?.data?.next_page_url);
      setCurrentPage(adminEarningsDoctor?.data?.current_page);
      adminEarningsDoctor?.data.forEach((element) => {
        let ex = {
          patientName: element?.appointment?.patient?.name,
          patientSurname: element?.appointment?.patient?.surname,
          ammount: element?.appointment?.payment_price,
          currency: element?.currency,
          createdAt: moment(new Date(element?.created_at)).format(
            'DD-MM-YYYY hh:mm A'
          ),
        };
        exportList.push(ex);
      });
    }
  }, [adminEarningsDoctor, exportList]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    dispatch(getAdminEarningsDoctorsActions(doctor_id, page, year, month));
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    dispatch(getAdminEarningsDoctorsActions(doctor_id, page, year, month));
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    dispatch(getAdminEarningsDoctorsActions(doctor_id, page, year, month));
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    dispatch(getAdminEarningsDoctorsActions(doctor_id, page, year, month));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let page = 1;
    setPage(page);
    dispatch(getAdminEarningsDoctorsActions(doctor_id, page, year, month));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fileName = `${doctor_id}.xlsx`;

  // console.log('adminEarningsDoctor', exportList);
  return (
    <div className='container-xxl'>
      {loading && <LoadingIndicator />}
      <div className='container-xxl page-heading'>
        <div className='row'>
          <div className='col-sm-4 col-12'>
            <Link to='/see-all-doctors' className='back-to'>
              {t('back-to-list')}
            </Link>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-xxl-6 col-md-12 col-12 mx-auto'>
          <div className='user-view'>
            <div className='row'>
              <form onSubmit={submitHandler}>
                <div className='col-sm-6 col-12 mb-3'>
                  {' '}
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    name='year'
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value={''}>{t('select-year')}</option>
                    <option value={'2022'}>2022</option>
                    <option value={'2023'}>2023</option>
                  </select>
                </div>
                <div className='col-sm-6 col-12 mb-3'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    name='month'
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  >
                    <option value={''}>{t('select-month')}</option>
                    <option value={'01'}>{t('January')}</option>
                    <option value={'02'}>{t('February')}</option>
                    <option value={'03'}>{t('March')}</option>
                    <option value={'04'}>{t('April')}</option>
                    <option value={'05'}>{t('May')}</option>
                    <option value={'06'}>{t('June')}</option>
                    <option value={'07'}>{t('July')}</option>
                    <option value={'08'}>{t('August')}</option>
                    <option value={'09'}>{t('September')}</option>
                    <option value={'10'}>{t('October')}</option>
                    <option value={'11'}>{t('November')}</option>
                    <option value={'12'}>{t('December')}</option>
                  </select>
                </div>
                <button type='submit' className='btn btn-nature'>
                  Submit
                </button>
              </form>
            </div>
            <br />
            <div className='row'>
              {adminEarningsDoctor?.data &&
                adminEarningsDoctor?.data.map((element) => {
                  return (
                    <div
                      key={element.id}
                      className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
                    >
                      <PatientCard data={element} />
                    </div>
                  );
                })}
            </div>
          </div>

          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='price-total'>
                <div className='price-value'>
                  <span>
                    {t('tot')} ({t('tax-incl')})
                  </span>
                  <span>${totalPrice} €</span>
                </div>
                <div className='price-value'>
                  <span>{t('payout-date')}</span>
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <ExportToExcel apiData={exportList} fileName={fileName} />
          </div>
        </div>
      </div>

      <div className='row'>
        {/* Pagination */}
        <Pagination className='justify-content-center mb-4 my-5'>
          <Pagination.First onClick={paginationFirstHandler} />
          <Pagination.Prev onClick={paginationPrevHandler} />
          {/* <Pagination.Ellipsis /> */}
          <Pagination.Item disabled>{currentPage}</Pagination.Item>
          <Pagination.Next onClick={paginationNextHandler} />
          <Pagination.Last onClick={paginationLastHandler} />
        </Pagination>
      </div>
    </div>
  );
};

export default SeeDoctorEarningsScreen;
