import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import {
  doctorGetProfileActions,
  freelenceDoctorCreatePriceActions,
} from '../../actions/doctorActions';
import { appRoutes } from '../../helpers/constants';
import { Alert } from 'react-bootstrap';

const FreelenceDoctorPriceCreateScreen = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [specialization_id, setSpecializationId] = useState('');
  const [price, setPrice] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState(null);
  const [elDisable, setElDisable] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let history = useNavigate();
  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }
  useEffect(() => {
    if (!token) {
      history('/login');
    }
  }, [history, token]);

  // GET Doctor profile
  const doctorProfileData = useSelector((state) => state.doctorProfile);
  const { doctorProfile } = doctorProfileData;

  useEffect(() => {
    dispatch(doctorGetProfileActions());
  }, [dispatch]);

  // console.log(doctorProfile);

  let specialization = [];
  if (doctorProfile) {
    specialization.push(
      doctorProfile?.data?.doctor_profile.primary_specialization
    );
    specialization.push(
      doctorProfile?.data?.doctor_profile.secondary_specialization
    );
  }

  const freelenceDoctorCreatePriceData = useSelector(
    (state) => state.freelenceDoctorCreatePrice
  );
  const { loading, error, freelenceDoctorCreatePrice } =
    freelenceDoctorCreatePriceData;

  useEffect(() => {
    if (freelenceDoctorCreatePrice?.status_code === 200) {
      setMessage(freelenceDoctorCreatePrice.data);
    }
  }, [freelenceDoctorCreatePrice]);

  let click = false;
  useEffect(() => {
    if (!error && click) {
      setElDisable(true);
    }
  }, [error, click]);

  const submitHandler = (e) => {
    e.preventDefault();
    click = true;
    dispatch(
      freelenceDoctorCreatePriceActions(specialization_id, title, Number(price))
    );
    setSpecializationId('');
    setTitle('');
    setPrice('');
  };

  return (
    <>
      <div className='doctor_price_create'>
        <div className='container-fluid page-heading'>
          <div className='row'>
            <div className='col-sm-4 col-12'>
              <Link className='back-to' to='/doctor-setings'>
                {t('back-to-settings')}
              </Link>
            </div>
            <div className='col-sm-4 col-12 text-center'>
              <div className='user-register-label nature'>
                <img src='/assets/images/money.svg' alt='money' />
                <span>{t('your-prices')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-5 col-md-12 col-12 mx-auto'>
              <div className='user-form-container'>
                <div className='user-text'>
                  <p>{t('your-prices-message')}.</p>
                </div>

                <div className='user-form user-form-prices'>
                  <form onSubmit={submitHandler}>
                    <div className='col-sm-6 col-12 mb-3 w-100'>
                      <div className='row'>
                        <div className='col-sm-12 col-12 mb-3'>
                          <label htmlFor='' className='form-label form-label-w'>
                            {t('specialization')}{' '}
                          </label>
                          <select
                            className='form-select'
                            aria-label='Visit title'
                            value={specialization_id}
                            onChange={(e) => {
                              setSpecializationId(e.target.value);
                            }}
                          >
                            <option>
                              {t('select-specialization-for-visit')}
                            </option>
                            {specialization &&
                              specialization.map((sp) => {
                                
                                  return (
                                    <option key={sp?.id} value={sp?.id}>
                                      {t(sp?.title)}
                                    </option>
                                  );
                                
                              })}
                          </select>
                        </div>
                      </div>
                      <div
                        className='d-flex flex-row justify-content-between mt-2'
                        style={{ gap: '30px' }}
                      >
                        <div className='row w-100'>
                          <div className=''>
                            <label htmlFor='title' className='form-label'>
                              {t('title-for-visit')}
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='title'
                              placeholder={t('title-for-visit')}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='row w-100'>
                          <div className=''>
                            <label htmlFor='price' className='form-label'>
                              {t('price-for-visit')}
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              required
                              id='price'
                              placeholder={t('price-for-visit')}
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      {message && !error && (
                        <Message variant='success'>{message}</Message>
                      )}

                      {error && <Message variant='danger'>{error}</Message>}
                    </div>
                    <div
                      className='d-flex flex-row justify-content-between'
                      style={{ gap: '10px' }}
                    >
                      <button
                        type='submit'
                        className='btn btn-darkblue'
                        disabled={loading || elDisable}
                      >
                        {t('save-edits')} <span>{loading && <Loader />}</span>
                      </button>
                      <Link
                        className='btn btn-darkblue'
                        to={appRoutes.DOCTOR.UPCOMING_PRICES}
                      >
                        <div>{t('view-previous-price')}</div>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreelenceDoctorPriceCreateScreen;
