import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const AboutUsScreen = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className='legal_page'>
        <div className='legal_wrapper'>
          <div className='container-xxl'>
            <h2 className=''>{t('about-us')}</h2>
            <div className='content_wrapper'>
                  <p className='normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim congue diam non leo, aliquet duis mi sagittis in. Id fames nunc bibendum vivamus imperdiet. Volutpat pellentesque fermentum lorem dui facilisis lobortis nulla faucibus amet. Pharetra amet, eu, fermentum, imperdiet ac. Nunc, malesuada ullamcorper ullamcorper cras mattis tortor vivamus pretium. Viverra posuere consequat ultrices turpis volutpat diam. Risus mauris neque quam auctor suspendisse. Maecenas imperdiet eu, eget vitae nunc. Iaculis cras eleifend at turpis diam, sed. Lorem rhoncus, elit nisi, nec. Egestas sapien leo a urna neque pulvinar morbi. Tristique massa est varius turpis vestibulum.</p>
                  <p className='normal'>Magna arcu sagittis viverra in vulputate. Eget facilisi praesent tortor aliquam risus adipiscing a. Pretium vehicula pellentesque sagittis purus rhoncus. In consequat convallis congue tortor ut faucibus. Auctor sagittis urna enim a nibh. Fusce maecenas morbi massa, ornare ut pretium facilisis. Arcu bibendum sit erat risus sit semper a ut.</p>
                  <p className='normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim congue diam non leo, aliquet duis mi sagittis in. Id fames nunc bibendum vivamus imperdiet. Volutpat pellentesque fermentum lorem dui facilisis lobortis nulla faucibus amet. Pharetra amet, eu, fermentum, imperdiet ac. Nunc, malesuada ullamcorper ullamcorper cras mattis tortor vivamus pretium. Viverra posuere consequat ultrices turpis volutpat diam. Risus mauris neque quam auctor suspendisse.</p>
                  <p className='normal'>Maecenas imperdiet eu, eget vitae nunc. Iaculis cras eleifend at turpis diam, sed. Lorem rhoncus, elit nisi, nec. Egestas sapien leo a urna neque pulvinar morbi. Tristique massa est varius turpis vestibulum.</p>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default AboutUsScreen;
