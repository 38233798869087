import axios from 'axios';
import {
  GET_CLINIC_LIST_REQUEST,
  GET_CLINIC_LIST_SUCCESS,
  GET_CLINIC_LIST_FAIL,
} from '../constants/clinicConstants';

export const getClinics =
  (page = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_CLINIC_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          Accept: 'application/json',
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_PRODUCTION_API}clinics?page=${page}`,
        config
      );
      console.log('Clinic Data:', data);
      dispatch({
        type: GET_CLINIC_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLINIC_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addClinicDoctor =
  (
    email,
    phoneNumber,
    name,
    surname,
    password,
    primary_specialization_id,
    secondary_specialization_id
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: 'CLINIC_CREATE_DOCTOR_REQUEST' });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          Accept: 'application/json',
        },
      };

      await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}clinics/add-doctor`,
        {
          email,
          password,
          name,
          surname,
          phone_number: phoneNumber,
          // usertype: 'clinic_doctor',
          city: 'Amsterdam',
          street: 'Jan Hanzenstraat',
          house_number: '32',
          primary_specialization_id,
          secondary_specialization_id,
          plan: '6c43895b-a2dd-49e0-8655-e516edccba18',
        },
        config
      );

      dispatch({
        type: 'CLINIC_CREATE_DOCTOR_SUCCESS',
      });
    } catch (error) {
      dispatch({
        type: 'CLINIC_CREATE_DOCTOR_FAIL',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
