import React, { useCallback, useEffect, useState } from "react";
import jwt from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { currentMonth, socketToken, socketURL, SOCKET_ROUTES } from "../helpers/constants";
import axios from "axios";
import io from "socket.io-client";
import moment from "moment-timezone";
import Calendar from "../components/Calendar/Calendar";
import CalendarHeader from "../components/Calendar/CalendarHeader";
import { getItemFromLocalStorage } from "../helpers/helpers";

const socket = io(socketURL, {
    transports: ["websocket"],
    query: { token: socketToken },
});

const UnifiedCalendarScreen = () => {
    const { t } = useTranslation();
    const [calendarDay, setCalendarDay] = useState(currentMonth);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const token = jwt(userInfo.data);

    const fetchUpcomingEvents = useCallback(async () => {
        const config = {
            headers: {
                "Content-type": "application/json",
                "x-localization": getItemFromLocalStorage("i18nextLng") || "en",
                Authorization: `Bearer ${userInfo.data}`,
            },
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_PRODUCTION_API}users/upcoming-events`, { month: calendarDay + 1 }, config);

            let allEvents = {
                appointments: [],
                termins: []
            };

            if (response.data.data.appointments && Array.isArray(response.data.data.appointments)) {
                allEvents.appointments = response.data.data.appointments.map(appointment => ({
                    ...appointment,
                    start_date: moment.utc(appointment.start_date).tz(moment.tz.guess()).format(),
                    end_date: moment.utc(appointment.end_date).tz(moment.tz.guess()).format()
                }));
            }

            if (response.data.data.termins) {
                console.log('all events . termins', response.data.data.termins)
                allEvents.termins = response.data.data.termins.map(termin => ({
                    ...termin,
                    start_date: moment.utc(termin.start_date).tz(moment.tz.guess()).format(),
                    end_date: moment.utc(termin.end_date).tz(moment.tz.guess()).format()
                }));
            }

            console.log('ALL EVENTS', allEvents)

            setUpcomingEvents(allEvents);
        } catch (error) {
            console.error('Error fetching upcoming events:', error);
        }
    }, [calendarDay, userInfo.data]);



    useEffect(() => {
        socket.connect();
        socket.emit(SOCKET_ROUTES.JOIN, token.tokenParams.id);
        socket.on(SOCKET_ROUTES.NOTIFICATIONS, fetchUpcomingEvents);
        return () => {
            socket.disconnect();
        };
    }, [token.tokenParams.id, fetchUpcomingEvents]);

    useEffect(() => {
        fetchUpcomingEvents();
    }, [fetchUpcomingEvents, calendarDay]);

    if (!userInfo) {
        return <div>Please log in to view this page.</div>;
    }

    return (
        <section className="calendar-container calendar_page">
            <CalendarHeader />
            <div className="calendar_wrapper container-xxl">
                <Calendar
                    formatCalendarFromMonth={calendarDay}
                    calendarInfo={upcomingEvents}
                    onChangeMonth={setCalendarDay}
                    onCreateAppointment={() => fetchUpcomingEvents()}
                    onEventAction={fetchUpcomingEvents}
                    isDoctor={['freelance_doctor', 'clinic_doctor', 'clinic_admin'].includes(token.tokenParams.usertype)}
                />
            </div>
        </section>
    );
};

export default UnifiedCalendarScreen;
