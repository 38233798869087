import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import {
  currentMonth,
  todayDay,
  newDate,
  appRoutes,
} from '../../helpers/constants';
import { minTwoDigits } from '../../helpers/helpers';
import useClickOutside from '../../hooks/useClickOutside';
import SmallCalendar from '../Calendar/SmallCalendar';
import { Link } from 'react-router-dom';

const HealthOverviewHeader = ({ selectedDate }) => {
  const format = 'YYYY-MM-DD';
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentDate, setCurrentDate] = useState(newDate);

  const calendarRef = useRef();
  const { t, i18n } = useTranslation();

  useClickOutside(calendarRef, () => {
    if (showCalendar) {
      setShowCalendar(false);
    }
  });

  const onDatePick = () => {
    setShowCalendar(true);
  };

  const onSelectDay = (year, month, date) => {
    setShowCalendar(false);
    const formatedSelectedDay = `${year}-${minTwoDigits(month + 1)}-${date}`;
    if (formatedSelectedDay == moment(newDate).format(format)) {
      selectedDate(formatedSelectedDay, true);
    } else {
      selectedDate(formatedSelectedDay, false);
    }
    setCurrentDate(formatedSelectedDay);
  };

  return (
    <>
      <div className='row mt-4'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
          <Link to={'/dashboard'} className='back-to'>
              {t('back-to-dashboard')}
          </Link>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
          <div className='user-register-label chart'>
            <img src='assets/images/chart.svg' />
            <span>{t('health-overview')}</span>
          </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-end hidden-mobile'>
          <div className='health-select-date'>
            <div style={{ position: 'relative' }} className='select-date'>
              <span>{t('select-date')}: </span>
              <a onClick={onDatePick} className='change-date'>
                {moment(currentDate).format('DD ')}
                {t(moment(currentDate).format('MMMM'))} 
                {moment(currentDate).format(' YYYY')}
              </a>
              <div
                id='small-calendar'
                ref={calendarRef}
                style={{
                  position: 'absolute',
                  right: 50,
                  top: 50,
                  display: showCalendar ? 'block' : 'none',
                }}
              >
                <SmallCalendar
                  onSelectDay={onSelectDay}
                  activeDay={minTwoDigits(todayDay)}
                  formatCalendarFromMonth={currentMonth}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthOverviewHeader;
