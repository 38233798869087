import { Link } from 'react-router-dom';
import { appRoutes } from '../../helpers/constants';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getItemFromLocalStorage } from '../../helpers/helpers';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Header = ({ redirectURL, redirectText, mainText, icon, classHeader }) => {
  return (
    <div className='container-fluid page-heading'>
      <div className='row'>
        <div className='col-sm-4 col-12'>
          <Link to={redirectURL} className='back-to'>
            {redirectText}
          </Link>
        </div>
        <div className='col-sm-4 col-12 text-center'>
          <div className={`user-register-label ${classHeader}`}>
            <img src={`/assets/images/${icon}`} alt='calendar' />
            <span>{mainText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({
  first_paragraph,
  second_paragraph,
  third_paragraph,
  classHeader,
  icon,
  id,
  onDelete,
}) => {
  return (
    <div
      id={id}
      style={{
        background: 'white',
        padding: '13px 20px',
        borderRadius: '10px',
        position: 'relative',
        marginBottom: '20px',
      }}
    >
      <div className='d-flex flex-row align-items-center'>
        <div
          className={`user-register-label ${classHeader} mb-0`}
          style={{ padding: '15px', marginRight: '20px' }}
        >
          <img
            style={{ maxHeight: '100%', width: '40px' }}
            src={`/assets/images/${icon}`}
            alt='calendar'
          />
        </div>
        <div className='sort-by'>
          <ul className='d-flex flex-column align-items-start'>
            <li>{first_paragraph}</li>
            <li>{second_paragraph}</li>
            <li>{third_paragraph}</li>
          </ul>
        </div>
      </div>
      <img
        onClick={() => onDelete(id)}
        style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          cursor: 'pointer',
        }}
        src='/assets/images/trash.svg'
        alt='Trash'
      />
    </div>
  );
};

const DoctorUpcomingTerminsOrPricesScreen = () => {
  const [data, setData] = useState([]);

  const [showMore, setShowMore] = useState(true)

  const [offset, setOffset] = useState(1)

  const limit = 10

  const [selectedID, setSelectedID] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const url = useLocation();

  let currentPage = '';
  const { t, i18n } = useTranslation();
  const headerInfo = {
    termins: {
      redirectURL: appRoutes.DOCTOR.CREATE_TERMIN,
      redirectText: t('back-to-working-hour'),
      mainText: t('working-hours'),
      icon: 'calendar-workingHours.svg',
      classHeader: 'ocean',
      title: t('scheduled-working-hour'),
      GET: `${process.env.REACT_APP_PRODUCTION_API}doctors/list-termins`,
      DELETE: `${process.env.REACT_APP_PRODUCTION_API}doctors/delete-termin`,
      card: {
        first_paragraph: t('visit-standard-time'),
        second_paragraph: t('start-time'),
        third_paragraph: t('hours-availability'),
      },
    },
    prices: {
      redirectURL: appRoutes.DOCTOR.CREATE_PRICE,
      redirectText: t('back-to-yours-prices'),
      mainText: t('your-prices'),
      icon: 'money.svg',
      classHeader: 'nature',
      title: t('current-price'),
      GET: `${process.env.REACT_APP_PRODUCTION_API}doctors/list-specialization-prices`,
      DELETE: `${process.env.REACT_APP_PRODUCTION_API}doctors/delete-specialization-price`,
      card: {
        first_paragraph: t('specialisation'),
        second_paragraph: t('title-visit'),
        third_paragraph: t('price-visit'),
      },
    },
  };

  if (url.pathname === appRoutes.DOCTOR.UPCOMING_TERMINS) {
    currentPage = 'termins';
  } else {
    currentPage = 'prices';
  }

  const getData = async () => {
    await axios
      .post(headerInfo[currentPage].GET, {
        perPage: limit
      }, config)
      .then((response) => {
        if(response.data.data.current_page === response.data.data.last_page) {
          setShowMore(false)
        }
        setData(response.data.data);
      })
      .catch(() => {
        console.log('SOME ERROR APPEAR HERE...');
      });
  };

  const onLoadMore = async () => {
    let prevData = [...data]
    await axios
      .post(headerInfo[currentPage].GET, {
        perPage: limit,
        page: offset + 1
      }, config)
      .then((response) => {
        setOffset((offset) => offset + 1)
        if(response.data.data.current_page === response.data.data.last_page) {
          setShowMore(false)
        }
        prevData = prevData.concat(response.data.data)
        setData(prevData);
      })
      .catch(() => {
        console.log('SOME ERROR APPEAR HERE...');
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const cardInfo = headerInfo[currentPage].card;

  const checkRender = (data) => {
    if (currentPage === 'prices') {
      return {
        first_paragraph:
          cardInfo.first_paragraph + ' ' + data.specialization.title,
        second_paragraph: cardInfo.second_paragraph + ' ' + data.title,
        third_paragraph: cardInfo.third_paragraph + ' ' + data.price + '$',
      };
    } else {
      const endTermin = new Date(data.end_date);
      const startTermin = new Date(data.start_date);
      const timeDifference = endTermin - startTermin;
      return {
        first_paragraph:
          cardInfo.first_paragraph +
          ' ' +
          new Date(timeDifference).getMinutes() +
          ' mins',
        second_paragraph:
          cardInfo.second_paragraph +
          ' ' +
          moment(startTermin).format('DD/MM/YYYY hh:mm A'),
        third_paragraph:
          cardInfo.third_paragraph +
          ' ' +
          moment(endTermin).format('DD/MM/YYYY hh:mm A'),
      };
    }
  };

  const deleteMethod = async () => {
    await axios
      .delete(headerInfo[currentPage].DELETE + `/${selectedID}`, config)
      .then((response) => {
        setOpenModal(false);
        document.getElementById(selectedID).remove();
      })
      .catch((error) => {
        console.log('SOME ERROR APPEAR HERE');
      });
  };

  const onDeleteCard = async (ID) => {
    setSelectedID(ID);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setSelectedID(null);
    setOpenModal(false);
  };

  const ConfirmModal = () => {
    return (
      <div
        className='modal modal-about fade show'
        tabIndex='-1'
        style={{ display: 'block' }}
        aria-modal='true'
        role='dialog'
      >
        <div className='modal-dialog modal-xl modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <span
                onClick={onCloseModal}
                type='button'
                className={'btn-close'}
                data-bs-dismiss='modal'
                aria-label='Close'
              ></span>
            </div>
            <div className='modal-body'>
              <div className='text-center confirm-content'>
                <h3>{t('price-delete-msg')}</h3>
                <div className='p-4'>
                  <button
                    onClick={onCloseModal}
                    className='btn btn-sunset'
                    style={{ marginRight: '20px' }}
                  >
                    {t('cancel')}
                  </button>
                  <button onClick={deleteMethod} className='btn btn-darkblue'>
                    {t('first-visit-answer-yes')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {openModal && <ConfirmModal />}
      <Header
        redirectURL={headerInfo[currentPage].redirectURL}
        redirectText={headerInfo[currentPage].redirectText}
        mainText={headerInfo[currentPage].mainText}
        icon={headerInfo[currentPage].icon}
        classHeader={headerInfo[currentPage].classHeader}
      />
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-xxl-6 col-md-6 col-12 mx-auto'>
            <h3 style={{ fontWeight: '400' }}>
              {headerInfo[currentPage].title}
            </h3>
            {data &&
              data.map((element) => (
                <Card
                  key={element.id}
                  onDelete={onDeleteCard}
                  id={element.id}
                  icon={headerInfo[currentPage].icon}
                  classHeader={headerInfo[currentPage].classHeader}
                  first_paragraph={checkRender(element).first_paragraph}
                  second_paragraph={checkRender(element).second_paragraph}
                  third_paragraph={checkRender(element).third_paragraph}
                />
              ))}
              {showMore && data.length > 0 && <button  className='btn btn-ocean' onClick={onLoadMore}>{t('load-more')}</button>}
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorUpcomingTerminsOrPricesScreen;
