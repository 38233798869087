import ChatHeader from '../components/Chat/ChatHeader';
import ChatGroupItem from '../components/Chat/ChatGroupItem';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import ChatCustomer from '../components/Chat/ChatCustomer';
import ChatDoctor from '../components/Chat/ChatDoctor';
import ChatInput from '../components/Chat/ChatInput';
import axios from 'axios';
import {
  API_ROUTES,
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  socketToken,
  socketURL,
  SOCKET_ROUTES,
} from '../helpers/constants';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../components/LoadingIndicator';
import io from 'socket.io-client';
import { getItemFromLocalStorage } from '../helpers/helpers';

const socket = io(socketURL, {
  transports: [ "websocket" ],
  query: { token: socketToken },
});

const ChatScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(1);

  const [loadMore, setLoadMore] = useState(true);

  const [socketID, setSocketID] = useState(null);

  const [messages, setMessages] = useState(null);

  const [groupID, setGroupID] = useState(null);

  const [groupPicture, setGroupPicture] = useState(null);

  const [groupPeople, setGroupPeople] = useState([]);
  const [loading, setLoading] = useState(false);

  const scrollRef = useRef(null);

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  let token;
  if (userInfo) {
    token = jwt(userInfo?.data);
  }

  const getGroups = async () => {
    setLoading(true);
    await axios
      .post(API_ROUTES.PATIENT.GET_DOCTORS_FOR_CHAT, null, config)
      .then((response) => {
        setLoading(false);
        const sortedDescending = response.data.data.sort((a,b) => b.count_is_read_messages - a.count_is_read_messages);
        setGroupPeople(sortedDescending);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getGroups();
    socket.on(SOCKET_ROUTES.CHAT, (data) => {
      if (data) {
        setMessages((prevMessage) => [...prevMessage, data]);
      }
    });
  }, []);

  // useEffect(() => {
  //   const domNode = scrollRef.current;
  //   if (domNode) {
  //     domNode.scrollTop = domNode.scrollHeight;
  //   }
  // });

  const onScrollEnd = (event) => {
    let element = event.target;
    if (element.scrollTop === 0 && loadMore) {
      getMessages(groupID, page + 1);
      setPage((page) => page + 1);
    }
  };

  const getMessages = async (ID, pageNumber) => {
    setLoading(true);
    let prevMessage = [];

    if (messages && messages.length >= 0) {
      prevMessage = [...messages];
    }

    await axios
      .post(
        API_ROUTES.GET_MESSAGES,
        {
          page: pageNumber,
          user_to_id: ID,
        },
        config
      )
      .then((response) => {
        if (response.data.data.last_page == response.data.data.current_page) {
          setLoadMore(false);
        }
        setLoading(false);
        prevMessage = response.data.data.data.concat(prevMessage);
        prevMessage = prevMessage.sort(function (a, b) {
          var c = new Date(a.created_at);
          var d = new Date(b.created_at);
          return c - d;
        });
        setMessages(prevMessage);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (groupID) {
      setPage(1);
      setMessages(getMessages(groupID, 1));
    }
  }, [groupID]);

  const onGroupItemClick = (element, index) => {
    setMessages([]);

    const prevGroupState = [...groupPeople];

    prevGroupState[index].count_is_read_messages = 0;

    setGroupPeople(prevGroupState);

    if (element?.doctor) {
      setGroupPicture(element?.doctor?.profile_picture);
    }

    if (element?.patient) {
      setGroupPicture(element?.patient?.profile_picture);
    }

    setGroupID(element.doctor_id ? element.doctor_id : element.patient_id);

    let uniqueSocketID = '';

    if (!element.doctor_id) {
      uniqueSocketID = token?.tokenParams?.id + element.patient_id;
    }

    if (element.doctor_id) {
      uniqueSocketID = element?.doctor_id + token?.tokenParams.id;
    }

    setSocketID(uniqueSocketID);

    socket.connect();
    socket.emit(SOCKET_ROUTES.JOIN, uniqueSocketID);
    socket.disconnect();
  };

  const onSend = async (message) => {
    socket.emit(SOCKET_ROUTES.CHAT, socketID, {
      user_from_id: token?.tokenParams?.id,
      message,
    });

    await axios
      .post(
        API_ROUTES.CREATE_MESSAGE,
        {
          user_to_id: groupID,
          message,
        },
        config
      )
      .then(() => {
        socket.emit(SOCKET_ROUTES.NOTIFICATIONS, groupID, {
          message: NOTIFICATIONS_TEXT.RECIVE_MESSAGE,
          type: NOTIFICATIONS_TYPES.ALERT,
        });
      });
  };

  const profilePicture = groupPicture
    ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${groupPicture}`
    : '/assets/images/doctors/doctor.jpg';

  return (
    <>
      {loading && <LoadingIndicator />}
      <div className='chat_page'>
        <ChatHeader />
        <div className='container-xxl'>
          <div className='chat-container'>
            <div className='group-chat-image'>
              {groupPeople.map((element, index) => {
                return (
                  <ChatGroupItem
                    active={
                      element.doctor_id
                        ? groupID == element.doctor_id
                        : groupID == element.patient_id
                    }
                    onGroupItemClick={() => onGroupItemClick(element, index)}
                    key={index}
                    data={element}
                  />
                );
              })}
            </div>
            {messages && messages.length >= 0 ? (
              <div className='group-chat-content'>
                <div style={{ marginBottom: '70px' }}>
                  <img
                    style={{ marginRight: '28px' }}
                    className='chat-item_image group-chat-content_img'
                    src={profilePicture}
                    alt='Name'
                    title='Name'
                  />
                </div>
                <div
                  onScroll={onScrollEnd}
                  ref={scrollRef}
                  className='w-100 group-content'
                >
                  {messages.length == 0 && <p>{t('no-message-currently')}..</p>}
                  {messages &&
                    messages.map((element, index) => {
                      return (
                        <div key={index}>
                          {element.user_from_id != token?.tokenParams?.id ? (
                            <ChatCustomer data={element} />
                          ) : (
                            <div className='chat-doctor-container'>
                              <ChatDoctor data={element} />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  <ChatInput onSend={onSend} />
                </div>
              </div>
            ) : (
              <div className='group-chat-content'>
                <p>{t('select-message-from-left-side')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatScreen;
