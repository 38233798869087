import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { resetForgotenPassword } from '../actions/userActions';

const ResetPasswordScreen = () => {
  const [password, setPassword] = useState('');

  const { t, i18n } = useTranslation();
  let [params] = useSearchParams();
  let token = params.get('token');
  let id = params.get('id');

  const dispatch = useDispatch();
  const userResetForgotenPassword = useSelector(
    (state) => state.userResetForgotenPassword
  );
  const { loading, error, userInfo } = userResetForgotenPassword;

  let message = userInfo?.data;
  const submitHandler = async (e) => {
    e.preventDefault();
    // Dispatch Reset Forgoten Password
    dispatch(resetForgotenPassword(token, id, password));
  };

  return (
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-xxl-4 col-md-12 col-12 mx-auto'>
          <div className='user-form-container'>
            <div className='user-form'>
              <form onSubmit={submitHandler}>
                <h3>{t('enter-new-password')}</h3>
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}
                {message && (
                  <Message variant='success'>
                    {message} {t('go-to-login')}{' '}
                    <Link to='/login'>{t('sign-in')}</Link>
                  </Message>
                )}
                <div className='mb-3'>
                  <label htmlFor='password' className='form-label'>
                    {t('password')} <span className='required'>*</span>
                  </label>
                  <input
                    type='password'
                    className='form-control'
                    id='password'
                    placeholder={t('password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type='submit' className='btn btn-ocean'>
                  {t('confirm')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
