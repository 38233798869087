import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showUserActions } from '../../actions/userActions';

const DocumentsUserInfoUpload = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const id = props?.patientId;

  const showUserData = useSelector((state) => state.showUser);
  const { showUser } = showUserData;
  useEffect(() => {
    dispatch(showUserActions(id));
  }, [dispatch, id]);
  
  let doctorPicture = '/assets/images/doctors/doctor.jpg';

  if (showUser?.data?.profile_picture != null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${showUser?.data?.profile_picture}`;
  }

  return (
    <div className='container-xxl doctor_user_document_page'>
      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-1'>
          <div className='profile'>
            <div className='profile-image'>
              <div className='profile-image-wrapper'
                style={{
                  background: `url(${doctorPicture})`,
                }}
              ></div>
              <img src={doctorPicture} alt='Doctor Name' />
              
            </div>
            <div className='profile-info'>
              <div className='personal'>
                <h3 className='name'>
                  {showUser?.data?.name} {showUser?.data?.surname}
                </h3>
                <ul>
                  <li>
                    <span>{t('male')}</span> {showUser?.data?.birthday}
                  </li>
                  <li> {showUser?.data?.email}</li>
                  <li> {showUser?.data?.phone_number}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 mb-1'>
          <div className='upload-document'>
            <div className='notification-icon'>
              <img src='/assets/images/document.svg' alt='' />
            </div>
            <p>{t('request-patient-doc')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsUserInfoUpload;
