import React, { useRef } from "react";
import { calendarDays, months } from "../helpers/constants";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const DoctorAppointment = ({ termins, day, index, noArrow = false }) => {
  const terminRef = useRef(null);

  const scrollBottom = () => {
    terminRef.current.scrollTo(0, terminRef.current.scrollHeight);
  };

  const isGroupAppointment = (termin) => termin.spots > 1;

  const userTimezone = moment.tz.guess();

  const convertToLocalTime = (dateString) => {
    return moment.utc(dateString).tz(userTimezone).format("HH:mm");
  };

  return (
    <div className="doctor-book-day">
      <div className="day">{calendarDays[new Date(day).getDay()]}</div>
      <div className="date">
        {new Date(day).getDate()} {months[new Date(day).getMonth()]}
      </div>
      <ul
        ref={terminRef}
        className="appointment-scroll-container"
        style={{ maxHeight: 155, overflowY: "auto" }}
      >
        {termins &&
          termins[index] &&
          termins[index].map((element, index) => {
            return (
              <Link
                to={
                  element.status === 0
                    ? "/book-your-visit"
                    : `/book-your-visit/book-your-visit-patient/${element.id}`
                }
                key={index} // Correct placement of the key
              >
                {isGroupAppointment(element) ? (
                  <li
                    className={
                      element.status === 0 ? "disable" : "group_appointment"
                    }
                  >
                    <div>{element.doctor_specialization_price?.title}</div>
                    <span>
                      {convertToLocalTime(
                        element.start_date.replace(/-/g, "/")
                      )}
                    </span>
                    <div>
                      {element.status}/{element.spots}
                    </div>
                  </li>
                ) : (
                  <li className={element.status === 0 ? "disable" : ""}>
                    <span>
                      {convertToLocalTime(
                        element.start_date.replace(/-/g, "/")
                      )}
                    </span>
                  </li>
                )}
              </Link>
            );
          })}
      </ul>

      {!noArrow && (
        <div
          onClick={scrollBottom}
          style={{
            zIndex: 999,
            position: "absolute",
            bottom: "0",
            left: "50%",
            cursor: "pointer",
          }}
        >
          <img src="/assets/images/arrow-up-down.svg" alt="Scroll Down" />
        </div>
      )}
    </div>
  );
};

export default DoctorAppointment;
