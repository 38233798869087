import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  adminChangeProfileStatusDoctorActions,
  getAdminAllDoctorsActions,
} from '../../actions/adminActions';

const ChangeDoctorUsertype = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [usertype, setUsertype] = useState('');
  const doctor_id = location?.state?.doctorId;
  const status = location?.state?.status;

  useEffect(() => {
    if (location?.state?.usertype) {
      setUsertype(location?.state?.usertype);
    }
  }, [location]);

  let history = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      adminChangeProfileStatusDoctorActions(doctor_id, status, usertype)
    );
    dispatch(getAdminAllDoctorsActions());
    history('/clinic-doctors');
  };

  return (
    <div className="container-xxl">
      {/* {loading && <LoadingIndicator />} */}
      <div className="container-xxl page-heading">
        <div className="row">
          <div className="col-sm-4 col-12">
            <Link to="/clinic-doctors" className="back-to">
              {t('back-to-list')}
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-6 col-md-12 col-12 mx-auto">
          <div className="user-view">
            <div className="row">
              <form onSubmit={submitHandler}>
                <div className="col-sm-6 col-12 mb-3">
                  <label htmlFor="text" className="form-label">
                    {t('select-dotor-status')}{' '}
                    <span className="required"></span>
                  </label>
                  <select
                    className="form-select"
                    required
                    aria-label="Default select example"
                    name="usertype"
                    value={usertype}
                    onChange={(e) => setUsertype(e.target.value)}
                  >
                    <option value={'clinic_doctor'}>
                      {t('clinic-doctor')}
                    </option>
                    <option value={'freelance_doctor'}>
                      {t('freelance-doctor')}
                    </option>
                  </select>
                </div>

                <button type="submit" className="btn btn-nature">
                  {t('change-status')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeDoctorUsertype;
