import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import PatientList from './PatientList';
import MiniEventsItemActions from './MiniEventsItemActions';
import ConfirmPricePopup from './ConfirmPricePopup';
import { useSelector } from 'react-redux';

const MiniEventsPopupItem = ({event, onEventAction}) => {

    const { t } = useTranslation();
    const [icons, setIcons] = useState([]);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [meetingLink, setMeetingLink] = useState('');
    const [status, setStatus] = useState('');
    const [statusString, setStatusString] = useState('');
    const [date, setDate] = useState('');
    const [confirmPrice, setConfirmPrice] = useState(false);
    const [price, setPrice] = useState('');
    const [spots, setSpots] = useState(0);
    const [patientList, setPatientList] = useState('');
    const [actions, setActions] = useState('');
    const [isDoctor, setIsDoctor] = useState(false);


    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {      
      setIcons(getIcons());
      setTitle(getTitle());
      setSubtitle(getSubtitle());
      setDescription(getDescription());
      setMeetingLink(getMeetingLink());
      setStatusString(getStatus());
      setDate(getDate());
      setConfirmPrice(getConfirmPrice());
      setPrice(getPrice());
      setSpots(getSpots());
      setPatientList(getPatientList());
    }
    , [isDoctor, event]);

    useEffect(() => {
      setIsDoctor(userInfo?.usertype === "freelance_doctor" || userInfo?.usertype === "clinic_doctor");
    }, [userInfo]);


    useEffect(() => {
      setActions(getActions());
    }, [status, event]);

    const getIcons = () => {
      if (event.type !== 'appointment') {
        return '';
      }

      let icons = [];
      
      icons.push(
        <Link key="chat" className="mini-events-popup-item-icon" to='/chat'>
          <img src='/assets/images/chat-black.svg' alt='chat' />
        </Link>
      )

      if (event.event.patient_user_appointment?.phone_number) {
        icons.push(<a key="phone" href={`tel:${event.event.patient_user_appointment.phone_number}`} className='mini-events-popup-item-icon'>
          <img src='/assets/images/phone.svg' alt='phone' />
        </a>);
      }
      return icons;
    }

    const getTitle = () => {
        if (event.type === 'appointment') {
          if (event.event.patient_user_appointment){
            return (event.event.patient_user_appointment?.surname ? event.event.patient_user_appointment.surname : '') + ' ' + (event.event.patient_user_appointment?.name ? event.event.patient_user_appointment.name : '');
          }
          else if (event.event.doctor_profile_calendar) {
            return (event.event.doctor_profile_calendar?.surname ? event.event.doctor_profile_calendar.surname : '') + ' ' + (event.event.doctor_profile_calendar?.name ? event.event.doctor_profile_calendar.name : '');
          }
        } else if (event.type === 'group-appointment') {
            return t('group-booking');
        } else if (event.type === 'termin') {
            return t('open-appointment');
        }
        else if (event.type === 'group-termin') {
            return t('group-booking');
        }
    }

    const getSubtitle = () => {
        if (event.type === 'appointment') {
          return t(event.event.specialization_appointment?.title ? event.event.specialization_appointment.title : '');
        } else if (event.type === 'group-appointment') {
          return t(event.events[0].specialization_appointment?.title ? event.events[0].specialization_appointment.title : '');
        } else if (event.type === 'termin') {
          return t(event.event.specialization_appointment?.title ? event.event.specialization_appointment.title : '');
        } else if ( event.type === "group-termin" ) {
          //TODO
          // return t(event.event.doctor_specialization_price?.title ? event.event.doctor_specialization_price.title : '');
        }
    }

    const getDescription = () => {
      if (event.type === 'appointment') {
        return event.event.reason_visit;
      } 
      else if ( event.type === "group-termin" ) {
        return t(event.event.doctor_specialization_price?.title ? event.event.doctor_specialization_price.title : '');
      }
      if (event.type === 'group-appointment') {
        return event.termin?.doctor_specialization_price?.title;
      }
    }

    const getMeetingLink = () => {
      if (event.type === 'appointment' && event.event.appointment_url) {
        return (
          <a className='btn btn-flowers' href={event.event.appointment_url} target='_blank' rel="noopener noreferrer">
            {t('join-online-meeting')}
          </a>
        );
      }
      if (event.type === 'group-appointment') {
        //Find one event with appointment_url
        for (let i = 0; i < event.events.length; i++) {
          if (event.events[i].appointment_url) {
            return (
              <a className='btn btn-flowers' href={event.events[0].appointment_url} target='_blank' rel="noopener noreferrer">
                {t('join-online-meeting')}
              </a>
              
            );
          }
        }
      }
      return <></>
    }


    const getStatus = () => {
      let statustemp = '';
      let color = '';
      if (event.type === 'appointment') {
        if (event.event.status === 0) {
          statustemp = "cancelled"
        }
        else {
          if (event.event.payment_status === 0 &&
            event.event.appointment_status === "accept_online" &&
            event.event.confirmed_price === 1) {
              color = "sun";
            }
          statustemp = event.event.appointment_status;
        }
      }

      else if (event.type === 'group-appointment') {
        statustemp = event.events[0].appointment_status;
      }
      else if (event.type === 'termin') {
        statustemp = "open"
      }
      else if (event.type === 'group-termin') {
        statustemp = "accept_" + event.event.location;
        color = "gray"
      }

      setStatus(statustemp);
      return statusToString(statustemp, color)
    }

    const statusToString = (status, color="") => {
      let text = '';

      if (status === "accept_online") {
        color = color ? color : "nature";
        text = t('accepted-online');
      }
      else if (status === "accept_studio") {
        color = color ? color : "nature";
        text = t('accepted-studio');
      }
      else if (status === "open") {
        color = "gray";
        text = t('open');
      }
      else if (status === "pending") {
        color = "sun";
        text = t('pending');
      }
      else if (status === "propose_online") {
        color = "sun";
        text = t('proposed-online');
      }
      else if (status === "propose_studio") {
        color = "sun";
        text = t('proposed-studio');
      }
      else if (status === "confirmed-price") {
        color = "sunset";
        text = t('confirmed-price');
      }
      else if (status === "cancelled") {
        color = "sunset";
        text = t('cancelled');
      }

      return <><div className={`mini-events-popup-item-status-color ${color}`}></div>{text}</>;
    }

    const getDate = () => {
      let dateStart = '';
      let dateEnd = '';
      if (event.type === 'appointment') {
        dateStart = event.event.start_date;
        dateEnd = event.event.end_date;
      } else if (event.type === 'group-appointment') {
        dateStart = event.events[0].start_date;
        dateEnd = event.events[0].end_date;
      } else if (event.type === 'termin' || event.type === 'group-termin') {
        dateStart = event.event.start_date;
        dateEnd = event.event.end_date;
      }

      const dateStartObj = new Date(dateStart);
      const dateEndObj = new Date(dateEnd);

      // Return date start hours and minutes and date end hours and minutes in format HH:MM - HH:MM event if minutes are < 10
      return `${dateStartObj.getHours()}:${dateStartObj.getMinutes() < 10 ? '0' : ''}${dateStartObj.getMinutes()} - ${dateEndObj.getHours()}:${dateEndObj.getMinutes() < 10 ? '0' : ''}${dateEndObj.getMinutes()}`;
    }

    const getConfirmPrice = () => {
      if (event.type === 'appointment' && event.event.confirmed_price === 0) {
        return <ConfirmPricePopup event={event} onEventAction={onEventAction} />
      } 
    }

    const getPrice = () => {
     if (event.type === 'appointment') {
        return event.event.payment_price;
      }
      else if (event.type === 'group-appointment') {
        return event.events[0].payment_price;
      }
      else if (event.type === 'group-termin') {
        return event.event.doctor_specialization_price.price;
      }
    }

    const getSpots = () => {
      if (event.type === 'appointment' || event.type === 'termin' || !isDoctor) {
        return '';
      }

      if (event.type === 'group-appointment'){
        if (!event.termin)
          return event.events.length + '/' + event.events.length + ' ' + t('patients');
        
        return event.events.length + '/' + event.termin.spots + ' ' + t('patients');
      }
      else {
        return 0 + '/' + event.event.spots + ' ' + t('patients');
      
      }

    }

    const getPatientList = () => {
      if (event.type !== 'group-appointment') {
        return '';
      }
      return (<PatientList event={event}/>);
    }

    const getActions = () => {
      return (
        <MiniEventsItemActions onEventAction={onEventAction} event={event} status={status} isDoctor={isDoctor}/>
      )
    }

  return (
    <div className='mini-events-popup-item'>
      <div className='mini-events-popup-item-icons'>{icons}</div>
      <div className='mini-events-popup-item-header'>
        {title && <div className='mini-events-popup-item-title'>{title}</div>}
        {subtitle && <div className='mini-events-popup-item-subtitle'>{subtitle}</div>}
        {description && <div className='mini-events-popup-item-description'>{description}</div>}
        {meetingLink && <div className='mini-events-popup-item-meeting-link'>{meetingLink}</div>}
      </div>
      <div className='mini-events-popup-item-body'>
        {statusString && <div className='mini-events-popup-item-status'>{statusString}</div>}
        {status !== "cancelled" && date && <div className='mini-events-popup-item-date'>{date}</div>}
        <>{status !== "cancelled" && confirmPrice}</>
        {status !== "cancelled" && price && 
          <div className='mini-events-popup-item-price'>
            <img src='/assets/images/price.svg' alt='price' />
            {price} €
          </div>
        }
      </div>
      <div className='mini-events-popup-item-footer'>
        {spots && <div className='mini-events-popup-item-group-info'>
          {spots}
          {patientList && <div className='mini-events-popup-item-patient-list'>{patientList}</div>}
        </div>}
        {status !== "cancelled" && actions}
      </div>
    </div>
  );
};

export default MiniEventsPopupItem;