import './index.scss';

import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import FooterV2 from './components/FooterV2';
import Header from './components/Header';
import jwt from 'jwt-decode';
import HomeScreenV2 from './screens/HomeScreenV2';
import RegisterPatientScreen from './screens/patient/RegisterPatientScreen';
import LoginScreen from './screens/LoginScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import RegisterClinicDoctorScreen from './screens/doctor/RegisterClinicDoctorScreen';
import UpdatePatientProfileScreen from './screens/patient/UpdatePatientProfileScreen';
import UpdateDoctorProfileScreen from './screens/doctor/UpdateDoctorProfileScreen';
import ShowAllAvailableApointmentScreen from './screens/apointment/ShowAllAvailableApointmentScreen';
import BookYourApointmentNotLoggedScreen from './screens/apointment/BookYourApointmentNotLoggedScreen';
import BookYourApointmentLoggedScreen from './screens/apointment/BookYourApointmentLoggedScreen';
import UpdateBookVisitScreen from './screens/apointment/UpdateBookVisitScreen';
import ConfirmOrCancelApointmentScreen from './screens/apointment/ConfirmOrCancelApointmentScreen';
import CancelApointmentScreen from './screens/apointment/CancelApointmentScreen';
import ConfirmApointmentScreen from './screens/apointment/ConfirmApointmentScreen';
import Dashboard from './screens/Dashboard';
import DoctorCreateTerminScreen from './screens/doctor/DoctorCreateTerminScreen';
import DoctorCreateGroupTerminScreen from './screens/doctor/DoctorCreateGroupTerminScreen';
import FreelenceDoctorPriceCreateScreen from './screens/doctor/FreelenceDoctorPriceCreateScreen';
import FreelenceDoctorSetingsScreen from './screens/doctor/FreelenceDoctorSetingsScreen';
import Faq from './screens/Faq';
import PatientTermsScreen from './screens/patient/PatientTermsScreen';
import AboutUsScreen from './screens/AboutUsScreen';
import {
  appRoutes,
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  socketToken,
  socketURL,
  SOCKET_ROUTES,
} from './helpers/constants';
import PatientNotificationScreen from './screens/patient/PatientNotificationScreen';
import PatientSettingsScreen from './screens/patient/PatientSettingsScreen';
import HealthOverviewScreen from './screens/patient/HealthOverviewScreen';
import RegisterFreelanceDoctorScreen from './screens/doctor/RegisterFreelenceDoctorScreen';
import DocumentScreen from './screens/patient/DocumentScreen';
import ChatScreen from './screens/ChatScreen';
import ContactScreen from './screens/ContactScreen';
import DoctorDocumentsScreen from './screens/doctor/DoctorUserListScreen';
import HealthOverviewDoctor from './screens/doctor/HealthOverviewDoctor';
import DoctorDocumentScreen from './screens/doctor/DoctorDocumentScreen';
import RegisterScreen from './screens/RegisterScreen';
import HomeScreen from './screens/HomeScreen';
import PatientPaymentScreen from './screens/patient/PatientPaymentScreen';
import { useSelector } from 'react-redux';
import OnlinePayments from './screens/patient/OnlinePayments';
import PaymentPlan from './components/PaymentPlan';
import { useEffect, useRef, useState } from 'react';
import SuccessNotification from './components/Notifications/SuccessNotifications';

import io from 'socket.io-client';
import AlertNotification from './components/Notifications/AlertNotification';
import DocumentNotification from './components/Notifications/DocumentNotification';
import ErrorNotification from './components/Notifications/ErrorNotification';
import DoctorOnlinePayment from './screens/doctor/OnlinePayment';
import PublicDoctorScreen from './screens/doctor/PublicDoctorScreen';
import TwoFactorAuthentication from './components/TwoFactorAuthentication';
import axios from 'axios';
import PermamentlyPlanScreen from './screens/doctor/PermamentlyPlanScreen';
import ChangePlanScreen from './screens/doctor/ChangePlanScreen';
import BuyPlanScreen from './screens/doctor/BuyPlanScreen';
import YourCreditScreen from './screens/doctor/YourCreditScreen';
import ClinicPatientsScreen from './screens/clinic/ClinicPatientsScreen';
import AddNewDoctorScreen from './screens/clinic/AddNewDoctorScreen';
import SeeDoctorProfileScreen from './screens/admin/SeeDoctorProfileScreen';
import SeeDoctorEarningsScreen from './screens/admin/SeeDoctorEarningsScreen';

import DoctorUpcomingTerminsOrPricesScreen from './screens/doctor/DoctorUpcomingTerminsOrPricesScreen';
import { getItemFromLocalStorage } from './helpers/helpers';
import { useTranslation } from 'react-i18next';
import ChangeDoctorUsertype from './screens/admin/ChangeDoctorUsertype';
import AllClinicsScreen from './screens/admin/AllClinicsScreen';

import ClinicSeeDoctorProfileScreen from './screens/clinic/ClinicSeeDoctorProfileScreen';
import ClinicSeeDoctorEarningsScreen from './screens/clinic/ClinicSeeDoctorEarningsScreen';
import ClinicChangeDoctorUsertype from './screens/clinic/ClinicChangeDoctorUsertype';
import ChangeProfileStatusScreen from './screens/ChangeProfileStatusScreen';
import Doctors from './screens/Doctors';
import Privacy from './screens/Privacy';
import UnifiedCalendarScreen from './screens/Calendar';

const socket = io(socketURL, {
  transports: ['websocket'],
  query: { token: socketToken },
});

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { t, i18n } = useTranslation();

  const notificationRef = useRef(null);

  const [notification, setNotification] = useState(null);
  const [notificationCount, setNotificationCount] = useState(false);
  const [chatNotificationCount, setChatNotificationCount] = useState(false);

  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  useEffect(() => {
    if (userInfo) {
      if (!socket.connected) socket.connect();
      socket.emit(SOCKET_ROUTES.JOIN, token.tokenParams.id);
      socket.on(SOCKET_ROUTES.NOTIFICATIONS, (notification) => {
        setNotification(null);
        setNotificationCount(true);
        setNotification(notification);
        if (notification.message === NOTIFICATIONS_TEXT.RECIVE_MESSAGE) {
          setChatNotificationCount(true);
        }
      });
      return () => {
        if (socket.connected) socket.disconnect();
      };
    }
  }, [userInfo, token]);

  const notificationsRead = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_PRODUCTION_API}notification-is-not-read`,
        null,
        config
      )
      .then((response) => {
        if (response.data.data) {
          setNotificationCount(true);
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  };

  const chatNotificationRead = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_PRODUCTION_API}message/is-not-read`,
        null,
        config
      )
      .then((response) => {
        if (response.data.data) {
          setChatNotificationCount(true);
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  };

  useEffect(() => {
    notificationsRead();
    chatNotificationRead();
  }, []);

  const onRemoveNotification = () => {
    if (notificationRef.current) {
      notificationRef.current.remove();
    }
  };

  return (
    <Router>
      <Header
        onNotificationClick={() => setNotificationCount(false)}
        onChatNotificationClick={() => setChatNotificationCount(false)}
        notificationCount={notificationCount}
        chatNotificationCount={chatNotificationCount}
      />
      <Routes>
        <Route path="/old" element={<HomeScreen />} />

        <Route path="/" element={<HomeScreenV2 />} />

        <Route
          path="/2fa/:document_id/:user_ids/:patient_id"
          element={<TwoFactorAuthentication />}
        />

        <Route path="/doctor-register" element={<RegisterScreen />} />
        <Route
          path="/book-your-visit"
          element={<ShowAllAvailableApointmentScreen />}
        />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/calendar" element={<UnifiedCalendarScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />

        <Route path={appRoutes.CHAT} element={<ChatScreen />} />
        <Route path={appRoutes.CONTACT} element={<ContactScreen />} />

        <Route
          path={appRoutes.DOCTOR.ONLINE_PAYMENTS}
          element={<DoctorOnlinePayment />}
        />

        {/* Patient */}
        <Route path="/register-patient" element={<RegisterPatientScreen />} />

        <Route
          path={appRoutes.PATIENT.ONLINE_PAYMENTS}
          element={<OnlinePayments />}
        />
        <Route
          path={appRoutes.PATIENT.PAYMENT + '/:id/:price/:doctorID'}
          element={<PatientPaymentScreen />}
        />
        <Route
          path={'/patient-profile'}
          element={<UpdatePatientProfileScreen />}
        />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path={appRoutes.PATIENT.DOCUMENTS}
          element={<DocumentScreen />}
        />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/patient-terms" element={<PatientTermsScreen />} />
        <Route path="/about-us" element={<AboutUsScreen />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path={appRoutes.NOTIFICATION}
          element={<PatientNotificationScreen />}
        />
        <Route
          path={appRoutes.PATIENT.SETTINGS}
          element={<PatientSettingsScreen />}
        />

        <Route
          path={appRoutes.PATIENT.HEALTH_OVERVIEW}
          element={<HealthOverviewScreen />}
        />

        {/* Clinic Doctor */}
        <Route
          path="/register-clinic-doctor"
          element={<RegisterClinicDoctorScreen />}
        />

        <Route path="/public-doctor/:id" element={<PublicDoctorScreen />} />

        <Route path={appRoutes.PAYMENT_PLAN} element={<PaymentPlan />} />

        <Route
          path={appRoutes.DOCTOR.PATIENTS_LIST}
          element={<DoctorDocumentsScreen />}
        />

        <Route
          path={appRoutes.DOCTOR.PATIENT.HEALTH_OVERVIEW + '/:id'}
          element={<HealthOverviewDoctor />}
        />

        <Route
          path={appRoutes.DOCTOR.DOCUMENTS + '/:id'}
          element={<DoctorDocumentScreen />}
        />
        <Route
          path={appRoutes.DOCTOR.CREATE_TERMIN}
          element={<DoctorCreateTerminScreen />}
        />
        <Route
          path={appRoutes.DOCTOR.CREATE_GROUP_TERMIN}
          element={<DoctorCreateGroupTerminScreen />}
        />

        <Route path="/doctor-profile" element={<UpdateDoctorProfileScreen />} />

        {/* Freelance Doctor */}
        <Route
          path="/register-freelance-doctor"
          element={<RegisterFreelanceDoctorScreen />}
        />
        <Route
          path={appRoutes.DOCTOR.CREATE_PRICE}
          element={<FreelenceDoctorPriceCreateScreen />}
        />

        <Route
          path={appRoutes.DOCTOR.UPCOMING_TERMINS}
          element={<DoctorUpcomingTerminsOrPricesScreen />}
        />
        <Route
          path={appRoutes.DOCTOR.UPCOMING_PRICES}
          element={<DoctorUpcomingTerminsOrPricesScreen />}
        />

        <Route
          path={appRoutes.DOCTOR.SETTINGS}
          element={<FreelenceDoctorSetingsScreen />}
        />
        {/* Payment plan */}
        <Route path="/permamently-plan" element={<PermamentlyPlanScreen />} />
        <Route path="/change-plan" element={<ChangePlanScreen />} />
        <Route path="/buy-plan" element={<BuyPlanScreen />} />
        <Route path="/your-credit" element={<YourCreditScreen />} />

        {/* Apointment */}
        <Route
          path="/book-your-visit"
          element={<ShowAllAvailableApointmentScreen />}
        />
        <Route
          path="/book-your-visit/book-your-visit/:id"
          element={<BookYourApointmentNotLoggedScreen />}
        />
        <Route
          path="/book-your-visit/book-your-visit-patient/:id"
          element={<BookYourApointmentLoggedScreen />}
        />
        <Route
          path="/update-book-visit/:id"
          element={<UpdateBookVisitScreen />}
        />
        <Route
          path="/update-book-visit-confirm-or-cancel/:id"
          element={<ConfirmOrCancelApointmentScreen />}
        />
        <Route
          path="/cancel-apointment-confirm"
          element={<CancelApointmentScreen />}
        />
        <Route
          path="/confirm-apointment/:id"
          element={<ConfirmApointmentScreen />}
        />

        <Route path="/clinics" element={<AllClinicsScreen />} />
        <Route path="/patients" element={<ClinicPatientsScreen />} />

        <Route path="/doctors" element={<Doctors />} />
        <Route path="/add-new-doctor" element={<AddNewDoctorScreen />} />

        <Route
          path="/see-doctor-profile"
          element={<SeeDoctorProfileScreen />}
        />
        <Route
          path="/see-doctor-earnings"
          element={<SeeDoctorEarningsScreen />}
        />
        <Route
          path="/change-doctor-usertype"
          element={<ChangeDoctorUsertype />}
        />
        <Route
          path="/clinic-see-doctor-profile"
          element={<ClinicSeeDoctorProfileScreen />}
        />
        <Route
          path="/clinic-see-doctor-earnings"
          element={<ClinicSeeDoctorEarningsScreen />}
        />
        <Route
          path="/change-doctor-profile-status"
          element={<ChangeProfileStatusScreen />}
        />
        <Route
          path="/clinic-change-doctor-usertype"
          element={<ClinicChangeDoctorUsertype />}
        />
      </Routes>
      {notification && (
        <div
          onClick={onRemoveNotification}
          className="fadeOut jumping-notification"
        >
          <Link to={appRoutes.NOTIFICATION}>
            {notification.type === NOTIFICATIONS_TYPES.SUCCESS && (
              <div ref={notificationRef}>
                <SuccessNotification message={t(notification.message)} />
              </div>
            )}
            {notification.type === NOTIFICATIONS_TYPES.ALERT && (
              <div ref={notificationRef}>
                <AlertNotification message={t(notification.message)} />
              </div>
            )}
            {notification.type === NOTIFICATIONS_TYPES.DOCUMENT && (
              <div ref={notificationRef}>
                <DocumentNotification message={t(notification.message)} />
              </div>
            )}
            {notification.type === NOTIFICATIONS_TYPES.ERROR && (
              <div ref={notificationRef}>
                <ErrorNotification message={t(notification.message)} />
              </div>
            )}
          </Link>
        </div>
      )}

      <FooterV2 />
    </Router>
  );
}

export default App;
