import React, { useEffect, useState } from "react";
import jwt from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomMap from "../../components/CustomMap";
import DoctorAppointemnt from "../../components/DoctorAppointment";
import axios from "axios";
import { API_ROUTES } from "../../helpers/constants";
import {
  doctorPublicScreenActions,
  getDoctorByUidActions,
} from "../../actions/doctorActions";
import { showUserActions } from "../../actions/userActions";

const PublicDoctorScreen = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [appointmentPage, setAppointmentPage] = useState(1);
  const [doctorList, setDoctorList] = useState([]);

  const dispatch = useDispatch();

  const { id } = useParams();

  const publicDoctorScreenData = useSelector(
    (state) => state.publicDoctorScreen
  );
  const { publicDoctorScreen } = publicDoctorScreenData;

  useEffect(() => {
    dispatch(doctorPublicScreenActions(id));
  }, [dispatch, id]);

  // Get user data from api
  const getDoctorByUidData = useSelector((state) => state.getDoctorByUid);
  const { getDoctorByUid } = getDoctorByUidData;

  useEffect(() => {
    dispatch(getDoctorByUidActions(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getDoctorByUid?.data) {
      setDoctorList(getDoctorByUid?.data);
    }
  }, [getDoctorByUid, doctorList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let doctorPicture = "/assets/images/doctors/doctor.jpg";

  if (getDoctorByUid?.data?.profile_picture != null) {
    doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${getDoctorByUid?.data?.profile_picture}`;
  }

  return (
    <>
      <div className="public_doctor_page">
        <div className="booking-appointment">
          <div className="container-xxl">
            <div className="row">
              <div className="col-xxl-12 col-md-12 col-12 mx-auto">
                <div className="booking booking-small">
                  <h1>
                    Dr. {getDoctorByUid?.data?.name}{" "}
                    {getDoctorByUid?.data?.surname}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="admin-doctors-container">
          <div className="container-xxl">
            <div className="row">
              <div className="col-xxl-12">
                <div className="doctor-item doctor-col-2">
                  <div className="doctor doctor doctor_single_card">
                    <div className="profile-image">
                      <div
                        className="profile-image-wrapper"
                        style={{
                          background: `url(${doctorPicture})`,
                        }}
                      ></div>
                      <img src={doctorPicture} alt="Doctor" />
                    </div>

                    <div className="doctor-info">
                      <div className="doctor-name">
                        <span title="Dr. Richard Hardee">
                          Dr. {getDoctorByUid?.data?.name}{" "}
                          {getDoctorByUid?.data?.surname}
                        </span>
                      </div>
                      <div className="doctor-job">
                        <div className="job-tags">
                          <span className="btn-nature">
                            {t(
                              getDoctorByUid?.data?.doctor_profile
                                ?.primary_specialization?.title
                            )}
                          </span>
                          <span className="btn-nature">
                            {t(
                              getDoctorByUid?.data?.doctor_profile
                                ?.secondary_specialization?.title
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="doctor-desciption">
                        <p>{getDoctorByUid?.data?.this_page_required_info}</p>
                      </div>
                      <div className="doctor-location hidden-mobile">
                        <p>
                          {`${getDoctorByUid?.data?.doctor_profile?.street} ${getDoctorByUid?.data?.doctor_profile?.city} `}
                          {getDoctorByUid?.data?.doctor_profile?.country &&
                            getDoctorByUid?.data?.doctor_profile?.country}
                        </p>
                      </div>
                      <div className="doctor-price hidden-mobile">
                        <span>
                          {getDoctorByUid?.data?.doctor_profile?.doctor_specialization_prices.map(
                            (p) => (
                              <div key={p.title}>
                                <span>{p.price}€ </span>
                                <span>{p.title}</span>
                                <br />
                              </div>
                            )
                          )}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="doctor doctor-mobile">
                    <div className="doctor-info">
                      <div className="doctor-location ">
                        <p>
                          {`${getDoctorByUid?.data?.doctor_profile?.street} ${getDoctorByUid?.data?.doctor_profile?.city}`}
                          {getDoctorByUid?.data?.doctor_profile?.country &&
                            getDoctorByUid?.data?.doctor_profile?.country}
                        </p>
                      </div>
                      <div className="doctor-price ">
                        <span>
                          {getDoctorByUid?.data?.doctor_profile?.doctor_specialization_prices.map(
                            (p) => (
                              <div key={p.title}>
                                <span>{p.price}€ </span>
                                <span>{p.title}</span>
                                <br />
                              </div>
                            )
                          )}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* APOINTMENTS */}
                  <div
                    className="doctor-book doctor-scrool"
                    style={{
                      height: "unset",
                    }}
                  >
                    {getDoctorByUid?.data?.doctor_appointment_termins &&
                      Object.keys(
                        getDoctorByUid?.data?.doctor_appointment_termins
                      ).map((appointment, index) => {
                        return (
                          <DoctorAppointemnt
                            day={appointment}
                            index={index}
                            key={appointment}
                            termins={Object.values(
                              getDoctorByUid?.data?.doctor_appointment_termins
                            )}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="doctor-gmap">
                  {/* TODO MAP */}
                  {doctorList && Object.keys(doctorList).length > 0 && (
                    <CustomMap height={300} data={Array(doctorList)} />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="doctor-accordion">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t("safetly")}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          {
                            getDoctorByUid?.data?.doctor_profile
                              ?.safety_measures
                          }
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {t("services")}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                      >
                        <div className="accordion-body">
                          {getDoctorByUid?.data?.doctor_profile?.services}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {t("formation")}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                      >
                        <div className="accordion-body">
                          {
                            getDoctorByUid?.data?.doctor_profile
                              ?.formation_and_specialization
                          }
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t("diseases")}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          {
                            getDoctorByUid?.data?.doctor_profile
                              ?.disease_treated
                          }
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t("languages")}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          {getDoctorByUid?.data?.doctor_profile?.languages}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-phone">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button phone"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#phone"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        {getDoctorByUid?.data?.phone_number}
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicDoctorScreen;
