import React, { useEffect } from 'react';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const RegisterScreen = () => {
  // Check if login user
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { t, i18n } = useTranslation();
  let token;
  if (userInfo) {
    token = jwt(userInfo.data);
  }

  let history = useNavigate();
  useEffect(() => {
    if (token) {
      history('/book-your-visit');
    }
  }, [history, token]);

  return (
    <>
      <div className='register_general'>
        <div className='page-hero'>
          <div className='container-xxl'>
            <div className='row'>
              <div className='col-xxl-8 col-md-12 col-12 mx-auto'>
                <div className='page-info'>
                  <div className='page-text'>
                    <h1>{t('how-are-you-to-use')}?</h1>
                    <p className='desc'>
                      {t('how-are-you-message')}
                      <br />
                      {t('register-as-patient-or-doctor')}.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-12'>
              <div className='users-box'>
                <div className='user-register-type col-md-6'>
                  <Link to='/register-patient' className='user-register-label register-patient'>
                      <img src='assets/images/patient-icon.svg' alt='Patient' />
                      <span>{t('register')}</span>
                  </Link>
                  <div className='user-register-description'>
                    <h4>{t('patient')}</h4>
                    <p>{t('you-dont-have-account')} </p>
                  </div>
                </div>

                {/* <div className='user-register-type'>
                  <Link to='/register-clinic-doctor'>
                    <a
                      href='/register-clinic-doctor'
                      className='user-register-label register-doctor'
                    >
                      <img src='assets/images/hospital-icon.svg' alt='Hospital' />
                      <span>Register</span>
                    </a>
                  </Link>
                  <div className='user-register-description'>
                    <h4>Clinic Doctor</h4>
                    <p>Access now to start helping the people of Guadeloupe!</p>
                  </div>
                </div> */}

                <div className='user-register-type col-md-6'>
                  <Link to='/register-freelance-doctor' className='user-register-label register-freelance'>
                      <img
                        src='assets/images/freelance-icon.svg'
                        alt='Freelance'
                      />
                      <span>{t('register')}</span>
                  </Link>
                  <div className='user-register-description'>
                    <h4>{t('freelance-doctor')}</h4>
                    <p>{t('part-of')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;
