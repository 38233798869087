import React from 'react';
import { useTranslation } from 'react-i18next';

const PatientSettingsScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className='container-xxl page-heading'>
      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
          <a href='/patient-dashboard' classNameName='back-to'>
            {t('dashboard')}
          </a>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
          <div className='user-register-label privacy'>
            <img src='assets/images/privacy.svg' alt='privacy' />
            <span>{t('settings')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientSettingsScreen;
