export const GET_ALL_DOCTOR_REQUEST = 'GET_ALL_DOCTOR_REQUEST';
export const GET_ALL_DOCTOR_SUCCESS = 'GET_ALL_DOCTOR_SUCCESS';
export const GET_ALL_DOCTOR_FAIL = 'GET_ALL_DOCTOR_FAIL';

export const GET_EARNINGS_DOCTOR_REQUEST = 'GET_EARNINGS_DOCTOR_REQUEST';
export const GET_EARNINGS_DOCTOR_SUCCESS = 'GET_EARNINGS_DOCTOR_SUCCESS';
export const GET_EARNINGS_DOCTOR_FAIL = 'GET_EARNINGS_DOCTOR_FAIL';

export const ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_REQUEST =
  'ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_REQUEST';
export const ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_SUCCESS =
  'ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_SUCCESS';
export const ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_FAIL =
  'ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_FAIL';

export const ADMIN_LOGIN_DOCTOR_REQUEST = 'ADMIN_LOGIN_DOCTOR_REQUEST';
export const ADMIN_LOGIN_DOCTOR_SUCCESS = 'ADMIN_LOGIN_DOCTOR_SUCCESS';
export const ADMIN_LOGIN_DOCTOR_FAIL = 'ADMIN_LOGIN_DOCTOR_FAIL';

export const CREATE_CLINIC_REQUEST = 'CREATE_CLINIC_REQUEST';
export const CREATE_CLINIC_SUCCESS = 'CREATE_CLINIC_SUCCESS';
export const CREATE_CLINIC_FAIL = 'CREATE_CLINIC_FAIL';