export const GET_ALL_CITY_REQUEST = 'GET_ALL_CITY_REQUEST';
export const GET_ALL_CITY_SUCCESS = 'GET_ALL_CITY_SUCCESS';
export const GET_ALL_CITY_FAIL = 'GET_ALL_CITY_FAIL';

export const GET_ALL_APOINTMENT_REQUEST = 'GET_ALL_APOINTMENT_REQUEST';
export const GET_ALL_APOINTMENT_SUCCESS = 'GET_ALL_APOINTMENT_SUCCESS';
export const GET_ALL_APOINTMENT_FAIL = 'GET_ALL_APOINTMENT_FAIL';

export const CREATE_APOINTMENT_REQUEST = 'CREATE_APOINTMENT_REQUEST';
export const CREATE_APOINTMENT_SUCCESS = 'CREATE_APOINTMENT_SUCCESS';
export const CREATE_APOINTMENT_FAIL = 'CREATE_APOINTMENT_FAIL';

export const CANCEL_APOINTMENT_REQUEST = 'CANCEL_APOINTMENT_REQUEST';
export const CANCEL_APOINTMENT_SUCCESS = 'CANCEL_APOINTMENT_SUCCESS';
export const CANCEL_APOINTMENT_FAIL = 'CANCEL_APOINTMENT_FAIL';

export const GET_APOINTMENT_BY_ID_REQUEST = 'GET_APOINTMENT_BY_ID_REQUEST';
export const GET_APOINTMENT_BY_ID_SUCCESS = 'GET_APOINTMENT_BY_ID_SUCCESS';
export const GET_APOINTMENT_BY_ID_FAIL = 'GET_APOINTMENT_BY_ID_FAIL';
