import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import MiniEventsPopupItem from './MiniEventsPopupItem';

const MiniEventsPopup = ({eventsSorted, closePopup, onEventAction}) => {

    const [title , setTitle] = useState('');
    const { t } = useTranslation();

    const onClosePopup = () => {
        closePopup();
    }

    useEffect(() => {
        setTitle(getTitle());
    }, [eventsSorted]);

    const getTitle = () => {
        if (eventsSorted.length === 1) {
            return `1 ${t('event')}`;
        } else {
            return `${eventsSorted.length} ${t('events')}`;
        }
    }

  return (
    <>
     <div className='mini-events-popup-background' onClick={() => onClosePopup()}></div>
     <div className='mini-events-popup'>
            <div className='mini-events-popup-header'>
                <div className='mini-events-popup-header-text'>
                    {title}
                </div>
                <a className='mini-events-popup-header-close' onClick={() => onClosePopup()}><img src='/assets/images/cross.svg' alt='close'/></a>
            </div>
            <div className='mini-events-popup-inner'>
                <div className='container-xxl mini-events-items'>
                    {eventsSorted?.map((event, index) => (
                        <MiniEventsPopupItem key={index} event={event} onEventAction={onEventAction}/>
                    ))}
                </div>
            </div>
        </div>
    </>
  );
};

export default MiniEventsPopup;