import moment from "moment";
import { getItemFromLocalStorage } from "./helpers";

export const newDate = new Date();

export const todayDay = newDate.getDate();

export const currentMonth = newDate.getMonth();

export const lastSevenDays = (date, formatType) => {
  let labels = [];

  const current = moment(date);
  let n = 7;
  while (n > 0) {
    labels.push(current.format(formatType));
    current.subtract(1, "day");
    n--;
  }
  labels = labels.reverse();
  return labels;
};

export const calendarPath = (userRole) => {
  if (userRole === ROLES.FREELANCE_DOCTOR) {
    return appRoutes.DOCTOR.CALENDAR;
  }

  if (userRole === ROLES.DOCTOR_CLINIC) {
    return "/patient-calendar";
  }

  return "/patient-calendar";
};

export const ROLES = Object.freeze({
  FREELANCE_DOCTOR: "freelance_doctor",
  DOCTOR_CLINIC: "clinic_doctor",
});

export const NOTIFICATIONS_TYPES = Object.freeze({
  SUCCESS: "success",
  ALERT: "alert",
  PAYMENT: "payment",
  ERROR: "error",
  REMINDER: "reminder",
  DOCUMENT: "document",

  sr: {
    SUCCESS: "success",
    ALERT: "alert",
    PAYMENT: "payment",
    ERROR: "error",
    REMINDER: "reminder",
    DOCUMENT: "document",
  },
  fr: {
    SUCCESS: "success",
    ALERT: "alert",
    PAYMENT: "payment",
    ERROR: "error",
    REMINDER: "reminder",
    DOCUMENT: "document",
  },
});

export const NOTIFICATIONS_TEXT = Object.freeze({
  DOCTOR: {
    PROPOSE_ONLINE: "doctor_propose_online",
    CANCEL_APPOINTEMNT: "doctor_cancel_appointment",
    CONFIRM_PRICE: "doctor_confirm_price",
    DOCUMENT: "doctor_new_document",
  },
  PATIENT: {
    PROPOSE_ONLINE: "patient_propose_online",
    CANCEL_APPOINTEMNT: "patient_cancel_appointment",
    CREATE_APPOINTMENT: "patient_create_appointment",
    MAKE_PAYMENT: "patient_make_payment",
    MAKE_FREE_PAYMENT: "patient_make_free_payment",
  },
  RECIVE_MESSAGE: "receive_message",
});

export const appRoutes = Object.freeze({
  PATIENT: {
    DOCUMENTS: "/patient-documents",
    PROFILE: "/patient-profile",
    SETTINGS: "/patient-settings",
    PRIVACY: "/patient-privacy",
    TERMS: "/patient-terms",
    HEALTH_OVERVIEW: "/health-overview",
    ONLINE_PAYMENTS: "/online-pacient-payments",
    PAYMENT: "/payment",
    REGISTER: "/register-patient",
  },
  DOCTOR: {
    DOCUMENTS: "/doctor-documents",
    SETTINGS: "/doctor-setings",
    CREATE_TERMIN: "/doctor-create-termin",
    CREATE_PRICE: "/price-create",
    CREATE_GROUP_TERMIN: "/doctor-create-group-termin",
    PRIVACY: "/doctor-privacy",
    TERMS: "/doctor-terms",
    PATIENTS_LIST: "/doctor-patients-list",
    ONLINE_PAYMENTS: "/doctor-online-payments",
    UPCOMING_TERMINS: "/doctor-upcoming-termins",
    UPCOMING_PRICES: "/doctor-upcoming-prices",
    PATIENT: {
      HEALTH_OVERVIEW: "/doctor-health-overview",
    },
  },
  CLINIC: {
    DOCTORS: "/clinic-doctors",
    PATIENTS: "/clinic-patients",
    DOCUMENTS: "/clinic-documents",
    SETTINGS: "/clinic-settings",
    PRIVACY: "/clinic-privacy",
    TERMS: "/clinic-terms",
    HEALTH_OVERVIEW: "/clinic-health-overview",
  },
  NOTIFICATION: "/notification",
  CONTACT: "/contact",
  CHAT: "/chat",
  DOCTOR_CLINIC: {
    REGISTER: "/register-clinic-doctor",
  },
  FREELANCE_DOCTOR: {
    REGISTER: "/register-freelance-doctor",
  },
  BOOK_YOUR_VISIT: "book-your-visit",
  LOGIN: "/login",
  PAYMENT_PLAN: "/doctor-payment-plan",
});

export const API_ROUTES = Object.freeze({
  PATIENT: {
    GET_HEALTH_OVERVIEW: `${process.env.REACT_APP_PRODUCTION_API}patients/healt-overview`,
    UPDATE_HEALTH_OVERVIEW: `${process.env.REACT_APP_PRODUCTION_API}patients/healt-overview-create-update`,
    UPLOAD_DOCUMENTS: `${process.env.REACT_APP_PRODUCTION_API}patients/upload`,
    GET_DOCUMENTS: `${process.env.REACT_APP_PRODUCTION_API}patients/documents`,
    GET_DOCTORS_FROM_DOCUMENT: `${process.env.REACT_APP_PRODUCTION_API}patients/doctors/document?`,
    GET_DOCTORS_FOR_CHAT: `${process.env.REACT_APP_PRODUCTION_API}patients/doctors/chat?`,
    SHARE_UNSHARE_DOCUMENTS: `${process.env.REACT_APP_PRODUCTION_API}patients/shared-unshared`,
    MAKE_PAYMENT: `${process.env.REACT_APP_PRODUCTION_API}patients/make-payment`,
    MAKE_FREE_PAYMENT: `${process.env.REACT_APP_PRODUCTION_API}patients/make-free-payment`,
    NOT_PAYED: `${process.env.REACT_APP_PRODUCTION_API}patients/payed-not-payed`,
    CONFIRM_CODE: `${process.env.REACT_APP_PRODUCTION_API}patients/shared-unshared-approve`,
    CANCEL_APPOINTEMNT: `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/cancel`,
    PROPOSE_STUDIO: `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/propose-studio`,
    PROPOSE_ONLINE: `${process.env.REACT_APP_PRODUCTION_API}patients/appointment/propose-online`,
  },
  DOCTOR: {
    LIST_PATIENTS: `${process.env.REACT_APP_PRODUCTION_API}doctors/list-patients`,
    PATIENT_DOCUMENTS: `${process.env.REACT_APP_PRODUCTION_API}doctors/patient-documents`,
    DOWNLOAD_DOCUMENT: `${process.env.REACT_APP_PRODUCTION_API}downoload`,
    UPLOAD_DOCUMENTS: `${process.env.REACT_APP_PRODUCTION_API}doctors/upload`,
    PAYED_NOT_PAYED: `${process.env.REACT_APP_PRODUCTION_API}patients/payed-not-payed`,
    SUBSCRIPTION_PLAN: `${process.env.REACT_APP_PRODUCTION_API}stripe/subscription-to-plan`,
    SUBSCRIPTION_PLAN_CHANGE_SUBSCRIPTION: `${process.env.REACT_APP_PRODUCTION_API}stripe/change-subscription`,
    SUBSCRIPTION_PLAN_PERMANENTLY: `${process.env.REACT_APP_PRODUCTION_API}stripe/buy-permanently-plan`,
    GET_SUBSCRIPTION: `${process.env.REACT_APP_PRODUCTION_API}stripe/get-subscription`,
    CANCEL_SUBSCRIPTION: `${process.env.REACT_APP_PRODUCTION_API}stripe/cancel-subscription`,
    CONFIRM_PAYMENT: `${process.env.REACT_APP_PRODUCTION_API}doctors/appointment/price-confirmation`,
    PROPOSE_DIFFERENT_PRICE: `${process.env.REACT_APP_PRODUCTION_API}doctors/appointment/different-price `,
    CREDIT_INFO: `${process.env.REACT_APP_PRODUCTION_API}stripe/doctor-credits`,
    FILTER_ONLINE_PAYMENTS: `${process.env.REACT_APP_PRODUCTION_API}admin/doctor-transactions`,
    CANCEL_APPOINTEMNT: `${process.env.REACT_APP_PRODUCTION_API}doctors/appointment/cancel`,
    DELETE_TERMIN: `${process.env.REACT_APP_PRODUCTION_API}doctors/delete-termin`,
  },
  PROFILE_INFO: `${process.env.REACT_APP_PRODUCTION_API}doctors/profile-by-uid`,
  GET_MESSAGES: `${process.env.REACT_APP_PRODUCTION_API}message/get`,
  CREATE_MESSAGE: `${process.env.REACT_APP_PRODUCTION_API}message/create`,
  GET_ALL_APPOINTMENTS: `${process.env.REACT_APP_PRODUCTION_API}filter-appointment-termins`,
  GET_NEXT_APPOINTMENTS: `${process.env.REACT_APP_PRODUCTION_API}filter-appointment-termins-by-doctor`,
  GET_NOTIFICATIONS: `${process.env.REACT_APP_PRODUCTION_API}notifications`,
  UPDATE_LANGUAGE: `${process.env.REACT_APP_PRODUCTION_API}users/update-language`,
});

export const SOCKET_ROUTES = Object.freeze({
  JOIN: "join-room",
  CHAT: "chat-doctor-patient",
  NOTIFICATIONS: "notifications-doctor-patient",
  EVENTS: "events",
});

export const socketURL = process.env.REACT_APP_SOCKET_URL;
export const socketToken = process.env.REACT_APP_USER_SOCKET;

export const STATUS = Object.freeze({
  PENDING: "pending",
  PROPOSE_STUDIO: "propose_studio",
  PROPOSE_ONLINE: "propose_online",
  ACCEPT_STUDIO: "accept_studio",
  ACCEPT_ONLINE: "accept_online",
  CONFIRM_PAYMENT: "confirm_payment",
  CUSTOMISE_PRICE: "customise_price",
  IS_PAYED: "is_payed",
  CANCEL_APPOINTEMNT: "cancel",
  CONFIRM_PRICE: "confirm_price",
});

export const calendarDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const mapStyle = [
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        saturation: "0",
      },
      {
        lightness: "0",
      },
      {
        gamma: "1.00",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
      {
        weight: "1.43",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
