import jwt from 'jwt-decode';
import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import accountIcon from '../assets/img/icons/account-icon.svg';
import calendarIcon from '../assets/img/icons/calendar-icon.svg';
import chatIcon from '../assets/img/icons/chat-icon.svg';
import chartIcon from '../assets/img/icons/chart-icon.svg';
import doctorIcon from '../assets/img/icons/doctor-icon.svg';
import notificationIcon from '../assets/img/icons/notification-icon.svg';
import paymentsIcon from '../assets/img/icons/payments-icon.svg';
import servicesIcon from '../assets/img/icons/services-icon.svg';
import dashboardIcon from '../assets/img/icons/dashboard-icon.svg';

import phoneImage_en from '../assets/img/en/offer_section_phone_image_en.png';
import phoneImage_fr from '../assets/img/fr/offer_section_phone_image_fr.png';

import appOverviewImage_en from '../assets/img/en/doctor_section_app_image_en.png';
import appOverviewImage_fr from '../assets/img/fr/doctor_section_app_image_fr.png';
import doctorsCircleImage from '../assets/img/doctor_section_circle_image.png';
import doctorsCalendar_en from '../assets/img/en/doctor_section_calendar_desktop_en.png';
import doctorsCalendar_fr from '../assets/img/fr/doctor_section_calendar_desktop_fr.png';

import firstCarouselImage from '../assets/img/carousel/drink_water_image.png';
import secondCarouselImage from '../assets/img/carousel/exercise_image.png';
import thirdCarouselImage from '../assets/img/carousel/third_image.png';

import desktopAdImage_en from '../assets/img/en/app_ad_desktop_en.png';
import desktopAdImage_fr from '../assets/img/fr/app_ad_desktop_fr.png';
import mobileAdImage_en from '../assets/img/en/app_ad_mobile_en.png';
import mobileAdImage_fr from '../assets/img/fr/app_ad_mobile_fr.png';

const HomeScreenV2 = () => {
    const {t, i18n} = useTranslation();

    const scrollRef = useRef();
    useEffect(() => {
        const el = scrollRef.current;
        if (el) {
            const onWheel = (e) => {
                if (e.deltaX == 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaX,
                });
            };
            el.addEventListener('wheel', onWheel);
            return () => el.removeEventListener('wheel', onWheel);
        }
    }, []);

    // dynamic links
    const userLogin = useSelector((state) => state.userLogin);
    const {userInfo} = userLogin;

    let token;
    if (userInfo) {
        token = jwt(userInfo.data);
    }

    const heroSection = (
        <section className='hero_section'>
            <h1 className='hero_section_title'>{t('welcome-title')}</h1>
            <p className='hero_section_description'>{t('home-first-message')}. </p>
            <div className='hero_section_buttons'>
                {token?.tokenParams?.usertype !== 'freelance_doctor' &&
                    token?.tokenParams?.usertype !== 'clinic_doctor' ? (
                        <Link className='btn btn-ocean' to='/book-your-visit'>
                            {t('find-your-doctor')}
                        </Link>
                    ):
                    (
                        <Link className='btn btn-ocean' to='/freelance-doctor-dashboard'>
                            {t('dashboard')}
                        </Link>
                    )
                }
            </div>

            <div className='hero_section_mobile_strip'>
                <div className='hero_section_mobile_strip_icon'>
                    <Link to='/dashboard'>
                        <img style={{width: '26px', height: '26px'}} src={dashboardIcon} alt='Chat Icon'/>
                    </Link>
                </div>
                <div className='hero_section_mobile_strip_icon'>
                    <Link to='/chat'>
                        <img src={chatIcon} alt='Chat Icon'/>
                    </Link>
                </div>
                <div className='hero_section_mobile_strip_icon'>
                    {token?.tokenParams?.usertype === 'patient' ? (
                        <Link to='/patient-calendar'>
                            <img src={calendarIcon} alt='Chat Icon'/>
                        </Link>
                    ) : token?.tokenParams?.usertype ? (
                        <Link to='/doctor-calendar'>
                            <img src={calendarIcon} alt='Chat Icon'/>
                        </Link>
                    ) : (
                        <Link to='/'>
                            <img src={calendarIcon} alt='Chat Icon'/>
                        </Link>
                    )}
                </div>
                <div className='hero_section_mobile_strip_icon'>
                    <Link to='/notification'>
                        <img src={notificationIcon} alt='Chat Icon'/>
                    </Link>
                </div>
            </div>
        </section>
    );

    const offeringsSection = () => {
        const actions = [
            {
                icon: accountIcon,
                iconColor: '#1EA6C4',
                title: t('personal-account'),
                desc: t('create-your-account-today'),
                link: '/',
            },
            {
                icon: servicesIcon,
                iconColor: '#4D6FBE',
                title: t('services'),
                desc: t('get-medical-assitence'), //spelling mistake
                link: '/',
            },
            {
                icon: doctorIcon,
                iconColor: '#79D2CC',
                title: t('doctor'),
                desc: t('doctor-message'),
                link: '/',
            },
            {
                icon: calendarIcon,
                iconColor: '#F5C84F',
                title: t('calendar'),
                desc: t('see-doctor-availability'),
                link: '/',
            },
            {
                icon: notificationIcon,
                iconColor: '#EC5C78',
                title: t('notifications'),
                desc: t('notifications-message'),
                link: '/',
            },
            {
                icon: paymentsIcon,
                iconColor: '#10213E',
                title: t('online-payments'),
                desc: t('compare-tarife'), //spelling mistake
                link: '/',
            },
            {
                icon: chartIcon,
                iconColor: '#EC5C78',
                title: t('health-overview'),
                desc: t('health-overview-message'), //spelling mistake
                link: '/',
            },
            {
                icon: chatIcon,
                iconColor: '#4066B7',
                title: t('chat'),
                desc: t('chat-message'),
                link: '/',
            },
        ];

        const renderActionCTA = (action, index) => (
            <div className='offer_section_cta' key={index}>
                <div
                    className='offer_section_cta_logo'
                    style={{backgroundColor: action.iconColor}}
                >
                    <img src={action.icon} alt={`${action.title} icon`}/>
                </div>
                <div className='offer_section_cta_title'>{action.title}</div>
                <div className='offer_section_cta_desc'>{action.desc}</div>
            </div>
        );

        return (
            <section className='offer_section'>
                <img
                    className='offer_section_image'
                    src={`${ i18n.language === 'en'? phoneImage_en : phoneImage_fr}`}
                    alt='Offering Phone'
                />
                <h1 className='offer_section_title'>{t('what-we-offer-you')}</h1>
                <div className='offer_section_actions'>
                    <div className='offer_section_actions_row'>
                        {actions.map((action, index) => renderActionCTA(action, index))}
                    </div>
                </div>
            </section>
        );
    };

    const doctorSection = (
        <section className='app_overview_section'>
            <div className='app_overview_section_first_row'>
                <div className='app_overview_section_first_row_column'>
                    <h2>{t('speak-to-one-doctor')}</h2>
                    <p>{t('follow-step')}</p>
                    <ul>
                        <li>{t('download-free-app')}</li>
                        <li>{t('sign-up-to-help')}</li>
                        <li>{t('find-perfect-doctor')}</li>
                        <li>{t('book-on-appointment')}</li>
                    </ul>
                </div>

                <div className='app_overview_section_first_row_column'>
                    &nbsp;
                    <img
                        className='phone_images'
                        src={`${ i18n.language === 'en'? appOverviewImage_en : appOverviewImage_fr}`}
                        alt='App Overview'
                    />
                </div>
            </div>

            <div className='app_overview_section_second_row'>
                <div className='app_overview_section_second_row_column'>
                    <img src={doctorsCircleImage} alt='Doctors circle'/>
                </div>
                <div className='app_overview_section_second_row_column'>
                    <h2 className='speak-doctors-subtitle'>{t('verified-doctor')}</h2>
                    <p>{t('verified-doctor-message')}.</p>
                </div>
            </div>

            <div className='app_overview_section_third_row'>
                <h2>{t('book-today')}</h2>
                <p>{t('say-goodbay')}</p>
                <img src={`${ i18n.language === 'en'? doctorsCalendar_en : doctorsCalendar_fr}`} alt='Doctor calendar'/>
            </div>
        </section>
    );

    const ourGoalSection = (
        <section className={`${ i18n.language === 'en'? 'our_goal_section_en' : 'our_goal_section_fr'}`}>
            <div className='our_goal_section_container'>
                <div className='our_goal_section_column'>
                    <h2>{t('faster-medical')}</h2>
                    <p>{t('we-belive-medical-help')}. </p>
                    <p>{t('that-right')}.</p>
                    <p>{t('additionaly-message')}!</p>
                </div>
                <div className='our_goal_section_column'>&nbsp;</div>
            </div>
        </section>
    );

    const recommendationSection = () => {
        const carouselItems = [
            {
                subtitle: 'drmesi-advise-subtitle',
                bgColor: '#fff',
                fontColor: '#000',
                title: 'drmesi-advise-title',
                image: firstCarouselImage,
                desc: 'drmesi-advise-desc',
            },
            {
                subtitle: 'drmesi-advise-subtitle',
                bgColor: '#4285F4',
                fontColor: '#fff',
                title: 'drmesi-advise-title',
                image: secondCarouselImage,
                desc: 'drmesi-health-desc',
            },
            {
                subtitle: 'drmesi-prevtion-subtitle',
                bgColor: '#1EA6C4',
                fontColor: '#fff',
                title: 'drmesi-prevtion-title',
                image: thirdCarouselImage,
                desc: 'drmesi-prevtion-desc',
            },
        ];

        const renderCarouselItem = (item, index) => (
            <div
                className='carousel_item'
                key={index}
                style={{backgroundColor: item.bgColor}}
            >
                <div
                    className='carousel_item_subtitle'
                    style={{color: item.fontColor}}
                >
                    {t(item.subtitle)}
                </div>
                <div className='carousel_item_title' style={{color: item.fontColor}}>
                    {t(item.title)}
                </div>
                <div className='carousel_item_image'>
                    <img src={item.image} alt='carousel item background'/>
                </div>
                <div className='carousel_item_desc'>{t(item.desc)}</div>
            </div>
        );

        return (
            <section className='recommendation_section'>
                <div className='carousel'>
                    <div className='carousel_inner'>
                        <div className='carousel_items' ref={scrollRef}>
                            {carouselItems.map((item, index) =>
                                renderCarouselItem(item, index)
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    const appAdSection = (
        <section className='app_ad_section'>
            <div className='app_ad_section_column'>
                <img src={`${ i18n.language === 'en'? desktopAdImage_en : desktopAdImage_fr}`} alt='desktop ad'/>
                <h2>{t('run-in-browser')}</h2>
                <p>{t('use-online-platform')}!</p>
                {/* <div className='d-flex justify-content-center run-browser'>
                    <button>{t('run-in-browser')}</button>
                </div> */}
            </div>
            <div className='app_ad_section_column'>
                <img src={`${ i18n.language === 'en'? mobileAdImage_en : mobileAdImage_fr}`} alt='mobile ad'/>
                <h2>{t('always-with-you')}</h2>
                <p className='text-center'>{t('free-to-download')}.</p>
                {/* <div
                    className='d-flex flex-row justify-content-center'
                    style={{marginTop: '10px'}}
                >
                    <div className='pwa'>
                        <img
                            className='pwamainimg'
                            src='/assets/images/home/pwa.svg'
                            alt='Pwa'
                            title='PWA'
                        />
                        <img
                            className='pwaimg'
                            src='/assets/images/home/pwa2.svg'
                            alt='Pwa'
                            title='PWA'
                        />
                    </div>
                </div> */}
            </div>
        </section>
    );

    return (
        <main className='homepage_wrapper'>
            {heroSection}
            {offeringsSection()}
            {doctorSection}
            {ourGoalSection}
            {recommendationSection()}
            {appAdSection}
        </main>
    );
};

export default HomeScreenV2;
