// screens/Dashboard.js
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


// dashboardConfig.js

const Dashboard = () => {
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const token = jwt(userInfo.data);
    const userType = token.tokenParams?.usertype;
    
    const dashboardIcons = [
        {
            icon: 'doctor-icon.svg',
            label: 'book-your-visit',
            path: '/book-your-visit',
            visibleTo: ['patient'],
            cssClass: 'dashboard'
        },
        {
            icon: 'calendar.svg',
            label: 'calendar',
            path: '/calendar', // Update as necessary for other users
            visibleTo: ['freelance_doctor', 'clinic_doctor', 'clinic_admin'],
            cssClass: 'calendar'
        },
        {
            icon: 'calendar.svg',
            label: `${userType === 'patient' ? 'patient-' : '' }calendar`,
            path: '/calendar', // Update as necessary for other users
            visibleTo: ['patient'],
            cssClass: 'calendar'
        },
        {
            icon: 'settings.svg',
            label: 'settings',
            path: '/settings',
            visibleTo: ['freelance_doctor', 'clinic_doctor', 'clinic_admin'],
            cssClass: 'settings'
        },
        {
            icon: 'notifications.svg',
            label: 'notifications',
            path: '/notification',
            visibleTo: ['patient', 'freelance_doctor', 'clinic_doctor', 'clinic_admin'],
            cssClass: 'dashboard'
        },
        { breakAfter: true },
        {
            icon: 'chat.svg',
            label: 'chat',
            path: '/chat',
            visibleTo: ['patient', 'freelance_doctor', 'clinic_doctor'],
            cssClass: 'settings'
        },
        {
            icon: 'wallet.svg',
            label: 'online-payments',
            path: '/online-payments',
            visibleTo: ['freelance_doctor', 'patient'],
            cssClass: 'privacy'
        },
        {
            icon: 'chart.svg',
            label: 'health-overview',
            path: '/health-overview',
            visibleTo: [ 'patient'],
            cssClass: 'chart'
        },       
        {
            icon: 'chart.svg',
            label: 'patient-health',
            path: '/doctor-patients-list',
            visibleTo: ['freelance_doctor', 'clinic_doctor'],
            cssClass: 'chart'
        },
        {
            icon: 'personal.svg',
            label: 'personal-info',
            path: `/${userType === 'patient' ? 'patient-profile' : 'doctor-profile' }`,
            visibleTo: ['freelance_doctor', 'clinic_doctor', 'patient'],
            cssClass: 'personal'
        },
        { breakAfter: true },
        {
            icon: 'document.svg',
            label: `${userType === 'patient' ? 'patient-' : '' }documents`,
            path: `/${userType === 'patient' ? 'patient-documents' : 'doctor-patients-list' }`,
            visibleTo: ['patient', 'freelance_doctor', 'clinic_doctor'],
            cssClass: 'document'
        },
        {
            icon: 'help.svg',
            label: `${userType === 'patient' ? 'patient-' : '' }faq`,
            path: `/${userType === 'patient' ? 'patient-' : '' }faq`,
            visibleTo: ['patient', 'freelance_doctor', 'clinic_doctor'],
            cssClass: 'help'
        },
        {
            icon: 'privacy.svg',
            label: `${userType === 'patient' ? 'patient-' : '' }privacy`,
            path: '/privacy',
            visibleTo: ['patient', 'freelance_doctor', 'clinic_doctor'],
            cssClass: 'privacy'
        },
        {
            icon: 'doctor-icon.svg',
            label: 'all-doctors',
            path: '/doctors',
            visibleTo: [ 'clinic_admin', 'superadmin'],
            cssClass: 'calendar'
        },
        {
            icon: 'calendar.svg',
            label: 'all-clinics',
            path: '/clinics',
            visibleTo: ['superadmin'],
            cssClass: 'chart'
        },
        {
            icon: 'calendar.svg',
            label: 'patients',
            path: '/patients',
            visibleTo: [ 'clinic_admin', 'superadmin'],
            cssClass: 'chart'
        },
    ];

    
    const { t } = useTranslation();
    useEffect(() => {
        console.log("Dashboard mounted or userInfo changed.");
    }, [userInfo]);
    if (!userInfo) {
        console.log('No user info available.');
        return <div>Please log in to view this page.</div>;
    }

    console.log('TOKEN:', token);  // This should show the decoded token
    console.log('User Type:', userType);

    return (
        <div className='dashboard-container'>
            <div className='container-xxl'>
            <h2 className='col-12 text-center'>{t('welcome-dashboard')}</h2>
                <div className='row row-flex justify-content-center'>
                    {dashboardIcons.map((icon, index) => {
                        if (icon.breakAfter) {
                            return <div key={index} className='w-100 d-none d-lg-block'></div>;
                        }
                        if (icon.visibleTo.includes(userType) || icon.visibleTo.includes('all')) {
                            return (
                                <div key={index} className={`col-xxl-2 col-md-3 col-sm-6 col-6`}>
                                    <Link to={icon.path} className={`offer_wrapper ${icon.cssClass}`}>
                                        <div className='offer'>
                                            <div className={`offer-icon ${icon.cssClass}`}>
                                                <img src={`/assets/images/${icon.icon}`} alt={t(`${icon.label}`)} />
                                            </div>
                                            <div className='offer-info'>
                                                <h3>{t(`${icon.label}`)}</h3>
                                                <p>{t(`${icon.label}-message`)}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
