import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import Message from '../../components/Message';
import { useOptions } from '../../constants/payment';
import {
  API_ROUTES
} from '../../helpers/constants';
import { getItemFromLocalStorage } from '../../helpers/helpers';

let stripeKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(stripeKey);

const StripeForm = ({ submitePayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { t, i18n } = useTranslation();
  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardNumberElement);

    // stripe's create payment method Api
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('error:', error);
    } else {
      submitePayment(paymentMethod);
    }
  };

  return (
    <div>
      <div className='mb-10'>
        <div className='mb-4'>
          <p className='text-grey'>{t('card-number')}</p>
          <CardNumberElement options={options} />
        </div>

        <div className='mb-4'>
          <p className='text-grey'>{t('expiration-date')}</p>
          <CardExpiryElement options={options} />
        </div>

        <div className=''>
          <p className='text-grey'>CVC</p>
          <CardCvcElement options={options} />
        </div>
      </div>

      <button
        className='btn btn-darkblue'
        disabled={!stripe || !elements}
        onClick={handleSubmit}
      >
        {t('pay')}
      </button>
    </div>
  );
};

const PermamentlyPlanScreen = () => {
  const location = useLocation();
  let history = useNavigate();
  const plan = location.state?.plan;
  //   console.log('PLAN', plan);

  const [loaader, setLoading] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const [messageSuccess, setMessageSuccess] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const submitPayment = async (data) => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.DOCTOR.SUBSCRIPTION_PLAN_PERMANENTLY,
        {
          permanently_plan_id: plan.id,
          stripe_payment_method_id: data.id,
        },
        config
      )
      .then((response) => {
        if (response?.data?.data === 'error') {
          setLoading(false);
          setMessageSuccess(null);
          return setMessageError(`Something gone wrong`);
        }
        setMessageSuccess(`Success`);
        setMessageError(null);
        setLoading(false);
        // history('/doctor-payment-plan');
      });
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className=''>
        <div className='container-xxl'>
          <div className='container-fluid page-heading'>
            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Link to='/doctor-payment-plan' className='back-to'>
                  {t('back')}
                </Link>
              </div>
              <div className='col-sm-4 col-12 text-center'>
                <div className='user-register-label nature'>
                  <img src='/assets/images/wallet.svg' />
                  <span>{t('online-payments')}</span>
                </div>
              </div>
            </div>
          </div>
          {loaader && <LoadingIndicator />}
          <div className='row'>
            <div className='col-xxl-6 col-md-6 col-12 mx-auto stripe_form_wrapper'>
              <h2 className='title'>
                {t('you-choise-plan')}: {plan.currency === 'usd' && '$'}
                {plan.amount / 100} {plan.nickname}
              </h2>
              <Elements stripe={stripePromise} options={{}}>
                <StripeForm submitePayment={submitPayment} />
              </Elements>
              <div className='second_payment_option'>
              <p>
                {t('in case you wish to pay with a different payment method please contact our support staff, they will help you set up the payment')}
              </p>
            </div>
            </div>
          </div>
          {messageSuccess && (
            <Message variant='success'>
              {messageSuccess} {t('click-to-go')}{' '}
              <Link to='/doctor-payment-plan'>{t('payment-plan')}</Link>
            </Message>
          )}
          {messageError && <Message variant='danger'>{messageError}</Message>}
        </div>
      </div>
    </>
  );
};

export default PermamentlyPlanScreen;
