import axios from 'axios';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API_ROUTES } from '../helpers/constants';
import { getItemFromLocalStorage } from '../helpers/helpers';
import DocumentHeader from './Documents/DocumentHeader';

const TwoFactorAuthentication = () => {
  const { t, i18n } = useTranslation();
  const { document_id, user_ids, patient_id } = useParams();
  let navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const [code, setCode] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
  });

  const onChangeCodeOne = (event) => {
    if (ref2.current) {
      ref2.current.focus();
    }
    const prevCode = { ...code };
    prevCode.code1 = event.target.value;
    setCode(prevCode);
  };

  const onChangeCodeTwo = (event) => {
    if (ref3.current) {
      ref3.current.focus();
    }
    const prevCode = { ...code };
    prevCode.code2 = event.target.value;
    setCode(prevCode);
  };

  const onChangeCodeThree = (event) => {
    if (ref4.current) {
      ref4.current.focus();
    }
    const prevCode = { ...code };
    prevCode.code3 = event.target.value;
    setCode(prevCode);
  };

  const onChangeCodeFour = (event) => {
    const prevCode = { ...code };
    prevCode.code4 = event.target.value;
    setCode(prevCode);
  };

  const onCodeConfirm = async () => {
    setIsInvalid(false);
    await axios
      .post(
        API_ROUTES.PATIENT.CONFIRM_CODE,
        {
          share_document_code:
            code.code1 + code.code2 + code.code3 + code.code4,
          document_id: document_id,
          user_ids: user_ids === 'empty' ? [] : user_ids.split(','),
          patient_id: patient_id,
        },
        config
      )
      .then((res) => {
        if (res.data.status_code === 400) {
          setIsInvalid(true);
          return;
        }
        setIsSuccess(true);
        // navigate('/patient-documents');
      })
      .catch(() => {
        alert(t('wrong-code'));
      });
  };

  return (
    <>
      <DocumentHeader />
      <div className='fa2_page container-xxl'>
        {!isSuccess && (
          <>
            <h1 className='text-center authentication-title'>
              {t('two-factor')}
            </h1>
            <p className='text-center authentication-description'>
              {t('two-factor-messag')}
            </p>
            <section className='input_section'>
              <input
                value={code.code1}
                onChange={onChangeCodeOne}
                type='text'
                maxLength={1}
              />
              <input
                value={code.code2}
                onChange={onChangeCodeTwo}
                ref={ref2}
                type='text'
                maxLength={1}
              />
              <input
                value={code.code3}
                onChange={onChangeCodeThree}
                ref={ref3}
                type='text'
                maxLength={1}
              />
              <input
                value={code.code4}
                onChange={onChangeCodeFour}
                ref={ref4}
                type='text'
                maxLength={1}
              />
            </section>
            {isInvalid && (
              <div className='text-center invalid_code'>
                <p>{t('invalid-code')}</p>
              </div>
            )}
            <div className='d-flex justify-content-center share-btn-container'>
              <button
                onClick={onCodeConfirm}
                className='btn btn-flowers'
                type='button'
              >
                {t('share')}
              </button>
            </div>
          </>
        )}
        {isSuccess && (
          <>
            <h1 className='text-center authentication-title'>
              {t('share-success')}
            </h1>
            <p className='text-center authentication-description'>
              {t('share-message')}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default TwoFactorAuthentication;
