import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userLoginReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  showUserReducer,
  contactUsReducer,
} from './reducers/userReducers';
import {
  patientAcceptApointmentReducer,
  patientRefuseApointmentReducer,
  patientRegisterReducer,
  patientUdpateProfileReducer,
} from './reducers/patientReducers';
import {
  clinicDoctorRegisterReducer,
  doctorCreateTerminReducer,
  doctorGetProfileReducer,
  doctorProposeOnlineReducer,
  doctorProposePhysicalReducer,
  doctorPublicScreenReducer,
  doctorRefuseApointmentReducer,
  doctorUdpateProfileReducer,
  freelenceDoctorCreatePriceReducer,
  freelenceDoctorRegisterReducer,
  getAllSpecializationReducer,
  getDoctorByUidScreenReducer,
} from './reducers/doctorReducers';
import {
  cancelApointmentReducer,
  createApointmentReducer,
  getAllApointmentReducer,
  getAllCityReducer,
  getApointmentByIdReducer,
} from './reducers/apointmentReducers';
import {
  calendarForMonthReducer,
  upcomingEventsReducer,
} from './reducers/calendarReducers';
import { paymentPlanReducer } from './reducers/paymentReducers';
import {
  adminChangePorfileStatusDoctorsReducer,
  adminLoginDoctorReducer,
  getAdminAllDoctorsReducer,
  getAdminEarningsDoctorsReducer,
} from './reducers/adminReducers';
import { clinicListReducer } from './reducers/clinicReducer';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  patientRegister: patientRegisterReducer,
  patientUpdateProfile: patientUdpateProfileReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetForgotenPassword: userResetPasswordReducer,
  allSpecialization: getAllSpecializationReducer,
  allCity: getAllCityReducer,
  allApointment: getAllApointmentReducer,
  doctor: getApointmentByIdReducer,
  createApointment: createApointmentReducer,
  cancelApointment: cancelApointmentReducer,
  clinicDoctorRegister: clinicDoctorRegisterReducer,
  freelenceDoctorRegister: freelenceDoctorRegisterReducer,
  doctorUpdateProfile: doctorUdpateProfileReducer,
  doctorCreateTermin: doctorCreateTerminReducer,
  doctorProfile: doctorGetProfileReducer,
  freelenceDoctorCreatePrice: freelenceDoctorCreatePriceReducer,
  calendarInfo: calendarForMonthReducer,
  clinicList: clinicListReducer,
  upcomingEvents: upcomingEventsReducer,
  patientAcceptApointment: patientAcceptApointmentReducer,
  patientRefuseApointment: patientRefuseApointmentReducer,
  doctorProposeOnline: doctorProposeOnlineReducer,
  doctorProposePhysical: doctorProposePhysicalReducer,
  doctorRefuseApointment: doctorRefuseApointmentReducer,
  paymentPlan: paymentPlanReducer,
  showUser: showUserReducer,
  contactUs: contactUsReducer,
  publicDoctorScreen: doctorPublicScreenReducer,
  adminAllDoctors: getAdminAllDoctorsReducer,
  adminEarningsDoctor: getAdminEarningsDoctorsReducer,
  adminChangePoofileStatusDoctor: adminChangePorfileStatusDoctorsReducer,
  adminLoginDoctor: adminLoginDoctorReducer,
  getDoctorByUid: getDoctorByUidScreenReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
