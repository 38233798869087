import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { t, i18n } = useTranslation();

  return (
    <div className='container-xxl page-heading'>
      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
          <Link to='/dashboard' className='back-to'>
              {t('back-to-dashboard')}
          </Link>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center'>
          <div className='user-register-label sunset'>
            <img src='/assets/images/chart.svg' alt='Chart' />
            <span>{t('patient')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
