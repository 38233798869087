import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { months } from "../../helpers/constants";
import CreateAppointmentButton from "./CreateAppointmentButton";
import { formatCustomCalendar } from "../../helpers/helpers";
import { useWindowSize } from "brink-hooks";
import Hammer from "react-hammerjs";

import moment from "moment";

import MiniEvents from "./MiniEvents";

const Calendar = ({
  calendarInfo,
  onChangeMonth,
  formatCalendarFromMonth,
  onEventAction,
  onCreateAppointment,
  isDoctor,
}) => {
  const [currentYEAR, setCurrentYear] = useState(new Date().getFullYear());
  const [calendar, setCalender] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(0);
  const calendarRef = useRef();
  const { width } = useWindowSize();

  const mobileView = width < 800;

  const formatCalendar = useCallback(
    (currentMounthIndex) => {
      setCurrentMonth(currentMounthIndex);
      return formatCustomCalendar(currentMounthIndex, currentYEAR, mobileView);
    },
    [calendar, mobileView]
  );

  const mergeCalendarWithAppointments = (calendar, events) => {
    events.map((dummy) => {
      const findCalendarIndex = calendar.findIndex(
        (element) =>
          element.value == moment(new Date(dummy.start_date)).date() &&
          element.active
      );
      calendar[findCalendarIndex].events.appointments.push(dummy);
    });
    return calendar;
  };

  const mergeCalendarWithTermins = (calendar, events) => {
    events?.map((dummy) => {
      const findCalendarIndex = calendar.findIndex(
        (element) =>
          element.value == moment(new Date(dummy.start_date)).date() &&
          element.active
      );
      calendar[findCalendarIndex]?.events?.termins.push(dummy);
    });
    return calendar;
  };

  useEffect(() => {
    let calendarTemp = formatCalendar(formatCalendarFromMonth);
    if (calendarInfo.appointments?.length > 0) {
      calendarTemp = mergeCalendarWithAppointments(
        calendarTemp,
        calendarInfo.appointments
      );
    }
    if (calendarInfo.termins?.length > 0) {
      calendarTemp = mergeCalendarWithTermins(
        calendarTemp,
        calendarInfo.termins
      );
    }
    setCalender(calendarTemp);
  }, [calendarInfo, mobileView]);

  const onBackMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYEAR - 1);
      setCurrentMonth(11);
      onChangeMonth(11);
      return;
    }

    setCurrentMonth((currentState) => currentState - 1);
    onChangeMonth(currentMonth - 1);
  };

  const onNextMonth = () => {
    //LAST MONTH
    if (currentMonth === 11) {
      setCurrentYear(currentYEAR + 1);
      setCurrentMonth(0);
      onChangeMonth(0);
      return;
    }
    setCurrentMonth((currentState) => currentState + 1);
    onChangeMonth(currentMonth + 1);
    // scroll to top of the calendar
  };

  useEffect(() => {
    if (calendarRef.current) {
      const miniEventEl = calendarRef.current.querySelectorAll(
        ".mini-event-container"
      );
      let firstEvent;
      if (miniEventEl.length > 0 && miniEventEl[0] !== undefined) {
        firstEvent = miniEventEl[0];
      }

      if (firstEvent && currentMonth !== new Date().getMonth()) {
        firstEvent.scrollIntoView({ behavior: "smooth", block: "nearest" });
      } else {
        if (currentMonth === new Date().getMonth()) {
          const today = new Date().getDate();
          const todayIndex = calendar.findIndex(
            (element) => parseInt(element.value) === today
          );

          if (calendarRef.current.children[0].children[todayIndex])
            calendarRef.current.children[0].children[todayIndex].scrollIntoView(
              {
                behavior: "smooth",
                block: "nearest",
              }
            );
        } else {
          calendarRef.current.children[0].children[0]?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }
  }, [calendar]);

  const handleSwipe = (e) => {
    if (e.direction === 4) {
      onBackMonth();
    } else if (e.direction === 2) {
      onNextMonth();
    }
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="calendar-switcher">
        <div onClick={onBackMonth}>
          <img
            className="cp"
            src="/assets/images/arrow-left-back.svg"
            alt="Back"
            title="Back"
          />
        </div>
        <h3 className="month-title">
          {t(months[currentMonth])} {currentYEAR}
        </h3>
        <div onClick={onNextMonth}>
          <img
            className="cp"
            src="/assets/images/arrow_right.svg"
            alt="Next"
            title="Next"
          />
        </div>
      </div>
      {isDoctor && (
        <CreateAppointmentButton onCreateAppointment={onCreateAppointment} />
      )}

      <div ref={calendarRef}>
        <Hammer onSwipe={handleSwipe}>
          <div className="week">
            {calendar.map((calendarItem, day) => {
              return (
                <div
                  key={day}
                  className={
                    !calendarItem.active
                      ? "inactive-day calendar-day"
                      : " calendar-day cp"
                  }
                >
                  <div className="day-title">
                    <div className="day-number day-number-name">
                      {t(calendarItem.name && calendarItem.name.toUpperCase())}
                    </div>
                    <div className="day-number day-date">
                      {calendarItem.value}
                    </div>
                  </div>
                  {(calendarItem?.events?.appointments.length > 0 ||
                    calendarItem?.events?.termins.length > 0) && (
                    <MiniEvents
                      key={day}
                      events={calendarItem?.events}
                      onEventAction={onEventAction}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Hammer>
      </div>
    </>
  );
};

export default Calendar;
