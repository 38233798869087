import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/home.css';

const HomeScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className='home-main_section'>
        <h1 className='home-main_title'>{t('welcome-title')}</h1>
        <p className='home-main_information'>{t('home-first-message')}. </p>
        <div className='home-buttons'>
          <div className='find_apply-buttons'>
            <Link to='/book-your-visit'>
              <button className='btn btn-ocean find_doctor_btn'>
                {t('find-your-doctor')}
              </button>
            </Link>
            <Link to='/doctor-register'>
              <button className='btn btn-sunset ml2'>
                {t('apply-as-doctor')}
              </button>
            </Link>
          </div>
          <div className='marked'>
            <img src='/assets/images/home/mark.svg' alt='Mark' title='Mark' />
          </div>
        </div>
      </section>
      <section className='justify-content-center d-flex home-landing'>
        <img
          className='w-100'
          src='/assets/images/home/descriptive.svg'
          alt='Descriptive'
          title='Descriptive'
        />
      </section>
      <section className='mobile-landing'>
        <div
          style={{ padding: '0px', marginBottom: 0 }}
          className='offer dashboard'
        >
          <div className='offer-icon dashboard'>
            <img src='assets/images/calendar.svg' alt='' />
          </div>
        </div>
        <div
          style={{ padding: '0px', marginBottom: 0 }}
          className='offer settings'
        >
          <div className='offer-icon settings'>
            <img src='/assets/images/chat/chat-bot.svg' alt='' />
          </div>
        </div>
        <div
          style={{ padding: '0px', marginBottom: 0 }}
          className='offer calendar'
        >
          <div className='offer-icon calendar'>
            <img src='assets/images/calendar.svg' alt='' />
          </div>
        </div>
        <div
          style={{ padding: '0px', marginBottom: 0 }}
          className='offer notification'
        >
          <div className='offer-icon notification'>
            <img src='assets/images/notifications.svg' alt='' />
          </div>
        </div>
      </section>
      <h2 className='home-main_title wht-offer'>{t('what-we-offer-you')}</h2>

      <div className='dashboard-container wht-offer-container'>
        <div className='container-xxl offer-modify-container-main'>
          <div className='row row-flex justify-content-center offer-modify-container'>
            <div className='col-xxl-2 col-md-3 col-sm-6 col-6'>
              <Link to={'/'}>
                <div className='offer personal'>
                  <div className='offer-icon personal'>
                    <img src='assets/images/personal.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to='/'>{t('personal-account')}</Link>
                    </h3>
                    <p>{t('create-your-account-today')}.</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xxl-2 col-md-3 col-sm-6 col-6'>
              <Link to={'/'}>
                <div className='offer personal'>
                  <div className='offer-icon personal'>
                    <img src='assets/images/home/pills.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to='/'>{t('services')}</Link>
                    </h3>
                    <p>{t('get-medical-assitence')}.</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xxl-2 col-md-3 col-sm-6 col-6'>
              <div className='offer wallet'>
                <div className='offer-icon wallet'>
                  <img src='assets/images/home/doctor-headphones.svg' alt='' />
                </div>
                <div className='offer-info'>
                  <h3>
                    <Link to='/'>{t('doctor')}</Link>
                  </h3>
                  <p>{t('doctor-message')}.</p>
                </div>
              </div>
            </div>

            <div className='col-xxl-2 col-md-3 col-sm-6  col-6'>
              <Link to='/'>
                <div className='offer calendar'>
                  <div className='offer-icon calendar'>
                    <img src='assets/images/calendar.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to='/'>{t('calendar')}</Link>
                    </h3>
                    <p>{t('see-doctor-availability')}</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xxl-2 col-md-3 col-sm-6 col-6'>
              <Link to={'/'}>
                <div className='offer chart'>
                  <div className='offer-icon chart'>
                    <img
                      src='assets/images/notifications.svg'
                      alt='Chart'
                      title='Chart'
                    />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to={'#'}>{t('notifications')}</Link>
                    </h3>
                    <p>{t('notifications-message')}</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xxl-2 col-md-3 col-sm-6 col-6 d-block d-lg-none'>
              <Link to={'/'}>
                <div className='offer help'>
                  <div className='offer-icon help'>
                    <img src='assets/images/wallet.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to={'#'}>{t('online-payments')}</Link>
                    </h3>
                    <p>{t('compare-tarife')}</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-xxl-2 col-md-3 col-sm-6 col-6 d-block d-lg-none'>
              <Link to={'/'}>
                <div className='offer chart'>
                  <div className='offer-icon chart'>
                    <img
                      src='assets/images/chart.svg'
                      alt='Chart'
                      title='Chart'
                    />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to={'#'}>{t('health-overview')}</Link>
                    </h3>
                    <p>{t('health-overview-message')}.</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-xxl-2 col-md-3 col-sm-6 col-6 d-block d-lg-none'>
              <Link to={'/'}>
                <div className='offer personal'>
                  <div className='offer-icon personal'>
                    <img src='assets/images/chat/chat-bot.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to='#'>{t('chat')}</Link>
                    </h3>
                    <p>{t('chat-message')}.</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className='row row-flex justify-content-center'>
            <div className='col-xxl-2 col-md-3 col-sm-6 col-6 d-none d-lg-block'>
              <Link to={'/'}>
                <div className='offer help'>
                  <div className='offer-icon help'>
                    <img src='assets/images/wallet.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to={'#'}>{t('online-payments')}</Link>
                    </h3>
                    <p>{t('compare-tarife')}.</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-xxl-2 col-md-3 col-sm-6 col-6 d-none d-lg-block'>
              <Link to={'/'}>
                <div className='offer chart'>
                  <div className='offer-icon chart'>
                    <img
                      src='assets/images/chart.svg'
                      alt='Chart'
                      title='Chart'
                    />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to={'#'}>{t('health-overview')}</Link>
                    </h3>
                    <p>{t('health-overview-message')}.</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-xxl-2 col-md-3 col-sm-6 col-6 d-none d-lg-block'>
              <Link to={'/'}>
                <div className='offer personal'>
                  <div className='offer-icon personal'>
                    <img src='assets/images/chat/chat-bot.svg' alt='' />
                  </div>
                  <div className='offer-info'>
                    <h3>
                      <Link to='#'>{t('chat')}</Link>
                    </h3>
                    <p>{t('chat-message')}.</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <section className='speak-doctors'>
        <div className='speak-doctors-left'>
          <h2 className='speak-doctors-subtitle'>
            {t('speak-to-one-doctor')}{' '}
          </h2>
          <p>{t('follow-step')}: </p>
          <ol className='speak-doctors_li-active'>
            <li>{t('download-free-app')}</li>
            <li>{t('sign-up-to-help')}</li>
            <li>{t('find-perfect-doctor')}</li>
            <li>{t('book-on-appointment')}</li>
          </ol>
        </div>
        <div className='speak-doctors-right'>
          <img src='/assets/images/home/speak_out.svg' />
        </div>
      </section>
      <section className='doctors_speak-container'>
        <div>
          <img src='/assets/images/home/doctors.svg' />
        </div>

        <div className='verified-doctors-speci-des'>
          <h2 style={{ marginTop: '20px' }} className='speak-doctors-subtitle'>
            {t('verified-doctor')}
          </h2>
          <p>{t('verified-doctor-message')}.</p>
        </div>
      </section>
      <section className='book_an_appointment_home'>
        <h2
          style={{ marginTop: '20px', marginBottom: '25px' }}
          className='speak-doctors-subtitle'
        >
          {t('book-today')}
        </h2>
        <p>{t('say-goodbay')}</p>
        <div className='p-4'>
          <div className=' d-sm-none d-block'>
            <img
              src='/assets/images/home/calendar-mob.svg'
              className='w-100 h-100'
            />
            <h3 className='text-center' style={{ marginTop: '20px' }}>
              {t('upcoming-events')}
            </h3>
            <p>{t('upcoming-events-home-message')}.</p>
            <img
              src='/assets/images/home/upc-events.svg'
              className='w-100 h-100'
            />
          </div>
          <div className='d-none d-sm-block'>
            <img
              className='w-100'
              src='/assets/images/home/calendar-desktop.png'
            />
          </div>
        </div>
      </section>
      <section className='faster-medical-container'>
        <div className='faster-medical-container-left'>
          <h2 className='speak-doctors-subtitle'>{t('faster-medical')}</h2>
          <p>{t('we-belive-medical-help')}. </p>

          <p>{t('that-right')}.</p>
          <p>{t('additionaly-message')}!</p>
        </div>
        <div className='d-flex justify-content-center'>
          <img src='/assets/images/home/faster-medical.svg' />
        </div>
      </section>

      <div className='advise-box-container'>
        <section className='advise-box'>
          <div className='advise-box--header'>
            <h3 className='advise-box--title'>{t('advise')}</h3>
            <h2 className='advise-box--subtitle'>{t('drink-water')}</h2>
            <img
              alt='drink_water'
              className='advise-img'
              src='/assets/images/home/carousel/drink_water.svg'
            />
          </div>
          <div className='advise-box--description'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
          </div>
        </section>
        <section className='advise-box'>
          <div className='advise-box--header'>
            <h3 className='advise-box--title'>{t('advise')}</h3>
            <h2 className='advise-box--subtitle'>{t('drink-water')}</h2>
            <img
              alt='drink_water'
              className='advise-img'
              src='/assets/images/home/carousel/drink_water.svg'
            />
          </div>
          <div className='advise-box--description'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
          </div>
        </section>
        <section className='advise-box'>
          <div className='advise-box--header'>
            <h3 className='advise-box--title'>{t('advise')}</h3>
            <h2 className='advise-box--subtitle'>{t('drink-water')}</h2>
            <img
              alt='drink_water'
              className='advise-img'
              src='/assets/images/home/carousel/drink_water.svg'
            />
          </div>
          <div className='advise-box--description'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
          </div>
        </section>
      </div>

      <section className='run-devices'>
        <div>
          <img src='/assets/images/home/run-in-browser.svg' />
          <h2 className='speak-doctors-subtitle text-center'>
            {t('run-in-browser')}
          </h2>
          <p className='text-center'>{t('use-online-platform')}!</p>
          <div className='d-flex justify-content-center run-browser'>
            <button>{t('run-in-browser')}</button>
          </div>
        </div>
        <div>
          <img src='/assets/images/home/always-with-you.svg' />
          <h2 className='speak-doctors-subtitle text-center'>
            {t('always-with-you')}
          </h2>
          <p className='text-center'>{t('free-to-download')}.</p>
          <div
            className='d-flex flex-row justify-content-center'
            style={{ marginTop: '10px' }}
          >
            <div className='pwa'>
              <img
                className='pwamainimg'
                src='/assets/images/home/pwa.svg'
                alt='Pwa'
                title='PWA'
              />
              <img
                className='pwaimg'
                src='/assets/images/home/pwa2.svg'
                alt='Pwa'
                title='PWA'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeScreen;
