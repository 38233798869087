import axios from 'axios';
import {
  PAYMENT_PLAN_FAIL,
  PAYMENT_PLAN_REQUEST,
  PAYMENT_PLAN_SUCCESS,
} from '../constants/payment';

export const paymentPlanActions = () => async (dispatch) => {
  try {
    dispatch({
      type: PAYMENT_PLAN_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(`${process.env.REACT_APP_PRODUCTION_API}stripe/all-plans`, {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: PAYMENT_PLAN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_PLAN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
