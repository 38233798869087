import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import footerLogo from '../assets/img/footer_logo.svg';
import footerMobileLogo from '../assets/img/footer_mobile_logo.svg';
import useClickOutside from '../hooks/useClickOutside';

import facebookIcon from '../assets/img/icons/facebook_icon.svg';
import instagramIcon from '../assets/img/icons/instagram_icon.svg';
import linkedinIcon from '../assets/img/icons/linkedin_icon.svg';
import axios from 'axios';
import { API_ROUTES } from '../helpers/constants';
import { useSelector } from 'react-redux';
import { getItemFromLocalStorage } from '../helpers/helpers';

const FooterV2 = () => {
  const { t, i18n } = useTranslation();
  const lsITEM = 'selectedLanguage';
  const selectedLanguage = localStorage.getItem(lsITEM);
  const dropdownMenuRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  useEffect(() => {
    i18n.init({
      fallbackLng: 'fr',
      lng: selectedLanguage, // default language
      debug: true,
      whitelist: ['sr', 'en', 'fr'],
      interpolation: {
        escapeValue: false,
      },
    });
  }, []);

  // outside click handler
  useClickOutside(dropdownMenuRef, () => {
    if (isOpened) setIsOpened(() => false);
  });

  const params = useLocation();
  const isHomepage = params.pathname === '/';

  const LanguageDropdown = () => {
    const availableLanguages = ['en', 'fr'];

    const handleChangeLanguage = async (language) => {
      i18n.changeLanguage(language).then(
        () => {},
        () => {}
      );
      try {
        await axios.post(
          API_ROUTES.UPDATE_LANGUAGE,
          {
            app_language: language,
          },
          config
        );
      } catch (error) {
        console.log('NEKI ERROR', error);
      }
    };

    const onHandleSelect = (lng) => {
      localStorage.setItem(lsITEM, lng);
    };

    const renderDropdownMenuItems = () =>
      availableLanguages.map((language, index) => {
        return (
          <li key={index} className='dropdown_menu_item'>
            <button
              onClick={() => {
                setIsOpened(false);
                onHandleSelect(language);
                handleChangeLanguage(language).then(
                  () => {},
                  () => {}
                );
              }}
              className='dropdown_menu_item_link'
            >
              {language}
            </button>
          </li>
        );
      });

    return (
      <div className='language_dropdown'>
        <button
          className={`dropdown_toggle_button ${
            isOpened ? 'dropdown_toggle_button_active' : ''
          }`}
          onClick={() => setIsOpened((isOpened) => !isOpened)}
        >
          <span>{i18n.language}</span>

          <i className={`fas fa-caret-${isOpened ? 'up' : 'down'}`} />
        </button>

        {isOpened && (
          <ul ref={dropdownMenuRef} className='dropdown_menu'>
            {renderDropdownMenuItems()}
          </ul>
        )}
      </div>
    );
  };

  return (
    <footer className={`footer ${isHomepage ? 'homepage_footer' : ''}`}>
      <div className='footer_container'>
        <div className='footer_left'>
          <img
            className='footer_desktop_footer'
            src={footerLogo}
            alt='Footer Desktop Logo'
          />
          <img
            className='footer_mobile_footer'
            src={footerMobileLogo}
            alt='Foter Mobile Logo'
          />
          <p className='desc'>{t('we-connect')}</p>
        </div>
        <div className='footer_right'>
          <ul className='footer_links'>
            <li>{t('support')}</li>
            <li>
              <Link to='/faq'>{t('faq')}</Link>
            </li>
            <li>
              <Link to='/contact'>{t('contact')}</Link>
            </li>
          </ul>
          <ul className='footer_links'>
            <li>{t('company')}</li>
            <li>
              <a href='/about-us'>
                {t('about-us')}
              </a>
            </li>
            <li>
              <Link to='/book-your-visit'>{t('look-for-doctor')}</Link>
            </li>
          </ul>
          <ul className='footer_links'>
            <li>{t('terms')}</li>
            <li>
              <Link to='/patient-terms'>{t('terms')}</Link>
            </li>
            <li>
              <Link to='/privacy'>{t('privacy')}</Link>
            </li>
          </ul>
          <div className='footer_controls'>
            <LanguageDropdown />
            <a
              href='https://www.facebook.com'
              target='_blank'
              className='footer_controls_social'
              rel='noreferrer'
            >
              <img src={facebookIcon} alt='Facebook icon' />
            </a>
            <a
              href='https://www.instagram.com'
              target='_blank'
              className='footer_controls_social'
              rel='noreferrer'
            >
              <img src={instagramIcon} alt='Instagram icon' />
            </a>
            <a
              href='https://www.linkedin.com'
              target='_blank'
              className='footer_controls_social'
              rel='noreferrer'
            >
              <img src={linkedinIcon} alt='Linkedin icon' />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterV2;
