import axios from 'axios';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  SHOW_USER_REQUEST,
  SHOW_USER_SUCCESS,
  SHOW_USER_FAIL,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAIL,
} from '../constants/userConstants';
import { getItemFromLocalStorage } from '../helpers/helpers';

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}login`,
      {
        email,
        password,
      },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.status_code === 400) {
      throw new Error(data.data);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({ type: USER_LOGOUT });
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}forgot-password`,
      {
        email,
      },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetForgotenPassword =
  (token, id, password) => async (dispatch) => {
    try {
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST,
      });

      const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}change-forgot-password`,
        {
          forgot_pw_code: token,
          id,
          password,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_RESET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const showUserActions = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SHOW_USER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PRODUCTION_API}users/show`,
      { id },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    dispatch({
      type: SHOW_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SHOW_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const contactUsActions =
  (first_name, email, message) => async (dispatch) => {
    try {
      dispatch({
        type: CONTACT_US_REQUEST,
      });

      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PRODUCTION_API}contact-us`,
        {
          first_name,
          email,
          message_: message,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status_code === 400) {
        throw new Error(data.data);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({
        type: CONTACT_US_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_US_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
