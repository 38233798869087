import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CancelApointmentScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <body className='modal-open' style={{ overflow: 'hidden' }}>
        <div
          className='modal modal-book fade show'
          tabindex='-1'
          style={{ display: 'block' }}
          aria-modal='true'
          role='dialog'
        >
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-body'>
                <h2>{t('canceled-appointment-title')}.</h2>
                <p>{t('canceled-appointment-message')}.</p>
              </div>
              <div className='modal-footer'>
                {/* <a href='/patient-dashboard'>
                  <button type='button' className='btn btn-ocean'>
                    {t('go-to-dashboard')}
                  </button>
                </a> */}
                <Link to='/patient-dashboard'>
                  <button type='button' className='btn btn-ocean'>
                    {t('go-to-dashboard')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>

        <script src='node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
      </body>
    </>
  );
};

export default CancelApointmentScreen;
