import { useState } from "react";

const ChatInput = ({ onSend }) => {
  const [messageValue, setMessageValue] = useState("");

  const onInputChange = (event) => {
    setMessageValue(event.target.value);
  };

  const onSendClick = () => {
    onSend(messageValue);
    setMessageValue("");
  };

  const onEnterInput = (event) => {
    if (event.key === "Enter") {
      onSend(messageValue);
      setMessageValue("");
    }
  };

  return (
    <div className="chat_input-container">
      <div className="chat_input">
        <input
          value={messageValue}
          onChange={onInputChange}
          onKeyDown={onEnterInput}
          placeholder="Type a message"
        />
      </div>
      <div onClick={onSendClick} className="chat-send cp">
        <img
          src="/assets/images/chat/round-circle.svg"
          alt="Send"
          title="Send"
        />
        <img
          className="send_abs"
          src="/assets/images/chat/send.svg"
          alt="Send"
          title="Send"
        />
      </div>
    </div>
  );
};

export default ChatInput;
