import {
  ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_FAIL,
  ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_REQUEST,
  ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_SUCCESS,
  ADMIN_LOGIN_DOCTOR_FAIL,
  ADMIN_LOGIN_DOCTOR_REQUEST,
  ADMIN_LOGIN_DOCTOR_SUCCESS,
  GET_ALL_DOCTOR_FAIL,
  GET_ALL_DOCTOR_REQUEST,
  GET_ALL_DOCTOR_SUCCESS,
  GET_EARNINGS_DOCTOR_FAIL,
  GET_EARNINGS_DOCTOR_REQUEST,
  GET_EARNINGS_DOCTOR_SUCCESS,
} from '../constants/adminConstants';

export const getAdminAllDoctorsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_DOCTOR_REQUEST:
      return { loading: true };
    case GET_ALL_DOCTOR_SUCCESS:
      return { loading: false, adminAllDoctors: action.payload };
    case GET_ALL_DOCTOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAdminEarningsDoctorsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EARNINGS_DOCTOR_REQUEST:
      return { loading: true };
    case GET_EARNINGS_DOCTOR_SUCCESS:
      return { loading: false, adminEarningsDoctor: action.payload };
    case GET_EARNINGS_DOCTOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminChangePorfileStatusDoctorsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_REQUEST:
      return { loading: true };
    case ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_SUCCESS:
      return { loading: false, adminChangePoofileStatusDoctor: action.payload };
    case ADMIN_CHANGE_PROFILE_STATUS_DOCTOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminLoginDoctorReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_DOCTOR_REQUEST:
      return { loading: true };
    case ADMIN_LOGIN_DOCTOR_SUCCESS:
      return { loading: false, adminLoginDoctor: action.payload };
    case ADMIN_LOGIN_DOCTOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
