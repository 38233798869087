// Constants for fetching the list of clinics
export const GET_CLINIC_LIST_REQUEST = 'GET_CLINIC_LIST_REQUEST';
export const GET_CLINIC_LIST_SUCCESS = 'GET_CLINIC_LIST_SUCCESS';
export const GET_CLINIC_LIST_FAIL = 'GET_CLINIC_LIST_FAIL';

// You can also add other constants related to clinic operations here
// For example, constants for creating, updating, or deleting clinics
export const CREATE_CLINIC_REQUEST = 'CREATE_CLINIC_REQUEST';
export const CREATE_CLINIC_SUCCESS = 'CREATE_CLINIC_SUCCESS';
export const CREATE_CLINIC_FAIL = 'CREATE_CLINIC_FAIL';

export const CLINIC_CREATE_DOCTOR_REQUEST = 'CLINIC_CREATE_DOCTOR_REQUEST';
export const CLINIC_CREATE_DOCTOR_SUCCESS = 'CLINIC_CREATE_DOCTOR_SUCCESS';
export const CLINIC_CREATE_DOCTOR_FAIL = 'CLINIC_CREATE_DOCTOR_FAIL';
