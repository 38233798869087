import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';

import { forgotPassword } from '../actions/userActions';

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { loading, error, userInfo } = userForgotPassword;

  let message = userInfo?.data;
  const submitHandler = (e) => {
    e.preventDefault();
    // Dispatch Forgot Password
    dispatch(forgotPassword(email));
  };

  return (
    <div className='forgot_password'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-xxl-4 col-md-12 col-12 mx-auto'>
            <div className='user-form-container'>
              <div className='user-form'>
                <form onSubmit={submitHandler}>
                  {error && <Message variant='danger'>{error}</Message>}
                  {loading && <Loader />}
                  {message && <Message variant='success'>{message}</Message>}
                  <div className='mb-3'>
                    <label htmlFor='emailAddress' className='form-label'>
                      {t('email')}
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      id='emailAddress'
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button type='submit' className='btn btn-ocean'>
                    {t('send-link')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
