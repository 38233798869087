import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CreateAppointmentPopup from './CreateAppointmentPopup';

const CreateAppointmentButton = ({onCreateAppointment}) => {

  const { t, i18n } = useTranslation();
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const openPopup = () => {
    setPopupIsOpen(true);
  }

  const closePopup = () => {
    setPopupIsOpen(false);
  }

  return (
    <>
        <a className='create-appointment-btn' onClick={() => openPopup()}>
            <span className='create-appointment-plus'>+</span>
            <span className='create-appointment-text'>{t("create")}</span>
        </a>
        {popupIsOpen && <CreateAppointmentPopup onCreateAppointment={onCreateAppointment} closePopup={closePopup} />}
    </>
  );
};

export default CreateAppointmentButton;
