import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import { appRoutes, API_ROUTES } from '../../helpers/constants';
import moment from 'moment';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const OnlinePayments = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { t, i18n } = useTranslation();

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const [doctorList, setDoctorList] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchDoctors = async () => {
    setLoading(true);
    await axios
      .post(API_ROUTES.PATIENT.NOT_PAYED, null, config)
      .then((response) => {
        setLoading(false);
        setDoctorList(response.data.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }
  // console.log('dddd', doctorList);
  let doctorPicture = '/assets/images/doctors/doctor.jpg';
  return (
    <>
      <div className='online_patient_payment'>
        <div className='container-xxl page-heading'>
          <div className='row'>
            <div className='col-sm-4 col-12'>
              <Link className='back-to' to={'/dashboard'}>
                {t('back-to-dashboard')}
              </Link>
            </div>
            <div className='col-sm-4 col-12 text-center'>
              <div className='user-register-label ocean'>
                <img src='/assets/images/wallet.svg' alt='Chart' />
                <span>{t('payments')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container-xxl'>
          <div className='row'>
            <div className='col-xxl-6 col-md-12 col-12 mx-auto'>
              <div className='user-view'>
                <div className='row'>
                  {doctorList &&
                    doctorList.map((element, index) => {
                      if (element?.doctor?.profile_picture != null) {
                        doctorPicture = `${process.env.REACT_APP_PRODUCTION_API}show-file/${element?.doctor?.profile_picture}`;
                      }

                      return (
                        <div
                          className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
                          key={index}
                        >
                          <div className='user col-xxl-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className=' profile patient_card'>
                              <div className='profile-image'>
                                <div
                                  className='profile-image-wrapper'
                                  style={{
                                    background: `url(${doctorPicture})`,
                                  }}
                                ></div>
                                <img src={doctorPicture} alt='Doctor Name' />
                              </div>

                              <div className='profile-info'>
                                <div className='personal'>
                                  <h3 className='name'>
                                    {element.doctor.name}{' '}
                                    {element.doctor.surname}
                                  </h3>
                                  {/* <p className='normal light'>
                                    <span>{t('male')} &#x2022; </span>
                                    {moment(element.created_at).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </p> */}
                                  <p className='normal light'>
                                    {moment(
                                      new Date(element.start_date)
                                    ).format('DD-MM-YYYY')}{' '}
                                    {moment(
                                      new Date(element.start_date)
                                    ).format('hh:mm A')}{' '}
                                    -{' '}
                                    {moment(new Date(element.end_date)).format(
                                      'hh:mm A'
                                    )}
                                  </p>
                                  <p className='normal light'>
                                    {element.doctor.email}
                                  </p>
                                  <p className='normal light'>
                                    {element.doctor.phone_number}
                                  </p>
                                </div>
                              </div>

                              <div className='profile-buttons'>
                                {element?.payment_status === 0 ? (
                                  <Link
                                    to={
                                      appRoutes.PATIENT.PAYMENT +
                                      `/${element.id}/${element.payment_price}/${element.doctor_id}`
                                    }
                                    className='btn btn-sun'
                                  >
                                    {t('pay-termin')} {element.payment_price} €
                                  </Link>
                                ) : (
                                  <button
                                    type='button'
                                    className='btn btn-nature'
                                  >
                                    {t('paid-termin')}
                                  </button>
                                )}
                                {/* <a className="btn btn-darkblue">See profile</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlinePayments;
