import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import io from 'socket.io-client';
import {
  API_ROUTES,
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  SOCKET_ROUTES,
  socketToken,
  socketURL,
  STATUS,
} from '../../helpers/constants';
import axios from 'axios';
import { getItemFromLocalStorage } from '../../helpers/helpers';
import LoadingIndicator from '../LoadingIndicator';

const ConfirmPricePopup = ({event, onEventAction}) => {

    const { t, i18n } = useTranslation();

    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [price, setPrice] = useState(0);
    const [isDoctor, setIsDoctor] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const [isLoading, setIsLoading] = useState(false);

    const config = {
        headers: {
          'Content-type': 'application/json',
          'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
          Authorization: `Bearer ${userInfo.data}`,
        },
    };
    const socket = io(socketURL, {
        transports: [ "websocket" ],
        query: { token: socketToken },
      });

      useEffect(() => {
        if (!socket.connected)
         socket.connect();
        return () => {
            if (socket.connected)
            socket.disconnect();
        }
        }, []);

    const confirmPriceAPI = async () => {
        setIsLoading(true);
        await axios.post(
            API_ROUTES.DOCTOR.CONFIRM_PAYMENT,
            {
              uid: event.event.id,
            },
            config
        )
        .then((response) => {
            socket.emit(
            SOCKET_ROUTES.EVENTS,
            event.event.doctor_id,
            STATUS.PENDING,
            event.event.id
            );
            socket.emit(SOCKET_ROUTES.NOTIFICATIONS, event.event.doctor_id, {
            message: NOTIFICATIONS_TEXT.DOCTOR.CONFIRM_PRICE,
            type: NOTIFICATIONS_TYPES.ALERT,
            });
            onEventAction();
            setIsLoading(false);
        })
        .catch((error) => {
            console.log('SOME ERROR APPEAR HERE', error);
        });
    }

    const makeFreePayment = async (appointment_id) => {
        await axios
          .post(
            API_ROUTES.PATIENT.MAKE_FREE_PAYMENT,
            {
              appointment_id: appointment_id,
            },
            {
              headers: {
              'Content-type': 'application/json',
              'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
              Authorization: `Bearer ${userInfo?.data}`,
              }
            },
          )
          .then((response) => {
            console.log('MAKE FREE PAYMENT RESPONSE', response);
            onEventAction();
            setIsLoading(false);
          });
    
    }

    const changePriceAPI = async () => {

        //TODO DEFINE PRICE & NOTES
        let notes = '';

        await axios.post(
            API_ROUTES.DOCTOR.PROPOSE_DIFFERENT_PRICE,
            {
              uid: event.event.id,
              new_price: price,
              notes: notes,
            },
            config
        )
        .then((response) => {
            confirmPriceAPI();
        })
        .catch((error) => {
            console.log('SOME ERROR APPEAR HERE', error);
        });
    }

    useEffect(() => {
        setIsDoctor(userInfo?.usertype === "freelance_doctor" || userInfo?.usertype === "clinic_doctor");
        setPrice(event.event?.payment_price);
    }, [event]);

    return (
        <>
        {isLoading && <LoadingIndicator />}
            {isDoctor &&
                <div className='confirm-price' onClick={() => setPopupIsOpen(true)}>
                    {t("confirm-price")}
                </div>
            }
            {popupIsOpen &&
                <div className='confirm-price-popup'>
                    <div className='confirm-price-popup-header'>
                        <div className='confirm-price-popup-title'>
                            {t("confirm-price")}
                        </div>
                        <div className='confirm-price-popup-close' onClick={() => setPopupIsOpen(false)}>
                            <img src='/assets/images/cross.svg' alt='close' />
                        </div>
                    </div>
                    <div className='confirm-price-popup-body'>
                        <div className='confirm-price-popup-body-price'>
                            <input type='number' min="0" value={price} onChange={(event) => setPrice(event.target.value)} placeholder={t("enter-price")} />
                        </div>
                    </div>
                    <div className='confirm-price-popup-footer'>
                        <div className='btn btn-darkblue' onClick={() => changePriceAPI()}>
                            {t("confirm")}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ConfirmPricePopup;
