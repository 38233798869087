import React from "react";

import styles from "../styles/Loading.module.css";

const LoadingIndicator = () => {
  return (
    <div className="loading_bg-modal">
      <div className={styles.loadingIndicator}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
