import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import MiniEventsPopup from './MiniEventsPopup';

const MiniEvents = ({events, onEventAction}) => {

  const { t } = useTranslation();
  const [bookedVisits, setBookedVisits] = useState(0);
  const [openAppointments, setOpenAppointments] = useState(0);
  const [requestedAppointments, setRequestedAppointments] = useState(0);
  const [groupEvents, setGroupEvents] = useState(0);
  const [eventsSorted, setEventsSorted] = useState([]);

  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    setGroupEvents(eventsSorted.filter(event => event.type === "group-appointment").length + eventsSorted.filter(event => event.type === "group-termin").length);
    setBookedVisits(eventsSorted.filter(event => event.type === "appointment" && ((event.event.payment_status === 1 && event.event.appointment_status === "accept_online") || event.event.appointment_status === "accept_studio")).length);
    setOpenAppointments(eventsSorted.filter(event => event.type === "termin").length);
    setRequestedAppointments(eventsSorted.filter(event => event.type === "appointment" && event.event.payment_status === 0 && !(event.event.appointment_status === "accept_online" || event.event.appointment_status === "accept_studio")).length);
  }, [eventsSorted]);

  const openPopup = () => {
    setShowPopUp(true);
  }

  const closePopup = () => {
    setShowPopUp(false);
  }

  useEffect(() => {
    sortEvents();
}
, [events]);

const sortEvents = () => {
    let eventsTemp = [];
    events.appointments.map((appointment) => {
        if (appointment.is_group_appointment === 1) {
            // Try to find if there is already a group appointment in the array with the same start date
            let found = false;
            eventsTemp.map((eventTemp) => {
                if (eventTemp.events?.[0]?.start_date === appointment.start_date) {
                    found = true;
                    eventTemp.events.push(appointment);
                    return;
                }
            });
            if (!found) {
                eventsTemp.push({
                    type: 'group-appointment',
                    events : [appointment],
                    termin : events.termins.filter(termin => termin.start_date === appointment.start_date)[0],
                });
            }
        } else {
            eventsTemp.push({
                type: 'appointment',
                event : appointment,
            });
        }
    });
    events.termins.map((termin) => {
        if (termin.spots > 1) {
            if (termin.status === termin.spots){
                eventsTemp.push({
                    type: 'group-termin',
                    event : termin,
                });
            }
            return;
            
        }
        
        eventsTemp.push({
            type: 'termin',
            event : termin,
        });
    });
    eventsTemp.sort((a, b) => ((a.event?.start_date ? a.event.start_date : a.events?.[0].start_date) > (b.event?.start_date ? b.event.start_date : b.events?.[0].start_date)) ? 1 : -1);
    setEventsSorted(eventsTemp);
}

  return (
    <>
      {showPopUp && (
        <MiniEventsPopup onEventAction={onEventAction} eventsSorted={eventsSorted} closePopup={closePopup}/>
      )}
      <section className="mini-event-container" onClick={openPopup}>
        <div className="mini-event-header">
          {t('all-appointments')}
        </div>
        <div className="mini-event-body">
        {groupEvents === 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
              {groupEvents}
              </div>
              <div className="mini-event-item-description">
                {t('group-appointment')}
              </div>
            </div>
          )}
          {groupEvents > 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
              {groupEvents}
              </div>
              <div className="mini-event-item-description">
                {t('group-appointments')}
              </div>
            </div>
        )}
        {bookedVisits === 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
              {bookedVisits}
              </div>
              <div className="mini-event-item-description">
                {t('booked-visit')}
              </div>
            </div>
          )}
          {bookedVisits > 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
              {bookedVisits}
              </div>
              <div className="mini-event-item-description">
                {t('booked-visits')}
              </div>
            </div>
          )}
          {openAppointments === 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
                {openAppointments}
              </div>
              <div className="mini-event-item-description">
                {t('open-appointment')}
              </div>
            </div>
          )}
          {openAppointments > 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
                {openAppointments}
              </div>
              <div className="mini-event-item-description">
                {t('open-appointments')}
              </div>
            </div>
          )}
          {requestedAppointments === 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
                {requestedAppointments}
              </div>
              <div className="mini-event-item-description">
                {t('requested-appointment')}
              </div>
            </div>
          )}
          {requestedAppointments > 1 && (
            <div className="mini-event-item">
              <div className="mini-event-item-number">
                {requestedAppointments}
              </div>
              <div className="mini-event-item-description">
                {t('requested-appointments')}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default MiniEvents;