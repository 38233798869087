import { calendarDays, daysInMonth } from "./constants";

export const minTwoDigits = (n) => {
  return (n < 10 ? "0" : "") + n;
};

export const formatCustomCalendar = (
  currentMounthIndex,
  currentYEAR,
  mobileView = false
) => {
  const defaultDays = [
    {
      name: "Monday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
    {
      name: "Tuesday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
    {
      name: "Wednesday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
    {
      name: "Thursday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
    {
      name: "Friday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
    {
      name: "Saturday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
    {
      name: "Sunday",
      value: "",
      active: false,
      events: {
        appointments: [],
        termins: [],
      },
    },
  ];

  let date = new Date(currentYEAR, currentMounthIndex, 1);
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let firstDayName = calendarDays[firstDay.getDay()];

  const previousMonthDays = daysInMonth(currentMounthIndex, currentYEAR);

  let dayIndex = defaultDays.findIndex(
    (element) => element.name === firstDayName
  );

  if (mobileView) {
    dayIndex = 0;
  }

  const headerDays = [...defaultDays];

  const remainingDays = [];

  for (
    let index = dayIndex, daysCounter = 1;
    index < 7;
    index++, daysCounter++
  ) {
    headerDays[index].value = minTwoDigits(daysCounter);
    headerDays[index].active = true;
  }

  for (
    let index = dayIndex - 1, backCounter = 0;
    index >= 0;
    index--, backCounter++
  ) {
    headerDays[index].value = previousMonthDays - backCounter;
  }

  const lastCounterDays = 7 - dayIndex;
  for (
    let index = lastCounterDays + 1;
    index <= daysInMonth(currentMounthIndex + 1, currentYEAR);
    index++
  ) {
    remainingDays.push({
      value: minTwoDigits(index),
      active: true,
      events: {
        appointments: [],
        termins: [],
      },
    });
  }

  //ADD EMPTY FIELDS...

  let totalCellNumber = 42;
  let mergeCalendar = [...headerDays, ...remainingDays];

  if (mergeCalendar.length !== totalCellNumber) {
    for (
      let index = mergeCalendar.length, helperIndex = 1;
      index < totalCellNumber;
      index++, helperIndex++
    ) {
      mergeCalendar[index] = {
        value: helperIndex,
        active: false,
        events: {
          appointments: [],
          termins: [],
        },
      };
    }
  }
  return mergeCalendar;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getItemFromLocalStorage = (itemName, stringify = false) => {
  return stringify
    ? JSON.stringify(localStorage.getItem(itemName))
    : localStorage.getItem(itemName);
};
