import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  API_ROUTES,
} from '../../helpers/constants';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const YourCreditScreen = () => {
  const { t, i18n } = useTranslation();
  const [yourCredit, setYourCredit] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const getCreditInfo = async () => {
    await axios
      .post(API_ROUTES.DOCTOR.CREDIT_INFO, null, config)
      .then((response) => {
        setYourCredit(response?.data);
      });
  };

  useEffect(() => {
    getCreditInfo();
  }, []);

  const getSubscriptionInfo = async () => {
    await axios
      .post(API_ROUTES.DOCTOR.GET_SUBSCRIPTION, null, config)
      .then((response) => {
        setSubscription(response?.data);
      });
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const cancelSubscription = async () => {
    await axios
      .post(API_ROUTES.DOCTOR.CANCEL_SUBSCRIPTION, null, config)
      .then((response) => {
        window.location.reload();
      });
  };

  //   console.log('YOUR CREDIT', yourCredit);

  return (
    <>
      <div className='container-xxl'>
        <div className='container-fluid page-heading'>
          <div className='row'>
            <div className='col-sm-4 col-12'>
              <Link to='/doctor-payment-plan' className='back-to'>
                {t('back')}
              </Link>
            </div>
            <div className='col-sm-4 col-12 text-center'>
              <div className='user-register-label nature'>
                <img src='/assets/images/wallet.svg' />
                <span>{t('online-payments')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xxl-3 col-md-4 col-sm-6 col-12 mx-auto stripe_form_wrapper'>
            <div className='register-plan mb-2'>
              {t('you-have')} {yourCredit && yourCredit?.data}{' '}
              {t('credits-left')}
            </div>

            <div className='register-plan'>
              <div className='register-plan-name'>
                {subscription?.data?.items?.data[0] &&
                  subscription?.data?.items?.data[0]?.plan?.nickname}
              </div>
              <div className='register-plan-price'>
                {subscription?.data?.items?.data[0] &&
                  subscription?.data?.items?.data[0]?.plan.currency === 'usd' &&
                  '$'}
                {subscription?.data?.items?.data[0] &&
                  subscription?.data?.items?.data[0]?.plan.amount / 100}
              </div>
              <div className='register-plan-feature-list'>
                <ul>
                  <li>
                    {subscription?.data?.items?.data[0] &&
                      subscription?.data?.items?.data[0]?.plan.metadata
                        .first_paragraph}
                  </li>
                  <li>
                    {subscription?.data?.items?.data[0] &&
                      subscription?.data?.items?.data[0]?.plan.metadata
                        .second_paragraph}
                  </li>
                  <li className='check-red'>
                    {subscription?.data?.items?.data[0] &&
                      subscription?.data?.items?.data[0]?.plan.metadata
                        .third_paragraph}
                  </li>
                  <li className='check-disable'>
                    {subscription?.data?.items?.data[0] &&
                      subscription?.data?.items?.data[0]?.plan.metadata
                        .fourth_paragraph}
                  </li>
                  <li className='check-disable'>
                    {subscription?.data?.items?.data[0] &&
                      subscription?.data?.items?.data[0]?.plan.metadata
                        .fifth_paragraph}
                  </li>
                </ul>
              </div>
            </div>

            <button onClick={cancelSubscription} className='btn btn-darkblue'>
              {t('cancel-subscription')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourCreditScreen;
