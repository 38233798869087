import React from "react";
import { useTranslation } from "react-i18next";

const ErrorNotification = () => {
    const { t, i18n } = useTranslation();
    return (
      <div className="notification-item">
        <div className="notification-desc">
          <div className="notification-icon sunset">
            <img src="assets/images/wrong.svg" alt="Wrong" />
          </div>
          <div className="notification-info">
            <h3>{t("something-went-wrong")}</h3>
            <p>{t("something-went-message")}</p>
          </div>
        </div>
      </div>
    );
  };

export default ErrorNotification;