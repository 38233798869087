import { useTranslation } from "react-i18next";

const ChatGroupItem = ({ data, active = false, onGroupItemClick }) => {
  // console.log("DATA", data);

  const { t, i18n } = useTranslation();

  const profilePicture = data.doctor
    ? data.doctor.profile_picture
    : data.patient.profile_picture;
  console.log(data);
  return (
    <div
      onClick={onGroupItemClick}
      className={`chat-item  cp ${active && "chat-item-active"}`}
    >
      <div>
        <img
          className="chat-item_image"
          src={
            profilePicture
              ? `${process.env.REACT_APP_PRODUCTION_API}show-file/${profilePicture}`
              : "/assets/images/doctors/doctor.jpg"
          }
          alt="Name"
          title="Name"
        />
      </div>
      {data.count_is_read_messages !== 0 && <p className="chat_number">{data.count_is_read_messages}</p>}
      <div className="chat-item-info">
        <h3>
          {data.doctor ? data.doctor.name : data.patient.name}{" "}
          {data.doctor ? data.doctor.surname : data.patient.surname}
        </h3>
        <p>{t("read-message")}</p>
      </div>
    </div>
  );
};

export default ChatGroupItem;
