import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cancelApointmentActions } from '../../actions/apointmentActions';

const ConfirmOrCancelApointmentScreen = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let history = useNavigate();
  useEffect(() => {
    if (!userInfo) {
      history(`/login`);
    }
  }, [history, userInfo]);

  const cancelApointmentData = useSelector((state) => state.cancelApointment);
  const { cancelApointment } = cancelApointmentData;

  useEffect(() => {
    if (cancelApointment?.status_code === 200) {
      history('/cancel-apointment-confirm');
    }
  }, [history, cancelApointment]);

  const confirmHandler = () => {
    history(`/confirm-apointment/${id}`);
  };

  let { id } = useParams();
  const cancelHandler = (id) => {
    dispatch(cancelApointmentActions(id));
  };

  return (
    <>
      <body className='modal-open' style={{ overflow: 'hidden' }}>
        <div
          className='modal modal-book fade show'
          tabindex='-1'
          style={{ display: 'block' }}
          aria-modal='true'
          role='dialog'
        >
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-body'>
                <h2>{t('cancel-appointment-title')}?</h2>
                <p>{t('cancel-appointment-message')}.</p>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-ocean'
                  onClick={() => {
                    confirmHandler();
                  }}
                >
                  {t('keep-appointment')}
                </button>
                <button
                  type='button'
                  className='btn btn-sunset'
                  onClick={() => {
                    cancelHandler(id);
                  }}
                >
                  {t('cancel-appointment')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>

        <script src='node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'></script>
      </body>
    </>
  );
};

export default ConfirmOrCancelApointmentScreen;
