import axios from 'axios';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  API_ROUTES,
  appRoutes,
  NOTIFICATIONS_TEXT,
  NOTIFICATIONS_TYPES,
  socketToken,
  socketURL,
  SOCKET_ROUTES,
  ROLES,
  STATUS,
} from '../../helpers/constants';
import '../../styles/payment.css';
import { useParams } from 'react-router';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import io from 'socket.io-client';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const socket = io(socketURL, {
  transports: [ "websocket" ],
  query: { token: socketToken },
});

let stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);

// New Stripe Form
const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: '16px',
          letterSpacing: '0.025em',
          border: '1px solid red',
          backgroundColor: '#fff',
          padding: 16,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};

const StripeForm = ({ submitePayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardNumberElement);

    // stripe's create payment method Api
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('error:', error);
    } else {
      submitePayment(paymentMethod);
    }
  };
  const { t, i18n } = useTranslation();
  return (
    <div className='stripe_payment_form'>
      <div className='user-form'>
        <div className='col-sm-12 col-12 mb-3'>
          <label className='form-label'>{t('card-number')}</label>
          <CardNumberElement options={options} />
        </div>

        <div className='mb-4'>
          <label className='form-label'>{t('expiration-date')}</label>
          <CardExpiryElement options={options} />
        </div>

        <div className=''>
          <label className='form-label'>CVC</label>
          <CardCvcElement options={options} />
        </div>
      </div>

      <button
        className='btn btn-darkblue'
        disabled={!stripe || !elements}
        onClick={handleSubmit}
      >
        {t('pay')}
      </button>
    </div>
  );
};

const PatientPaymentScreen = () => {
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { t, i18n } = useTranslation();

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo?.data}`,
    },
  };

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [monthYear, setMonthYear] = useState('');
  const [loading, setLoading] = useState(false);

  const { id, price, doctorID } = useParams();

  
  const onChangeCardName = (event) => {
    setCardName(event.target.value);
  };

  const onChangeCardNumber = (event) => {
    setCardNumber(event.target.value);
  };

  const onChangeMonth = (event) => {
    setMonthYear(event.target.value);
  };

  const onChangeCVV = (event) => {
    setCvv(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!cardNumber) {
      alert('Please enter Card Number');
      return;
    }

    if (!cardName) {
      alert('Please enter Card Name');
      return;
    }

    if (!monthYear) {
      alert('Please enter Month/Year');
      return;
    }

    if (!cvv) {
      alert('Please fill CVC');
      return;
    }

    setLoading(true);

    await axios
      .post(
        API_ROUTES.PATIENT.MAKE_PAYMENT,
        {
          appointment_id: id,
          stripe_payment_method_id: event.id,
        },
        config
      )
      .then(() => {
        navigate(appRoutes.PATIENT.ONLINE_PAYMENTS, { replace: true });
        setLoading(false);
        socket.connect();
        socket.emit(SOCKET_ROUTES.NOTIFICATIONS, doctorID, {
          message: NOTIFICATIONS_TEXT.PATIENT.MAKE_PAYMENT,
          type: NOTIFICATIONS_TYPES.SUCCESS,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const submitPayment = async (data) => {
    setLoading(true);
    await axios
      .post(
        API_ROUTES.PATIENT.MAKE_PAYMENT,
        {
          appointment_id: id,
          stripe_payment_method_id: data.id,
        },
        config
      )
      .then(() => {
        navigate(appRoutes.PATIENT.ONLINE_PAYMENTS, { replace: true });
        setLoading(false);
        socket.connect();
        socket.emit(SOCKET_ROUTES.EVENTS, doctorID, STATUS.IS_PAYED, id);
        socket.emit(SOCKET_ROUTES.NOTIFICATIONS, doctorID, {
          message: NOTIFICATIONS_TEXT.PATIENT.MAKE_PAYMENT,
          type: NOTIFICATIONS_TYPES.SUCCESS,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <section className='stripe_patient_payment_form payment-screen'>
      <img
        className='payment-left-vector'
        src='/assets/images/payment/left-vector.svg'
      />

      <img
        src='/assets/images/payment/right-vector.svg'
        className='payment-right-vector'
      />
      <div className='container-fluid page-heading'>
        <div className='row'>
          <div className='col-sm-4 col-12'>
            <a
              href='/dashboard'
              className='back-to'
            >
              {t('back-to-dashboard')}
            </a>
          </div>
          <div className='col-sm-4 col-12 text-center'>
            <div className='user-register-label nature'>
              <img src='/assets/images/wallet.svg' />
              <span>{t('online-payments')}</span>
            </div>
          </div>
        </div>
      </div>
      <section className='payment-container'>
        <div className='payment-order'>
          <h3 className='speak-doctors-subtitle'>{t('order-summary')}: </h3>
          <div className='justify-content-between d-flex'>
            <p>{t('subtotal')}:</p>
            <p>${price}</p>
          </div>
          <div className='justify-content-between d-flex'>
            <p>{t('subtotal')}:</p>
            <p>${price}</p>
          </div>
          <hr />
          <div className='justify-content-between d-flex'>
            <p>{t('subtotal')}:</p>
            <p>${price}</p>
          </div>
        </div>
      </section>
      <div className='row'>
        <div className='col-xxl-6 col-md-6 col-12 mx-auto stripe_form_wrapper'>
          {/* <section className='payment-container-details stripe_form_wrapper'> */}
          <h2 className='title'>{t('payment-details')}</h2>
          <div className='payment-form'>
            <Elements stripe={stripePromise} options={{}}>
              <StripeForm submitePayment={submitPayment} />
            </Elements>
            <div className='second_payment_option'>
              <p>
                {t('in case you wish to pay with a different payment method please contact our support staff, they will help you set up the payment')}
              </p>
            </div>
          </div>
          {/* </section> */}
        </div>
      </div>
    </section>
  );
};

export default PatientPaymentScreen;
