import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsActions } from '../actions/userActions';
import Message from '../components/Message';

const ContactScreen = () => {
  const { t, i18n } = useTranslation();
  const [first_name, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const contactUsData = useSelector((state) => state.contactUs);
  const { loading, error, contactUs } = contactUsData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (contactUs?.status_code === 200) {
      setInfoMessage('Success');
    }
  }, [contactUs]);

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    // Dispatch Login
    dispatch(contactUsActions(first_name, email, message));
  };

  return (
    <>
      <div className='contact_page'>
        <div className='legal_wrapper'>
          <div className='container-xxl'>
            <div className='content_wrapper'>
              <div className='col-xxl-6 col-md-12 col-12'>
                <div className='user-form-container'>
                  <div className='user-text'>
                    <h2>{t('contact-us')}</h2>
                    <p>{t('contact-us-description')}</p>
                  </div>
                  <div className='user-form'>
                    <form onSubmit={submitHandler}>
                      <div className='mb-3'>
                        <label htmlFor='emailAddress' className='form-label'>
                          {t('name')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form-control'
                          placeholder={t('name')}
                          value={first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='emailAddress' className='form-label'>
                          {t('email')}
                        </label>
                        <input
                          type='email'
                          required
                          className='form-control'
                          placeholder={t('email')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='emailAddress' className='form-label'>
                          {t('message')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form-control'
                          placeholder={t('message')}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <button type='submit' className='btn btn-ocean'>
                        {t('send-a-message')}
                      </button>
                    </form>
                  </div>
                  {infoMessage && (
                    <Message variant='success'>{infoMessage}</Message>
                  )}
                  {error && <Message variant='danger'>{error}</Message>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactScreen;
