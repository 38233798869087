import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { API_ROUTES } from '../../helpers/constants';
import { getItemFromLocalStorage } from '../../helpers/helpers';

const DoctorList = ({ availableDoctors, data, onCodeOpen }) => {
  const [sharedWith, setSharedWith] = useState(
    data.documents_shared_with_doctor.map((element) => element.user_id)
  );

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { t, i18n } = useTranslation();

  const config = {
    headers: {
      'Content-type': 'application/json',
      'x-localization': getItemFromLocalStorage('i18nextLng') || 'en',
      Authorization: `Bearer ${userInfo.data}`,
    },
  };

  const onSaveChanges = async () => {
    await axios
      .post(
        API_ROUTES.PATIENT.SHARE_UNSHARE_DOCUMENTS,
        {
          document_id: data.id,
          patient_id: data.user_id,
        },
        config
      )
      .then(() => {
        onCodeOpen({
          document_id: data.id,
          user_ids: sharedWith.length > 0 ? sharedWith : "empty",
          patient_id: data.user_id,
        });
      });
  };

  const onCheckBoxChange = (event) => {
    const id = event.target.value;

    const prevState = [...sharedWith];

    const indexOfArray = prevState.indexOf(id);

    if (indexOfArray == -1) {
      prevState.push(id);
    } else {
      prevState.splice(indexOfArray, 1);
    }

    setSharedWith(prevState);
  };
  return (
    <div className='share-to-doctors'>
      <div className='notification-item'>
        <div className='notification-desc'>
          <div className='notification-icon sun'>
            <img
              src='assets/images/document-share-white.svg'
              alt='share document'
            />
          </div>
          <div className='notification-info'>
            <h3>{data?.title}</h3>
            <p>{t('select-doctor-to-share')}:</p>
          </div>
        </div>
        <div className='notification-button'>
          <a onClick={onSaveChanges} className='btn btn-sun'>
            {t('save-changes')}
          </a>
        </div>
      </div>
      <div className='share-to-doctor'>
        <div className='row'>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
            {availableDoctors &&
              availableDoctors.map((element, index) => {
                return (
                  <div key={index} className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onChange={onCheckBoxChange}
                      id={`doctorCheckBox${data.id}${index}`}
                      defaultChecked={
                        sharedWith.includes(element.doctor_id) ? true : false
                      }
                      value={element.doctor_id}
                    />
                    <label
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '15px',
                        opacity: 1,
                      }}
                      className='form-check-label'
                      htmlFor={`doctorCheckBox${data.id}${index}`}
                    >
                      Dr. {element.doctor.name} {element.doctor.surname}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorList;
